import React, { useState, createContext, useContext } from 'react';

export interface ShareType {
  shareHandler: any,
  setShareHandler?: (handler: any) => void,
}

const defaultValues = {
  shareHandler: null,
};

const ShareIntentContext = createContext<ShareType>(defaultValues);

export const ShareIntentProvider = ({ children }) => {
  const [shareHandler, setShareHandler] = useState(defaultValues.shareHandler);
  const updateShareHandler = (handler: any) => {
    setShareHandler(handler);
  };
  return (
    <ShareIntentContext.Provider
      value={{ shareHandler, setShareHandler: updateShareHandler }}
    >
      {children}
    </ShareIntentContext.Provider>
  );
};

export const useShareContext = () => useContext(ShareIntentContext);
export default ShareIntentContext;
