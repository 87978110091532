import React, {useEffect} from "react";
import {Dimensions, Modal, Platform, StatusBar, TouchableOpacity, View} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";

export function TrailerModal({ route, navigation }) {
  const { youtubeId } = route.params;
  useEffect(() => {
    navigation.setOptions({
      headerShown: false,
    });
  }, []);
  return (
    <Modal
      visible
      animationType="slide"
    >
      <StatusBar backgroundColor="black" barStyle="light-content" />
      <View
        style={{
          backgroundColor: '#000000',
          display: 'flex',
          alignItems: 'center',
          alignContent: 'center',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <TouchableOpacity
          onPress={navigation.goBack}
          style={{ position: 'absolute', top: 4, right: 4 }}
        >
          <Ionicons
            style={{padding: 12}}
            // FIXME Need to use mobile-device-detect for this instead of Platform.OS - this will resolve to 'web'
            name={Platform.OS === 'ios' ? 'close-outline' : 'close'}
            size={28}
            color="white"
          />
        </TouchableOpacity>
        <iframe
          width={Dimensions.get('window').width - 20}
          height={(Dimensions.get('window').width - 20) * (9 / 16)}
          src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          style={{ margin: 10 }}
        />
      </View>
    </Modal>
  )
}
