import React, { useState, createContext, useContext } from 'react';

export interface MovieType {
  id?: number;
  title?: string;
  description?: string;
  posterImage?: string;
  posterBackdrop?: string;
  year?: string;
  rating?: number;
  videoPreview?: string;
  youtubeId?: string;
  ratingCount?: number;
}

interface ContextType {
  selectedMovie: MovieType;
  setSelectedMovie?: (movie: MovieType) => void;
}

const MovieDetailContext = createContext<ContextType>({
  selectedMovie: {},
});

// @ts-ignore
export const MovieDetailProvider = ({ children }) => {
  const [selectedMovie, setSelectedMovie] = useState({});
  const updateSelectedMovie = (movie: MovieType) => setSelectedMovie(movie);
  return (
    <MovieDetailContext.Provider
      value={{
        selectedMovie,
        setSelectedMovie: updateSelectedMovie,
      }}
    >
      {children}
    </MovieDetailContext.Provider>
  );
};

export const useMovieContext = () => useContext(MovieDetailContext);
export default MovieDetailContext;
