import React, {useEffect, useState} from 'react';
import {useAuth} from "../contexts/AuthContext";
import {useTheme} from "@react-navigation/native";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {observer} from "mobx-react-lite";
import {ImageBackground, View, ViewStyle,} from "react-native";
import {TextInput} from "react-native-paper";
import {color, spacing} from "../theme";
import {Button, Screen} from "../components";
import {palette} from "../theme/palette";
import {isMobile} from "../components/mobile-device-detect";
import {validateEmail} from "./Login";

const flexStyle: ViewStyle = {flex: 1};
const containerStyle: ViewStyle = {
  // backgroundColor: '#121212', // colors.background, // FIXME Depends on the theme
  paddingHorizontal: spacing[4],
}

export const PasswordResetScreen: React.FC<StackScreenProps<NavigatorParamList, "Reset Password">> = observer(({navigation}) => {
    const {currentUser, resetPassword} = useAuth();
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const {dark} = useTheme();
    // FIXME Prob don't need handleSuccess if using this
    useEffect(() => {
      if (currentUser) {
        navigation.navigate('Home');
      }
    }, [currentUser]);
    const validateForm = () => {
      let noErrors = true;
      if (!email || !email.length || !validateEmail(email.trim())) {
        setEmailError(true);
        noErrors = false;
      } else {
        setEmailError(false);
      }
      return noErrors;
    };
    const renderInnerForm = () => (
      <>
        <TextInput
          label="Email"
          mode="outlined"
          autoCapitalize={"none"}
          keyboardType="email-address"
          autoCorrect={false}
          autoComplete="email"
          style={{marginBottom: 20}}
          value={email}
          onChangeText={(text) => setEmail(text)}
        />
        <Button
          style={{height: 50, backgroundColor: palette.secondary, marginTop: 20}}
          onPress={async () => {
            setSubmitted(true);
            if (!validateForm()) {
              return;
            }
            setSubmitting(true);
            console.log(email);
            // await resetPassword(email).then(() => {
            //   alert('If an account is associated with this email, you will receive instructions on resetting your password.')
            // });
          }}
          text="Send Reset Request"
          textStyle={{fontSize: 14}}
        />
      </>
    )
    const renderForm = () => {
      if (isMobile) {
        return renderInnerForm();
      }
      return (
        <View style={{ width: '100%', maxWidth: 480, margin: 'auto', padding: 80, backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: 20}}>
          {renderInnerForm()}
        </View>
      )
    }
    // TODO Include a useEffect to listen to the user - if the user is already logged in, redirect them to the home screen or correct url path
    return (
      <View style={flexStyle}>
        <ImageBackground
          source={isMobile ? require('../../assets/images/auth-background.jpg') : require('../../assets/images/auth-background-web.jpg')}
          style={{width: '100%', height: '100%'}}
        >
          <Screen style={containerStyle} preset="scroll" backgroundColor={color.transparent}>
            {renderForm()}
          </Screen>
        </ImageBackground>
      </View>
    );
  },
)

