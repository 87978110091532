import React, { useState, createContext, useContext } from 'react';

export interface UserType {
  id?: string;
  firstName?: string;
  lastName?: string;
  fullName?: string;
  profileImageUrl?: string;
  coverImageUrl?: string;
}

interface ContextType {
  selectedUser: UserType;
  setSelectedUser?: (user: UserType | null) => void;
}

const UserDetailContext = createContext<ContextType>({
  selectedUser: {},
});

// @ts-ignore
export const UserDetailProvider = ({ children }) => {
  const [selectedUser, setSelectedUser] = useState({});
  // @ts-ignore
  const updateSelectedUser = (user: UserType | null) => setSelectedUser(user);
  return (
    <UserDetailContext.Provider
      value={{
        selectedUser,
        setSelectedUser: updateSelectedUser,
      }}
    >
      {children}
    </UserDetailContext.Provider>
  );
};

export const useUserContext = () => useContext(UserDetailContext);
export default UserDetailContext;
