import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {isMobile} from '../../components/mobile-device-detect';
import IonIcons from 'react-native-vector-icons/Ionicons';
import {MovieType, useMovieContext} from '../../contexts/MovieDetailContext';
import {AdMobBanner} from 'expo-ads-admob';
import User from '../../models/user';
import {useAuth} from '../../contexts/AuthContext';
import {baseOriginalPath, basePath, thumbnailPath} from "../../models/movie";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  ImageBackground,
  StyleSheet,
  Platform,
  Linking,
  useColorScheme,
} from "react-native";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {observer} from "mobx-react-lite";
import Rating from 'react-native-star-rating-widget';
import {color} from "../../theme";
import {Screen} from "../../components";
import {useTheme} from "@react-navigation/native";
import {LinearGradient} from "expo-linear-gradient";
import SkeletonView from "./skeleton-detail";
import SkeletonList from "./skeleton-scroll-list";
import {TrailerPlayer} from "../../components/trailer-player/index.web";
import {Toast} from "../../components/toast";
import {MovieRatingCircularProgress} from "../../components/movie-rating-circular-progress";
import {WatchedWith} from "../../components/watched-with";
import {WatchedDate} from "../../components/watched-date";
import {palette} from "../../theme/palette";
import { ListItem } from 'react-native-elements';
import {BannerAd} from "../../components/google-ad-manager";

const movieDbApiKey = process.env.TMDB_API_KEY;
const streamingAvailabilityApiKey = process.env.STREAMING_AVAILABILITY_API_KEY;

// import YouTube from "react-native-youtube";

// FIXME Update this view for TV series'
export const TvShowDetailScreen: React.FC<StackScreenProps<NavigatorParamList, "Movie Detail">> = observer(({route, navigation}) => {
  const {currentUser} = useAuth();
  const {selectedMovie, setSelectedMovie} = useMovieContext();
  const [cast, setCast] = useState([]);
  const [hasSeen, setHasSeen] = useState('');
  const [bookmarked, setBookmarked] = useState(false);
  const [showVideoTrailer, setShowVideoTrailer] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingCast, setLoadingCast] = useState(true);
  const [loadingRecommended, setLoadingRecommended] = useState(false);
  const [loadingSimilar, setLoadingSimilar] = useState(true);
  const [selectedSeasonId, setSelectedSeasonId] = useState(1);
  const [selectedSeason, setSelectedSeason] = useState(null);
  const [recommendedMovies, setRecommendedMovies] = useState<MovieType[]>([]);
  const [accordionState, setAccordionState] = useState({});
  const [similarMovies, setSimilarMovies] = useState<MovieType[]>([]);
  const [rating, setRating] = useState(0);
  const [streamingProviders, setStreamingProviders] = useState([]);

  const showDetail = (movie: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(movie);
    // FIXME Replace? If not, the back button needs to reset the selected movie
    // navigation.navigate("Movie Detail", movie.id);
    // @ts-ignore
    navigation.setParams({movieId: movie.id});
  };
  const showPersonCredits = (castMemberId) => {
    navigation.navigate("Cast Member", { personId: castMemberId });
  }
  const {colors} = useTheme();
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    innerContainer: {
      backgroundColor: darkMode ? '#121212' : '#efefef',
    },
    image: {
      flex: 1,
      justifyContent: "center"
    },
    title: {
      color: colors.text,
      fontSize: 20,
      marginTop: 16,
      marginBottom: 10,
      fontWeight: '500',
    },
    description: {
      fontSize: 14,
      color: colors.text,
    },
    headerBackground: {
      height: isMobile ? 200 : 400,
      width: '100%',
    },
    backgroundVideo: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      height: 0,
    },
  });
  const scrollRef = useRef();
  useEffect(() => {
    // Scroll to the topscrollRef
    scrollRef && scrollRef.current && scrollRef.current.scrollTo(0);
    // @ts-ignore
    let {showId} = route.params;
    if (selectedMovie.id || showId) {
      if (selectedMovie.id) {
        showId = selectedMovie.id;
      }
      // console.log('Getting details for movie: ' + movieId);
      if (currentUser && currentUser.uid) {
        User.isOnWatchlist(currentUser.uid, `tv-${showId}`).then(
          isOnList => {
            setBookmarked(isOnList);
          }
        );
        User.getSeenStatus(currentUser.uid, `tv-${showId}`).then(
          seenStatus => {
            setHasSeen(seenStatus);
          }
        );
        User.getRating(currentUser.uid, `tv-${showId}`).then(r => {
          if (r) {
            setRating(r);
          }
        });
      }

      axios
        .get(
          `https://api.themoviedb.org/3/tv/${showId}/credits?api_key=${movieDbApiKey}&append_to_response=videos`
        )
        .then(res => {
          const {data} = res;
          setCast(
            data.cast.map((member: any) => ({
              // TODO https://developers.themoviedb.org/3/discover/movie-discover use "with_cast" with single ID to find all their movies
              id: member.id,
              name: member.name,
              character: member.character,
              image:
                member.profile_path &&
                `${basePath}${member.profile_path}`,
            }))
          );
          setLoadingCast(false);
        }).catch(() => {
        setLoadingCast(false);
      });

      // FIXME These don't look right - they prob need the user to be signed in to TMDB and adding reviews there
      // axios
      //   .get(
      //     `https://api.themoviedb.org/3/tv/${showId}/recommendations?api_key=${movieDbApiKey}`
      //   )
      //   .then(res => {
      //     const { data } = res;
      //     setRecommendedMovies(
      //       data.results
      //         .filter((movie: any) => {
      //           return !!movie.backdrop_path;
      //         })
      //         .map((movie: any) => ({
      //           id: movie.id,
      //           title: movie.title,
      //           description: movie.overview,
      //           posterImage:
      //             movie.poster_path &&
      //             `${basePath}${movie.poster_path}`,
      //           posterBackdrop:
      //             movie.backdrop_path &&
      //             `${baseOriginalPath}${movie.backdrop_path}`,
      //           year: movie.release_date.substring(0, 4),
      //           rating: movie.vote_average,
      //           ratingCount: movie.vote_count,
      //         }))
      //     );
      //   });

      axios
        .get(
          `https://api.themoviedb.org/3/tv/${showId}/similar?api_key=${movieDbApiKey}`
        )
        .then(res => {
          const {data} = res;
          setSimilarMovies(
            data.results
              .filter((movie: any) => {
                return !!movie.backdrop_path;
              })
              .map((movie: any) => ({
                id: movie.id,
                title: movie.title,
                description: movie.overview,
                posterImage:
                  movie.poster_path &&
                  `${basePath}${movie.poster_path}`,
                posterBackdrop:
                  movie.backdrop_path &&
                  `${baseOriginalPath}${movie.backdrop_path}`,
                year: movie.release_date.substring(0, 4),
                rating: movie.vote_average,
                ratingCount: movie.vote_count,
              }))
          );
          setLoadingSimilar(false);
        }).catch(() => {
        setLoadingSimilar(false);
      });

      // FIXME Don't run this in dev (try to detect whether hot reloads are enabled)
      axios
        .request({
          method: 'GET',
          url:
            'https://streaming-availability.p.rapidapi.com/get/basic',
          params: {
            country: 'us',
            tmdb_id: `tv/${showId}`,
            output_language: 'en',
          },
          headers: {
            'x-rapidapi-host':
              'streaming-availability.p.rapidapi.com',
            'x-rapidapi-key': streamingAvailabilityApiKey,
          },
        })
        .then(function (response) {
          console.log('Streaming providers:');
          console.log(response.data);
          console.log(response.data.streamingInfo);
          const services = Object.keys(response.data.streamingInfo);
          const providers = services.map(name => ({
            name,
            // @ts-ignore
            logo: providerLogos[name],
            link: response.data.streamingInfo[name].us.link,
          }));
          // @ts-ignore
          setStreamingProviders(providers);
        })
        .catch(function (error) {
          console.error('Streaming availability API error:');
          console.error(error);
        });
    }
    // @ts-ignore
  }, [selectedMovie.id, route.params.movieId]);

  useEffect(() => {
    let {showId} = route.params;
    if (selectedMovie.id || showId) {
      if (selectedMovie.id) {
        showId = selectedMovie.id;
      }
      axios
        .get(
          `https://api.themoviedb.org/3/tv/${showId}/season/${selectedSeasonId}?api_key=${movieDbApiKey}&append_to_response=videos`
        )
        .then(res => {
          console.log('Got episodes for season');
          console.log(res);
          setSelectedSeason(res.data);
        })
        .catch(function (error) {
          console.error('Unable to get episodes for season');
          console.error(error);
        });
    }
  }, [selectedSeasonId]);

  useEffect(() => {
    // @ts-ignore
    let {showId} = route.params;
    if (selectedMovie.id || showId) {
      if (selectedMovie.id) {
        console.log('Using selectedMovie.id')
        showId = selectedMovie.id;
      } else {
        setLoadingDetail(true);
      }
      axios
        .get(
          `https://api.themoviedb.org/3/tv/${showId}?api_key=${movieDbApiKey}&append_to_response=videos`
        )
        .then(res => {
          const {data} = res;
          console.log('Got data for tv show:');
          console.log(data);
          const {videos} = data;
          const {results} = videos;
          if (results) {
            results.forEach(async (result: any) => {
              if (result.type === 'Trailer') {
                if (result.site === 'YouTube') {
                  setSelectedMovie?.({
                    id: data.id,
                    title: data.title,
                    description: data.overview,
                    posterImage:
                      data.poster_path && `${basePath}${data.poster_path}`,
                    posterBackdrop:
                      data.backdrop_path &&
                      `${baseOriginalPath}${data.backdrop_path}`,
                    year: data.first_air_date.substring(0, 4),
                    rating: data.vote_average,
                    ratingCount: data.vote_count,
                    videoPreview: `https://youtu.be/${result.key}`,
                    youtubeId: result.key,
                    // @ts-ignore
                    seasons: data.seasons.map((season) => ({
                      airDate: season.air_date,
                      episodeCount: season.episode_count,
                      id: season.id,
                      name:	season.name,
                      description: season.overview,
                      posterImage: season.poster_path && `${basePath}${season.poster_path}`,
                      seasonNumber: season.season_number,
                    })).reverse()
                  });
                }
                // TODO Other providers?
              }
            });
            setSelectedMovie?.({
              id: data.id,
              title: data.title,
              description: data.overview,
              posterImage:
                data.poster_path && `${basePath}${data.poster_path}`,
              posterBackdrop:
                data.backdrop_path &&
                `${baseOriginalPath}${data.backdrop_path}`,
              year: data.first_air_date.substring(0, 4),
              rating: data.vote_average,
              ratingCount: data.vote_count,
              // @ts-ignore
              seasons: data.seasons.map((season) => ({
                airDate: season.air_date,
                episodeCount: season.episode_count,
                id: season.id,
                name:	season.name,
                description: season.overview,
                posterImage: season.poster_path && `${basePath}${season.poster_path}`,
                seasonNumber: season.season_number,
              })).reverse()
            });
            setLoadingDetail(false);
          }
        }).catch((err) => {
        if (selectedMovie.id) {
          setLoadingDetail(false);
        }
        // TODO Show an error screen instead? There was a problem getting the movie details
        alert(err);
      });
    }
  }, []);
  // const share = () => {
  //   // FIXME Use Branch deep links for this
  //   // @ts-ignore
  //   // if (window.navigator.share) {
  //   //   // @ts-ignore
  //   //   window.navigator.share({
  //   //     title: selectedMovie.title,
  //   //     text: `Want to watch "${selectedMovie.title}"?`,
  //   //     url: window.location.href,
  //   //   });
  //   // } else {
  //   //   // TODO In-browser solution
  //   // }
  // };
  const showToast = (message: string) => {
    Toast.show(message);
  }
  // FIXME Require the user is logged in for this
  const toggleBookmarked = () => {
    let message = `Added "${selectedMovie.title}" to Watch List`;
    if (bookmarked) {
      message = `Removed "${selectedMovie.title}" from Watch List`;
      User.removeFromWatchlist(currentUser.uid, `${selectedMovie.id}`)
        .then(() => {
          console.log('Removed successfully');
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      User.addToWatchlist(currentUser.uid, selectedMovie)
        .then(() => {
          console.log('Added successfully');
        })
        .catch(err => {
          console.error(err);
        });
    }
    showToast(message);
    setBookmarked(!bookmarked);
  };
  const shouldShowAds = () => {
    return true;
    // FIXME Only if user is logged out or they have a premium account
    // TODO They still aren't showing up - test on live site
    return (
      currentUser &&
      // FIXME Set a "premium" flag on the user object to indicate they get premium features and no ads
      currentUser.email !== 'paigemelissa0@gmail.com' &&
      currentUser.email !== 'trevordboyer@gmail.com' &&
      currentUser.email !== 'devs@elite-dev.com' // TODO Remove this to test
    );
  };
  const toggleAccordionItem = (key) => {
    if (accordionState[key]) {
      setAccordionState({ ...accordionState, [key]: false });
    } else {
      setAccordionState({ ...accordionState, [key]: true });
    }
  };
  // const searchUsers = (searchText: string) => {
  //   User.searchUsers(searchText.toLowerCase()).then(users => {
  //     setLoadingUsers(false);
  //     setUserResults(users);
  //   });
  // };
  const renderActionButtons = () => (
    <View
      style={{
        position: 'absolute',
        right: 16,
        zIndex: 1,
        top: isMobile ? 130 : 380,
        marginBottom: 16,
        paddingTop: 16,
        paddingBottom: 16,
        // borderTop: '1px solid #656565',
        // borderBottom: '1px solid #656565',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/* FIXME Make these all reusable - the code is duplicated aside from the text and icon */}
      {(!hasSeen || hasSeen === 'no') && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHasSeen('yes');
              showToast('Seen status set to "Yes"');
              if (currentUser) {
                User.setSeenStatus(currentUser.uid, `${selectedMovie.id}`, 'yes');
                // FIXME I'm not sure we want to do this unless they manually pick a date
                // User.setWatchTime(currentUser.uid, `${selectedMovie.id}`, new Date());
              }
            }}
          >
            <IonIcons name="checkmark-circle-outline" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: colors.text}}>Seen</Text>
        </View>
      )}
      {hasSeen === 'maybe' && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHasSeen('no');
              showToast('Seen status set to "No"');
              if (currentUser) {
                User.setSeenStatus(currentUser.uid, `${selectedMovie.id}`, 'no');
              }
            }}
          >
            <IonIcons name="help-circle-outline" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <span style={{fontSize: 12, color: colors.text}}>Seen</span>
        </View>
      )}
      {hasSeen === 'yes' && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHasSeen('maybe');
              showToast('Seen status set to "Maybe"');
              if (currentUser) {
                User.setSeenStatus(currentUser.uid, `${selectedMovie.id}`, 'maybe');
              }
            }}
          >
            <IonIcons name="checkmark-circle" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: colors.text}}>Seen</Text>
        </View>
      )}
      {bookmarked ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity onPress={toggleBookmarked}>
            <IonIcons name="bookmark" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: colors.text}}>Remove</Text>
        </View>
      ) : (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity onPress={toggleBookmarked}>
            <IonIcons name="bookmark-outline" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: colors.text}}>Add</Text>
        </View>
      )}
    </View>
  );
  if (loadingDetail || !selectedMovie) {
    return (
      <View style={styles.container}>
        <Screen preset="scroll" backgroundColor={color.transparent}>
          <SkeletonView />
          <SkeletonList />
          <SkeletonList />
        </Screen>
      </View>
    );
  }
  return (
    <View style={styles.container}>
      {/*  title="Details"*/}
      {/*  leftButtons={*/}
      {/*    <IonButtons slot="start">*/}
      {/*      <IonBackButton color="secondary" />*/}
      {/*    </IonButtons>*/}
      {/*  }*/}
      {/*  rightButtons={[*/}
      {/*    <IonButtons slot="end" onClick={share}>*/}
      {/*      <IonButton>*/}
      {/*        {isIOS ? (*/}
      {/*          <IonIcon slot="icon-only" icon={shareOutline} />*/}
      {/*        ) : (*/}
      {/*          <IonIcon slot="icon-only" icon={shareSocial} />*/}
      {/*        )}*/}
      {/*      </IonButton>*/}
      {/*    </IonButtons>,*/}
      {/*  ]}*/}
      {/*>*/}
      <Screen style={styles.innerContainer} backgroundColor={color.transparent}>
        <ScrollView ref={scrollRef}>
          {renderActionButtons()}
          <ImageBackground
            // @ts-ignore
            source={{uri: selectedMovie.posterBackdrop || selectedMovie.posterImage}}
            resizeMode="cover"
            style={styles.headerBackground}
          />
          <LinearGradient
            colors={['transparent', darkMode ? '#121212' : '#efefef']}
            style={{
              marginTop: 0,
              position: 'absolute',
              top: isMobile ? 50 : 0,
              width: '100%',
              height: isMobile ? 150 : 400,
            }}
          />
          {!isMobile && (
            <View
              style={{
                marginTop: 0,
                marginLeft: -16,
                marginRight: -16,
                // background: isMobile
                //     ? `linear-gradient(0, ${
                //         darkMode ? '#121212' : 'white'
                //     }, transparent)`
                //     : `linear-gradient(45deg, ${
                //         darkMode ? '#121212' : 'white'
                //     }, transparent)`,
                position: 'absolute',
                top: isMobile ? 50 : 0,
                width: '100%',
                height: isMobile ? 150 : 400,
              }}
            />
          )}
          {!isMobile && (
            <Image
              // @ts-ignore
              source={{ uri: selectedMovie.posterImage && selectedMovie.posterImage.replace('/w500/', '/w200/') }}
              style={{
                height: 300,
                position: 'absolute',
                top: 50,
                left: 30,
                borderRadius: 8,
              }}
            />
          )}
          <View style={{padding: 16}}>
            <Text style={styles.title}>
              {selectedMovie.title}{' '}
              <Text style={{fontSize: 14, color: colors.text}}>
                {`(${selectedMovie.year})`}
              </Text>
            </Text>
            {hasSeen === 'yes' && (
              <>
                <WatchedWith movie={selectedMovie} />
                <WatchedDate movie={selectedMovie} />
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Text style={{...styles.title, ...{fontSize: 14, marginTop: 20, color: darkMode ? 'white' : '#121212' }}}>Your Rating</Text>
                  <View
                    style={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                      marginBottom: 30,
                    }}
                  >
                    <Rating
                      rating={rating}
                      onChange={(newValue) => {
                        // @ts-ignore
                        setRating(newValue);
                        if (currentUser) {
                          // @ts-ignore
                          User.addRating(currentUser.uid, `${selectedMovie.id}`, newValue);
                        }
                      }}
                      starSize={30}
                      style={{ alignSelf: 'flex-end' }}
                    />
                  </View>
                </View>
              </>
            )}
            <View style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
              <View style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
                <MovieRatingCircularProgress
                  title={undefined}
                  titleColor={'transparent'}
                  titleFontSize={12}
                  fontSize={12}
                  titleStyle={{}}
                  maxValue={100}
                  radius={28}
                  value={selectedMovie.rating ? selectedMovie.rating * 10 : 0}
                  activeStrokeWidth={6}
                  textColor={colors.text}
                  activeStrokeColor={'#00b1b2'}
                  inActiveStrokeColor={'#656565'}
                  inActiveStrokeOpacity={0.5}
                  inActiveStrokeWidth={6}
                  circleBackgroundColor={'transparent'}
                  valueSuffix={'%'}
                />
                <Text
                  style={{
                    fontSize: 12,
                    alignSelf: 'center',
                    marginLeft: 10,
                    color: colors.text,
                  }}
                >
                  {selectedMovie.ratingCount} ratings
                </Text>
              </View>
              {/* FIXME There's a z-index issue here with the action buttons and the provider logo but doesn't seem like a straightforward fix */}
              {Platform.OS === 'web' && selectedMovie.videoPreview && (
                <TrailerPlayer
                  showVideoTrailer={showVideoTrailer}
                  videoId={selectedMovie.videoPreview.replace(
                    'https://youtu.be/',
                    ''
                  )}
                  onClose={() => {
                    setShowVideoTrailer(false);
                    console.debug('Closing video modal');
                  }}
                />
              )}
              {(selectedMovie.videoPreview && selectedMovie.videoPreview.length > 0) && (
                <View
                  style={{
                    flex: 1,
                    borderLeft: '1px solid #aaa',
                    alignItems: 'center',
                    height: 44,
                  }}
                >
                  <TouchableOpacity onPress={() => setShowVideoTrailer(true)}>
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                      <IonIcons name="play-outline" size={20} style={{color: colors.text, marginTop: 10}}/>
                      <Text style={{color: colors.text, marginLeft: 10, lineHeight: 44}}>
                        Play Trailer
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              )}
            </View>
            {streamingProviders.length === 0 && (
              <Text style={{marginTop: 22, color: colors.text}}>Not currently streaming</Text>
            )}
            {/* TODO https://www.digitalfodder.com/netflix-affiliate-program-alternatives/ - most seem to have affiliate programs */}
            {streamingProviders.length > 0 && (
              <>
                <Text style={{ color: colors.text, marginBottom: 6, marginTop: 10 }}>Watch Now</Text>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  {streamingProviders.map(provider => (
                    <TouchableOpacity onPress={() => Linking.openURL(provider.link)}>
                      <Image
                        key={provider.name}
                        source={{uri: provider.logo}}
                        // @ts-ignore
                        alt={provider.name}
                        style={{height: 80, width: 80, borderRadius: 8, marginRight: 10}}
                      />
                    </TouchableOpacity>
                  ))}
                </View>
              </>
            )}
            {shouldShowAds() && (
              <View style={{ marginTop: 20, marginHorizontal: -15 }}>
                <BannerAd
                  adUnitId={ isMobile ? '/22758720814/MOVIES.PLUS.TV.MEDIUM.RECTANGLE' : '/22758720814/MOVIES.PLUS.TV.LEADERBOARD' }
                  adUnitSize={ isMobile ? '[[300, 250]]' : '[[728, 90]]' }
                />
              </View>
            )}
            <Text style={styles.title}>{selectedMovie.seasons && selectedMovie.seasons.length} Seasons</Text>
            <ScrollView horizontal>
              {/* FIXME Why is reverse not working here? Should be newest season first */}
              {selectedMovie.seasons && selectedMovie.seasons.map((season: any) => (
                <TouchableOpacity
                  onPress={() => {
                    setSelectedSeasonId(season.seasonNumber);
                    setSelectedSeason(season);
                  }}
                >
                  <View
                    key={season.name}
                    style={{
                      width: 140,
                      minWidth: 140,
                      height: 240,
                      marginLeft: 6,
                      marginRight: 6,
                      borderRadius: 5,
                      overflow: 'hidden',
                      backgroundColor: darkMode ? palette.offBlack : 'white',
                    }}
                  >
                    <Image
                      source={{
                        uri: (season.posterImage && season.posterImage.replace('/w500/', '/w200/')) || 'https://via.placeholder.com/140x210?text=No%20Image'
                      }}
                      style={{width: 140, height: 210}}
                    />
                    <Text
                      style={{
                        fontSize: 14,
                        margin: 0,
                        fontWeight: '700',
                        color: colors.text,
                        textAlign: 'center',
                        marginTop: 8,
                      }}
                    >
                      {season.name}
                    </Text>
                  </View>
                </TouchableOpacity>
              ))}
            </ScrollView>
            <View>
              <Text style={styles.title}>{selectedSeason && selectedSeason.episodes && selectedSeason.episodes.length} Episodes</Text>
              {/* TODO Accordion view for all episodes with description and "seen" toggle */}
              <View style={{ marginHorizontal: -16 }}>
                {selectedSeason && selectedSeason.episodes && selectedSeason.episodes.map((episode, i) => (
                  <ListItem.Accordion
                    content={
                      <>
                        <ListItem.Content>
                          <ListItem.Title style={{ color: darkMode ? 'white' : '#121212' }}>
                            S{episode.season_number}:E{episode.episode_number} - {episode.name}
                          </ListItem.Title>
                        </ListItem.Content>
                      </>
                    }
                    containerStyle={{
                      backgroundColor: darkMode ? palette.offBlack : 'white',
                    }}
                    isExpanded={accordionState[i] === true}
                    onPress={(event) => {
                      console.log(accordionState);
                      toggleAccordionItem(i);
                    }}
                  >
                    {accordionState[i] === true && (
                      <ListItem
                        key={i}
                        bottomDivider
                        containerStyle={{
                          backgroundColor: 'transparent',
                        }}
                      >
                        <ListItem.Content>
                          <ListItem.Title style={{ color: darkMode ? 'white' : '#121212' }}>{episode.overview || 'No description available'}</ListItem.Title>
                        </ListItem.Content>
                      </ListItem>
                    )}
                  </ListItem.Accordion>
                ))}
              </View>
            </View>
          </View>
          {shouldShowAds() && (
            <View style={{ marginTop: 20, marginHorizontal: -15 }}>
              <BannerAd
                adUnitId={ isMobile ? '/22758720814/MOVIES.PLUS.TV.MEDIUM.RECTANGLE' : '/22758720814/MOVIES.PLUS.TV.LEADERBOARD' }
                adUnitSize={ isMobile ? '[[300, 250]]' : '[[728, 90]]' }
              />
            </View>
          )}
          {loadingCast && <SkeletonList />}
          {cast.length > 0 && (
            <View>
              <View style={{padding: 16}}>
                <Text style={styles.title}>Cast</Text>
              </View>
              <ScrollView horizontal>
                {cast.map((member: any) => (
                  <TouchableOpacity onPress={() => showPersonCredits(member.id)}>
                    <View
                      key={member.name}
                      style={{
                        width: 160,
                        minWidth: 160,
                        height: 250,
                        marginLeft: 6,
                        marginRight: 6,
                        borderRadius: 5,
                        overflow: 'hidden',
                        // backgroundColor: darkMode ? palette.offBlack : 'white',
                      }}
                    >
                      <Image
                        source={{
                          uri: (member.image && member.image.replace('/w500/', '/w200/')) || 'https://via.placeholder.com/140x210?text=No%20Image'
                        }}
                        style={{ width: 160, height: 160, borderRadius: 90 }}
                      />
                      <View style={{margin: 10}}>
                        <Text
                          style={{
                            fontSize: 14,
                            margin: 0,
                            fontWeight: '700',
                            color: colors.text,
                            textAlign: 'center',
                          }}
                        >
                          {member.name}
                        </Text>
                        <Text
                          style={{
                            fontSize: 13,
                            margin: 0,
                            marginTop: 5,
                            color: colors.text,
                            textAlign: 'center',
                          }}
                        >
                          {member.character}
                        </Text>
                      </View>
                    </View>
                  </TouchableOpacity>
                ))}
              </ScrollView>
            </View>
          )}
          {/*<br/>*/}
          {/*<br/>*/}
          {loadingRecommended && <SkeletonList />}
          {recommendedMovies.length > 0 && (
            <View>
              <View style={{display: 'flex', padding: 16}}>
                <Text style={styles.title}>Other Viewers Watched</Text>
                {/* <Text>View all</span> */}
              </View>
              <ScrollView horizontal={true}>
                {recommendedMovies.map((movie: any) => (
                  <TouchableOpacity key={movie.id} onPress={() => showDetail(movie)}>
                    <Image
                      source={{uri: movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')}}
                      style={{
                        height: 180,
                        margin: 3,
                        width: 120,
                        borderRadius: 5,
                      }}
                    />
                  </TouchableOpacity>
                ))}
              </ScrollView>
            </View>
          )}
          {loadingSimilar && <SkeletonList />}
          {similarMovies.length > 0 && (
            <View>
              <View style={{display: 'flex', padding: 16}}>
                <Text style={styles.title}>Similar Movies</Text>
                {/* <Text>View all</span> */}
              </View>
              <ScrollView horizontal={true}>
                {similarMovies.map((movie: any) => (
                  <View
                    style={{margin: 5}}
                  >
                    <TouchableOpacity key={movie.id} onPress={() => showDetail(movie)}>
                      <Image
                        source={{uri: movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')}}
                        style={{
                          height: 180,
                          margin: 3,
                          width: 120,
                          borderRadius: 5,
                        }}
                      />
                    </TouchableOpacity>
                  </View>
                ))}
              </ScrollView>
            </View>
          )}
          {/*{(selectedMovie.youtubeId && selectedMovie.youtubeId.length > 0) && (*/}
          {/*  <YouTube*/}
          {/*    apiKey={''}*/}
          {/*    videoId={selectedMovie.youtubeId} // The YouTube video ID*/}
          {/*    play={showVideoTrailer}*/}
          {/*    fullscreen*/}
          {/*    loop={false}*/}
          {/*    // onReady={e => this.setState({ isReady: true })}*/}
          {/*    // onChangeState={e => this.setState({ status: e.state })}*/}
          {/*    // onChangeQuality={e => this.setState({ quality: e.quality })}*/}
          {/*    // onError={e => this.setState({ error: e.error })}*/}
          {/*    style={{ alignSelf: 'stretch', height: 300 }}*/}
          {/*  />*/}
          {/*)}*/}
        </ScrollView>
      </Screen>
    </View>
  );
});
