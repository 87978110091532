import React, {useEffect, useState} from "react";
import {
  Autocomplete,
  Avatar,
  Chip,
  CircularProgress,
  createTheme,
  TextField,
  ThemeProvider
} from "@mui/material";
import Rating from "react-native-star-rating-widget";
import {useColorScheme, Text} from "react-native";
import User from "../../models/user";
import {useAuth} from "../../contexts/AuthContext";

export const WatchedWith = ({ movie }) => {
  const {currentUser} = useAuth();
  const [watchedWithUsers, setWatchedWithUsers] = useState([]);
  const [showAutocomplete, setShowAutocomplete] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [userResults, setUserResults] = useState([]);
  const [shouldEditUsers, setShouldEditUsers] = useState(false);
  const [tempUsers, setTempUsers] = useState<string[]>([]);
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      User.getWatchedWithUsers(currentUser.uid, `${movie.id}`).then(
        users => {
          if (users && users.length > 0) {
            setWatchedWithUsers(users);
          }
        }
      );
    }
  }, [currentUser]);
  const searchUsers = (searchText: string) => {
    User.searchUsers(searchText.toLowerCase()).then(users => {
      setLoadingUsers(false);
      setUserResults(users);
    });
  };
  const renderWatchedWithPicker = () => (
    <ThemeProvider theme={theme}>
      {/* FIXME Filter out their own user account - maybe just rename that one to "Myself" or something and search it by default? */}
      <Autocomplete
        fullWidth
        multiple
        filterSelectedOptions
        sx={{ marginTop: '20px', marginBottom: '20px' }}
        open={showAutocomplete}
        onOpen={() => {
          setShowAutocomplete(true);
        }}
        onClose={() => {
          setShowAutocomplete(false);
        }}
        onChange={(event, newValue) => {
          console.log('Selected watched with users:');
          console.log(newValue);
          setTempUsers(newValue.map(user => user.id));
        }}
        onBlur={() => {
          User.setWatchedWithUsers(currentUser.uid, `${movie.id}`, tempUsers);
        }}
        defaultValue={watchedWithUsers}
        filterOptions={(x) => x}
        // TODO Ensure 'no one' or something is a default option - users may want to state that they watched it alone
        options={userResults}
        loading={loadingUsers}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Watched with..."
            onChange={(e) => {
              searchUsers(e.target.value);
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loadingUsers ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              avatar={
                <Avatar
                  alt={option.fullName}
                  src={option.profileImageUrl}
                >
                  {option.firstName?.charAt(0)}
                </Avatar>
              }
              label={option.fullName}
              {...getTagProps({ index })}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Avatar src={ option.profileImageUrl } style={{ height: 40, width: 40 }}>
                {option.firstName?.charAt(0) || option.fullName?.charAt(0)}
              </Avatar>
              <div style={{ flex: 1, margin: '0 12px' }}>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: 700,
                    // margin: '5px 0 0 0',
                  }}
                >
                  {option.fullName}
                </Text>
              </div>
            </div>
          </li>
        )}
      />
    </ThemeProvider>
  );
  const renderWatchedWithUsers = () => (
    <div>
      <div style={{ display: 'flex', marginTop: 8, marginBottom: 12, width: 'calc(100vw - 40px)' }}>
        <Text style={{ margin: 0, flex: 1, color: darkMode ? 'white' : '#121212' }}>Watched With</Text>
        <Text
          style={{ alignSelf: 'center', color: darkMode ? 'white' : '#121212' }}
          onPress={() => setShouldEditUsers(true)}
        >
          Edit
        </Text>
      </div>
      <div
        style={{
          overflowY: 'hidden',
          overflowX: 'scroll',
          marginLeft: -16,
          marginRight: -16,
        }}
      >
        <div style={{ display: 'flex', padding: 10 }}>
          <ThemeProvider theme={theme}>
            {watchedWithUsers.map((user) => (
              <Chip
                avatar={
                  <Avatar alt={user.fullName} src={user.profileImageUrl}>
                    {user.firstName?.charAt(0)}
                  </Avatar>
                }
                label={(
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ fontSize: 10 }}>{user.fullName}</div>
                    <Rating
                      value={async () => await User.getRating(user.id!, `${movie.id}`)}
                      starSize={12}
                      onChange={() => {}}
                      starStyle={{ marginLeft: 0, marginRight: 0 }}
                    />
                  </div>
                )}
              />
            ))}
          </ThemeProvider>
        </div>
      </div>
    </div>
  );
  return (
    <div style={{ display: 'flex', borderBottom: '1px solid #323232' }}>
      {/* TODO On blur, set "editing" to false and hide the picker and show the chips below with "edit" button */}
      {(watchedWithUsers.length === 0 || shouldEditUsers) && renderWatchedWithPicker()}
      {watchedWithUsers.length > 0 && !shouldEditUsers && renderWatchedWithUsers()}
    </div>
  )
};
