import React, { useState, createContext, useContext } from 'react';

export interface QueryType {
  movieSearchQuery: string;
  setMovieSearchQuery?: (text: string) => void
  userSearchQuery: string;
  setUserSearchQuery?: (text: string) => void
}

const initialQueries = {
  movieSearchQuery: '',
  userSearchQuery: '',
};

const QueryContext = createContext<QueryType>(initialQueries);

export const QueryProvider = ({ children }) => {
  const [movieSearch, setMovieSearch] = useState(initialQueries.movieSearchQuery);
  const [userSearch, setUserSearch] = useState(initialQueries.userSearchQuery);
  const updateMovieSearch = (query: string) => {
    setMovieSearch(query);
  };
  const updateUserSearch = (query: string) => {
    setUserSearch(query);
  };
  return (
    <QueryContext.Provider
      value={{
        movieSearchQuery: movieSearch,
        userSearchQuery: userSearch,
        setMovieSearchQuery: updateMovieSearch,
        setUserSearchQuery: updateUserSearch,
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

export const useQueryContext = () => useContext(QueryContext);
export default QueryContext;
