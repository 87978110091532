import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import {
  MovieType,
  useMovieContext,
} from '../../contexts/MovieDetailContext';
import {Image, Text, TouchableOpacity, View, Dimensions, useColorScheme, Platform, ScrollView} from "react-native";
import { ListItem } from "react-native-elements";
import {ActivityIndicator, Card} from "react-native-paper";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../../navigators";
import {color} from "../../theme";
import {Screen} from "../../components";
import {palette} from "../../theme/palette";
import Ionicons from "react-native-vector-icons/Ionicons";
import MenuDrawer from "react-native-side-drawer";
import RangeSlider from './components/rangeslider';
import {providerLogos} from "../MovieDetail";
import SegmentedControl from '@react-native-segmented-control/segmented-control';
import Thumb from "./components/thumb";
import Rail from "./components/rail";
import RailSelected from "./components/railselected";
import Label from "./components/label";
import Notch from "./components/notch";
import Rating from "react-native-star-rating-widget";
import {useSettingsContext} from "../../contexts/AppContext";
import {isMobile} from "../../components/mobile-device-detect";

const movieDbApiKey = process.env.TMDB_API_KEY;

const basePath = 'https://image.tmdb.org/t/p/w500/';

const isCloseToBottom = ({layoutMeasurement, contentOffset, contentSize}) => {
  const paddingToBottom = 20;
  return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
};

let page = 1;
const currentYear = new Date().getFullYear();
const sortItems = ['popularity', 'release_date', 'vote_average'];
const ratingCountItems = [1000, 2000, 5000, 10000];
export const GenreListScreen: React.FC<StackScreenProps<NavigatorParamList, "Genre List">> = ({ route, navigation }) => {
  const {settings} = useSettingsContext();
  const {allProviders: providers} = settings;
  // FIXME Probably change providers above to allProviders and store providers locally to act as filter - they won't
  //  necessarily want to change their main list of providers to get this to work
  const providerString = Object.keys(providers).join('|');
  const [movieResults, setMovieResults] = useState([]);
  const [loadingMovies, setLoadingMovies] = useState(false);
  // const [sortBy, setSortBy] = useState('popularity.desc'); // TODO Store in persistence
  const [listStyle, setListStyle] = useState('grid'); // TODO Store in persistence
  const [sortType, setSortType] = useState('popularity');
  const [sortOrder, setSortOrder] = useState('desc');
  const [sortBy, setSortBy] = useState('popularity.desc'); // TODO Store in persistence
  const [showSidemenu, setShowSidemenu] = useState(false);
  const [startYear, setStartYear] = useState(null);
  const [endYear, setEndYear] = useState(null);
  const [rating, setRating] = useState<number | null>(3);
  const [ratingCount, setRatingCount] = useState<number | null>(1000);
  const { setSelectedMovie } = useMovieContext();
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  const [selectedProviders, setSelectedProviders] = useState<string[]>([]);
  const styles = {
    text: {
      color: darkMode ? 'white' : '#121212',
      fontWeight: '500',
    },
    title: {
      color: darkMode ? 'white' : '#121212',
      fontSize: 16,
      marginTop: 12,
      marginBottom: 8,
      fontWeight: '600',
    },
    slider: {

    },
  };
  const showDetail = (movie: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(movie);
    navigation.navigate('Movie Detail', { movieId: movie.id, movie });
  };
  const toggleListView = () => setListStyle(listStyle === 'grid' ? 'list' : 'grid');

  useEffect(() => {
    const { genre } = route.params;
    if (isMobile && genre) {
      navigation.setOptions({
        title: genre.title,
        // TODO Actions - possibly toggle list/grid views
        /*
          TODO Probably allow sorting - sort_by in query params - prob just popularity.desc, release_date.desc, and vote_average.desc
            although they don't have useful titles - if by vote, also include vote_count.gte=1000 or something
          popularity.asc, popularity.desc, release_date.asc, release_date.desc, revenue.asc, revenue.desc, primary_release_date.asc,
          primary_release_date.desc, original_title.asc, original_title.desc, vote_average.asc, vote_average.desc, vote_count.asc, vote_count.desc
        */
        headerRight: () => (
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <TouchableOpacity onPress={toggleListView}>
              <Ionicons
                style={{padding: 12}}
                name={listStyle === 'grid' ? (Platform.OS === 'ios' ? 'list-outline' : 'list') : (Platform.OS === 'ios' ? 'grid-outline' : 'grid')}
                size={listStyle === 'grid' ? 22 : 20}
                color={palette.primary}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setShowSidemenu(!showSidemenu)}>
              <Ionicons
                style={{padding: 12}}
                name={showSidemenu ? 'close' : (Platform.OS === 'ios' ? 'filter-outline' : 'filter')}
                size={showSidemenu ? 26 : 20}
                color={palette.primary}
              />
            </TouchableOpacity>
          </View>
        ),
      });
    }
  }, [route.params && route.params.genre, showSidemenu, listStyle]);
  useEffect(() => {
    const { genre } = route.params;
    if (genre) {
      const { key } = genre;
      if (!loadingMovies && key) {
        loadPage();
      }
    }
  }, [route.params && route.params.genre]);
  useEffect(() => {
    loadPage();
  }, [sortBy, page, rating, ratingCount, providerString]);
  const loadPage = () => {
    const { genre } = route.params;
    if (genre) {
      setLoadingMovies(true);
      const {key} = genre;
      // TODO Include with_watch_providers to filter to only the ones the user specifies in settings
      let url;
      let queryParams;
      if (sortType === 'popularity') {
        queryParams = `?api_key=${movieDbApiKey}&language=en&with_genres=${key}&page=${page}&sort_by=${sortBy}&with_watch_providers=${providerString}&watch_region=US`;
      } else if (sortType === 'vote_average') {
        queryParams = `?api_key=${movieDbApiKey}&language=en&with_genres=${key}&page=${page}&sort_by=${sortBy}&vote_count.gte=${ratingCount}&with_watch_providers=${providerString}&watch_region=US`;
      } else if (sortType === 'release_date') {
        queryParams = `?api_key=${movieDbApiKey}&language=en&with_genres=${key}&page=${page}&sort_by=${sortBy}&release_date.lte=${endYear}&release_date.gte=${startYear}&with_watch_providers=${providerString}&watch_region=US`;
      }
      if (key.indexOf('/') !== -1) {
        url = `https://api.themoviedb.org/3/${key}${queryParams}`;
      } else {
        url = `https://api.themoviedb.org/3/discover/movie${queryParams}`;
      }
      axios
        .get(url)
        .then(res => {
          setLoadingMovies(false);
          // console.debug(`Got movies for query: ${searchText}`);
          // console.debug(res.data);
          const movies = res.data.results.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.overview,
            posterImage:
              data.poster_path && `${basePath}${data.poster_path}`,
            posterBackdrop:
              data.backdrop_path &&
              `${basePath}${data.backdrop_path}`,
            year: data.release_date.substring(0, 4),
            rating: data.vote_average,
            ratingCount: data.vote_count,
          }));
          // console.debug(movies);
          if (page === 1) {
            setMovieResults([...movies]);
          } else {
            setMovieResults([...movieResults, ...movies]);
          }
        })
        .catch(err => {
          console.error(err);
          setLoadingMovies(false);
          setMovieResults([]);
        });
    }
  };
  const isSelected = (provider: string) => {
    return selectedProviders.indexOf(provider) !== -1;
  };

  const toggleProvider = (provider: string) => {
    if (selectedProviders.indexOf(provider) !== -1) {
      selectedProviders.splice(selectedProviders.indexOf(provider), 1);
    } else {
      selectedProviders.push(provider);
    }
    setSelectedProviders([...selectedProviders]);
  };
  const renderDescription = (movie) => {
    let desc = 'No description available';
    if (movie.description && movie.description.length > 0) {
      if (movie.description.length >= 60) {
        desc = `${movie.description.substring(0, 60)}...`;
      } else {
        desc = movie.description;
      }
    }
    return <Text style={{ fontSize: 12, color: darkMode ? 'white' : '#121212' }}>{desc}</Text>
  }
  const renderThumb = useCallback(() => <Thumb/>, []);
  const renderRail = useCallback(() => <Rail/>, []);
  const renderRailSelected = useCallback(() => <RailSelected/>, []);
  const renderLabel = useCallback(value => <Label text={value}/>, []);
  const renderNotch = useCallback(() => <Notch/>, []);
  const handleValueChange = useCallback((low, high) => {
    setStartYear(low);
    setEndYear(high);
  }, []);
  const renderContent = () => {
    const { genre } = route.params;
    const { key } = genre;
    return (
      <ScrollView style={{ height: '100%', backgroundColor: darkMode ? '#121212' : '#ffffff' }}>
        <View style={{ padding: 16 }}>
          {key.indexOf('/') === -1 && (
            <View>
              <Text style={styles.title}>Availability</Text>
              {/* TODO Let them pick which of their streaming services to filter to - toggle opacity to show on/off state - default to all unselected */}
              <ScrollView horizontal={true} style={{ marginHorizontal: -16, paddingHorizontal: 10 }}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  {Object.keys(providers).map((provider) => (
                    <TouchableOpacity onPress={() => toggleProvider(provider)}>
                      <Image
                        source={{ uri: providerLogos[providers[provider].toLowerCase().replace(new RegExp(' ', 'g'), '_')]}}
                        style={{ height: 50, width: 50, marginHorizontal: 4, borderRadius: 4, opacity: isSelected(provider) ? 1 : 0.4 }}
                      />
                    </TouchableOpacity>
                  ))}
                </View>
              </ScrollView>
            </View>
          )}
          <Text style={styles.title}>Sort By</Text>
          {/* TODO Alphabetical? */}
          <SegmentedControl
            values={['Popularity', 'Release Date', 'Rating']}
            selectedIndex={sortItems.indexOf(sortType)}
            onChange={(event) => {
              page = 1;
              const type = sortItems[event.nativeEvent.selectedSegmentIndex];
              setSortType(type);
              setSortBy(`${type}.${sortOrder}`);
            }}
          />
          <SegmentedControl
            style={{ marginTop: 12 }}
            values={['Ascending', 'Descending']}
            selectedIndex={sortOrder === 'asc' ? 0 : 1}
            onChange={(event) => {
              page = 1;
              const order = event.nativeEvent.selectedSegmentIndex === 0 ? 'asc' : 'desc';
              setSortOrder(order);
              setSortBy(`${sortType}.${order}`);
            }}
          />
          {sortType === 'release_date' && (
            <>
              <Text style={styles.title}>Release Year</Text>
              <View>
                <RangeSlider
                  style={styles.slider}
                  min={1900}
                  max={currentYear}
                  step={1}
                  floatingLabel
                  renderThumb={renderThumb}
                  renderRail={renderRail}
                  renderRailSelected={renderRailSelected}
                  renderLabel={renderLabel}
                  renderNotch={renderNotch}
                  onValueChanged={handleValueChange}
                />
                {/* TODO Checkbox for last 2 years? */}
              </View>
            </>
          )}
          {/* FIXME Ensure we can actually do this here - I'm not sure if we can using the /discover endpoint */}
          {/*{key.indexOf('/') !== -1 && (*/}
          {/*  <View>*/}
          {/*    <Text style={styles.title}>Genres</Text>*/}
          {/*    /!* TODO Map checkboxes for the genre names unless we're looking at a specific genre *!/*/}
          {/*  </View>*/}
          {/*)}*/}
          {sortType === 'vote_average' && (
            <>
              <Text style={styles.title}>Rating</Text>
              <Rating
                rating={rating || 0}
                onChange={(newValue) => {
                  page = 1;
                  // @ts-ignore
                  setRating(newValue);
                }}
                starSize={30}
                style={{ alignSelf: 'center' }}
              />
              <Text style={styles.title}>Rating Count</Text>
              <SegmentedControl
                values={['1k+', '2k+', '5k+', '10k+']}
                selectedIndex={ratingCountItems.indexOf(ratingCount)}
                onChange={(event) => {
                  page = 1;
                  const index = event.nativeEvent.selectedSegmentIndex;
                  setRatingCount(ratingCountItems[index]);
                }}
              />
            </>
          )}
        </View>
      </ScrollView>
    );
  }
  return (
    <View style={{ flex: 1} }>
      <MenuDrawer
        open={showSidemenu}
        position="right"
        drawerContent={renderContent()}
        drawerPercentage={85}
        animationTime={250}
        overlay={true}
        // opacity={0.4}
      >
        <Screen
          preset="scroll"
          backgroundColor={color.transparent}
          onScroll={({nativeEvent}) => {
            if (isCloseToBottom(nativeEvent)) {
              page += 1;
              loadPage();
            }
          }}
          scrollEventThrottle={400}
        >
          {/* TODO Filter by provider? */}
          {!loadingMovies && movieResults.length === 0 && (
            <Text
              style={{
                marginTop: 30,
                textAlign: 'center',
                color: darkMode ? 'white' : '#121212'
              }}
            >
              No results found
            </Text>
          )}
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              maxWidth: 1600,
              marginHorizontal: 'auto',
              marginVertical: 16,
            }}
          >
            {listStyle === 'grid' && movieResults.map((movie: MovieType) => (
              <View
                key={movie.id}
                style={{
                  flex: 1,
                  maxWidth: '50%',
                  flexBasis: isMobile ? ((Dimensions.get('window').width / 2) - 40) : 200,
                  margin: 6,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TouchableOpacity onPress={() => showDetail(movie)}>
                  <Image
                    source={{ uri: movie.posterImage ? movie.posterImage.replace('/w500/', '/w200/') : 'https://via.placeholder.com/94x141?text=No%20Image' }}
                    style={{
                      height: (isMobile ? ((Dimensions.get('window').width / 2) - 40) : 200) * 1.5,
                      width: isMobile ? ((Dimensions.get('window').width / 2) - 40) : 200,
                      borderRadius: 5,
                    }}
                  />
                </TouchableOpacity>
              </View>
            ))}
            {/* TODO If alphabetical, use https://github.com/CofferHub/react-native-section-alphabet */}
            {listStyle === 'list' && movieResults.map((movie: MovieType) => (
              <TouchableOpacity onPress={() => showDetail(movie)}>
                <Card
                  key={movie.id}
                  style={{
                    marginHorizontal: 16,
                    marginBottom: 10,
                    overflow: 'hidden',
                    height: 125,
                    width: Dimensions.get("window").width - 32,
                    backgroundColor: darkMode ? palette.offBlack : 'white',
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      marginBottom: 10,
                      paddingRight: 8,
                    }}
                  >
                    <Image source={{ uri: movie.posterImage ? movie.posterImage.replace('/w500/', '/w92/') : 'https://via.placeholder.com/94x141?text=No%20Image' }} style={{ height: 125, width: 85 }}/>
                    {/* TODO Add platform rating to title? */}
                    <View style={{ flex: 1, margin: 12 }}>
                      <Text style={{ fontSize: 14, fontWeight: '700', color: darkMode ? 'white' : '#121212' }}>
                        {movie.title}
                      </Text>
                      <Text style={{ fontSize: 12, marginTop: 8, marginBottom: 6, color: darkMode ? 'white' : '#121212' }}>{movie.year}</Text>
                      {renderDescription(movie)}
                    </View>
                    <ListItem.Chevron color={darkMode ? 'white' : '#121212'}/>
                  </View>
                </Card>
              </TouchableOpacity>
            ))}
          </View>
          {loadingMovies && (
            <View style={{ alignSelf: 'center', marginTop: 30 }}>
              <ActivityIndicator size={30} animating={true} color={palette.primary} />
            </View>
          )}
        </Screen>
      </MenuDrawer>
    </View>
  );
};
