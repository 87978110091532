import branch from 'branch-sdk';
import { v4 as uuidv4 } from 'uuid';
export default class ShareHandler {
  constructor(navigation) {
    if (navigation) {
      // FIXME What is this for the JS SDK?
      // branch.subscribe(bundle => {
      //   if (bundle && bundle.params && !bundle.error) {
      //     if (bundle.params.screen && bundle.params.id) {
      //       navigation.navigate(bundle.params.screen, bundle.params);
      //     }
      //   }
      // });
    }
  }
  createLink = ({ title, message, imageUrl, screen, params }) => new Promise((resolve, reject) => {
    const linkData = {
      // campaign: 'content 123',
      // feature: 'dashboard',
      // stage: 'new user',
      // alias: '',
      // tags: ['tag1', 'tag2', 'tag3'], // FIXME What are these used for? Marketing campaigns?
      // channel: service,
      data: {
        path: params.path,
        route: params.route,
        id: `${screen}_${params.id || uuidv4()}`,
        $og_title: title,
        $og_description: message,
        $og_image_url: imageUrl,
        // $og_video: data.video,
      },
    };
    console.debug(linkData);
    branch.link(linkData, (err, link) => {
      console.debug('Generating link');
      if (!err) {
        console.log('Created Deep Link:');
        console.log(link);
        resolve(link);
      } else {
        console.log(err);
        reject(err);
      }
    });
  });
  share = async ({ title, message, imageUrl, screen, params }) => {
    if (window.navigator.share) {
      console.debug('Using window.navigator.share');
      // FIXME This stopped showing the default share options on some views (like the profile)
      this.createLink({ title, message, imageUrl, screen, params }).then((link) => {
        console.log('Found navigator.share...');
        window.navigator.share({
          title: title,
          text: message,
          // url: link, // TODO Build this
        }).then(() => console.log('Successful share'))
          .catch((error) => console.error('Error sharing', error));
      });
    } else {
      // TODO
      console.log('Showing in-browser share options...');
      // FIXME Global sheet or is there a way to do this sort of thing again?
      // BottomSheet.show({
      //   id: 'share-items',
      //   items: shareItems,
      //   toggle: () => {
      //     BottomSheet.close({ id: 'share-items' });
      //   },
      // });
    }
  };
}
