import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";

export const config = {
  apiKey: 'AIzaSyBOX8qnQEPxSZyid0TdWAYJE4p2wfL2kW0',
  authDomain: 'in-reel-time.firebaseapp.com',
  projectId: 'in-reel-time',
  storageBucket: 'in-reel-time.appspot.com',
  messagingSenderId: '817224802947',
  appId: '1:817224802947:web:39db7c197428d19b1eb1b6',
  measurementId: 'G-8H8MGGXRZM',
  databaseURL: 'https://in-reel-time-default-rtdb.firebaseio.com/',
};

const app = firebase.initializeApp(config);

export const auth = firebase.auth();
export const database = firebase.database();

export default app;
