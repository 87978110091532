import React from 'react';
import {palette} from "../../theme/palette";
import {Box, CircularProgress, Typography} from "@mui/material";
import { circularProgressClasses } from '@mui/material/CircularProgress';
import {useColorScheme} from "react-native";

export const MovieRatingCircularProgress = ({ value }) => {
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  return (
    <Box
      className="rating-progress"
      sx={{ position: 'relative', display: 'inline-flex' }}
    >
      <CircularProgress
        variant="determinate"
        sx={{
          color: theme => theme.palette.grey[darkMode ? 800 : 400],
        }}
        size={50}
        thickness={4}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        sx={{
          color: palette.primary,
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' },
        }}
        size={50}
        thickness={4}
        value={value}
      />
      <Box
        sx={{
          top: 4,
          left: 4,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="caption" component="div" style={{ color: darkMode ? 'white' : '#121212' }}>
          {`${Math.round(value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};
