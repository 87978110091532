import React, {
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import {
  MovieType,
  useMovieContext,
} from '../contexts/MovieDetailContext';
import {Image, Text, TouchableOpacity, View, Dimensions, useColorScheme} from "react-native";
import { ListItem } from "react-native-elements";
import {ActivityIndicator, Card} from "react-native-paper";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {useQueryContext} from "../contexts/QueryContext";
import {color} from "../theme";
import {Screen} from "../components";
import {palette} from "../theme/palette";

const movieDbApiKey = process.env.TMDB_API_KEY;

const basePath = 'https://image.tmdb.org/t/p/w500/';

// @ts-ignore
export const MovieSearchScreen: React.FC<StackScreenProps<NavigatorParamList, "Search Movies">> = ({ navigation }) => {
  const { movieSearchQuery } = useQueryContext();
  const [movieResults, setMovieResults] = useState([]);
  const [tvResults, setTvResults] = useState([]);
  const [allResults, setAllResults] = useState([]);
  const [loadingMovies, setLoadingMovies] = useState(false);
  const { setSelectedMovie } = useMovieContext();
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  const showDetail = (movie: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(movie);
    navigation.navigate('Movie Detail', { movieId: movie.id, movie });
  };
  const showTvDetail = (show: MovieType) => {
    setSelectedMovie?.(show);
    // @ts-ignore
    navigation.navigate("TV Show Detail", { showId: show.id });
  };
  useEffect(() => {
    if (!loadingMovies && movieSearchQuery.length > 0) {
      setLoadingMovies(true);
      axios
        .get(
          `https://api.themoviedb.org/3/search/movie?api_key=${movieDbApiKey}&query=${movieSearchQuery}`
        )
        .then(res => {
          setLoadingMovies(false);
          // console.debug(`Got movies for query: ${searchText}`);
          // console.debug(res.data);
          const movies = res.data.results.map((data: any) => ({
            id: data.id,
            title: data.title,
            description: data.overview,
            posterImage:
              data.poster_path && `${basePath}${data.poster_path}`,
            posterBackdrop:
              data.backdrop_path &&
              `${basePath}${data.backdrop_path}`,
            year: data.release_date.substring(0, 4),
            rating: data.vote_average,
            ratingCount: data.vote_count,
          }));
          // console.debug(movies);
          setMovieResults(movies);
        })
        .catch(err => {
          console.error(err);
          setLoadingMovies(false);
          // setMovieResults([]);
        });

      axios
        .get(
          `https://api.themoviedb.org/3/search/tv?api_key=${movieDbApiKey}&query=${movieSearchQuery}`
        )
        .then(res => {
          setLoadingMovies(false);
          // console.debug(`Got movies for query: ${searchText}`);
          // console.debug(res.data);
          const tvShows = res.data.results.map((data: any) => ({
            id: data.id,
            type: 'tv',
            title: data.title || data.name,
            description: data.overview,
            posterImage:
              data.poster_path && `${basePath}${data.poster_path}`,
            posterBackdrop:
              data.backdrop_path &&
              `${basePath}${data.backdrop_path}`,
            year: (data.release_date && data.release_date.substring(0, 4)) || (data.first_air_date && data.first_air_date.substring(0, 4)),
            rating: data.vote_average,
            ratingCount: data.vote_count,
          }));
          // console.debug(movies);
          setTvResults(tvShows);
        })
        .catch(err => {
          console.error(err);
          setLoadingMovies(false);
          // setMovieResults([]);
        });
    }
  }, [movieSearchQuery]);

  useEffect(() => {
    // FIXME Is there a way to merge them better?
    setAllResults([...tvResults, ...movieResults]);
  }, [movieResults, tvResults])

  const renderDescription = (movie) => {
    let desc = 'No description available';
    if (movie.description && movie.description.length > 0) {
      if (movie.description.length >= 60) {
        desc = `${movie.description.substring(0, 60)}...`;
      } else {
        desc = movie.description;
      }
    }
    return <Text style={{ fontSize: 12, color: darkMode ? 'white' : '#121212' }}>{desc}</Text>
  }
  return (
    <View style={{ flex: 1} }>
      {/* TODO Add paging? */}
      <Screen preset="scroll" backgroundColor={color.transparent}>
        {/* TODO Filter by provider? */}
        {!loadingMovies && allResults.length === 0 && (
          <Text
            style={{
              marginTop: 30,
              textAlign: 'center',
              color: darkMode ? 'white' : '#121212'
            }}
          >
            No results found
          </Text>
        )}
        {loadingMovies && allResults.length === 0 && (
          <View style={{ alignSelf: 'center', marginTop: 30 }}>
            <ActivityIndicator size={30} animating={true} color={palette.primary} />
          </View>
        )}
        <View
          style={{
            width: '100%',
            margin: 16,
          }}
        >
          {/* FIXME This card should be its own component */}
          {allResults.map((movie: MovieType) => (
            <TouchableOpacity onPress={() => {
              if (movie.type === 'tv') {
                showTvDetail(movie);
              } else {
                showDetail(movie)
              }
            }}>
              <Card
                key={movie.id}
                style={{
                  marginBottom: 10,
                  overflow: 'hidden',
                  height: 125,
                  width: Dimensions.get("window").width - 32,
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginBottom: 10,
                    paddingRight: 8,
                  }}
                >
                  <Image source={{ uri: (movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')) || 'https://via.placeholder.com/94x141?text=No%20Image' }} style={{ height: 125, width: 85 }}/>
                  {/* TODO Add platform rating to title? */}
                  <View style={{ flex: 1, margin: 12 }}>
                    <Text style={{ fontSize: 14, fontWeight: '700', color: darkMode ? 'white' : '#121212' }}>
                      {movie.title}
                    </Text>
                    <Text style={{ fontSize: 12, marginTop: 8, marginBottom: 6, color: darkMode ? 'white' : '#121212' }}>{movie.year}</Text>
                    {renderDescription(movie)}
                  </View>
                  <ListItem.Chevron color={darkMode ? 'white' : '#121212'}/>
                </View>
              </Card>
            </TouchableOpacity>
          ))}
        </View>
      </Screen>
    </View>
  );
};
