/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import React, {useEffect, useState} from "react"
import {Dimensions, Image, Platform, TouchableOpacity, useColorScheme, View} from "react-native";
import {NavigationContainer, DefaultTheme, DarkTheme, Link, StackActions} from "@react-navigation/native"
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
// import * as Linking from 'expo-linking';
import {
  HomeScreen,
  MovieDetailScreen,
  LoginScreen,
  SignUpScreen,
  ProfileScreen,
  MovieSearchScreen,
  FriendListScreen,
  UserSearchScreen,
  SettingsScreen,
  GenreListScreen,
  TvShowDetailScreen,
  TrailerModal,
  ProviderListScreen,
  CastMemberScreen,
  TvListScreen,
  PasswordResetScreen,
  PrivacyPolicyScreen,
  TermsOfServiceScreen,
} from "../screens"
import {navigationRef, useBackButtonHandler} from "./navigation-utilities"
import {palette} from "../theme/palette";
import {createNativeStackNavigator} from "@react-navigation/native-stack";
import {Searchbar} from "react-native-paper";
import {useQueryContext} from "../contexts/QueryContext";
import ShareHandler from "../utils/share";
import {useShareContext} from "../contexts/ShareContext";
import User from "../models/user";
import {useAuth} from "../contexts/AuthContext";
import {useSettingsContext} from "../contexts/AppContext";
import {isMobile} from "../components/mobile-device-detect";
import {desktopHeader} from "../components/desktop-header";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 */
export type NavigatorParamList = {
  Home: undefined
  'User Profile': undefined
  'My Profile': undefined
  Friends: undefined
  Login: undefined
  Settings: undefined
  'Sign Up': undefined
  'Reset Password': undefined
  'Movie Detail': undefined
  'TV Show Detail': undefined
  'Search Movies': undefined
  'Search Users': undefined
  'Genre List': undefined
  'TV List': undefined
  Trailer: undefined
  Providers: undefined
  'Cast Member': undefined
  'Privacy Policy': undefined
  'Terms of Service': undefined
}

// FIXME The tabs hold the prefix in the path followed by the nested path so something like /users/me and tapping a movie
//  would lead to /users/me/detail/:movieId which is not what we want - figure out a better way to handle this - this is one
//  project I would definitely like on the web in addition to the native apps
const linking = {
  // prefixes: [ Linking.createURL('/'), 'https://moviesplustv.app' ],
  prefixes: [ 'https://moviesplustv.app' ],
  config: {
    screens: {
      Login: "login",
      'Sign Up': "register",
      'Reset Password': "accounts/recover",
      'Movie Detail': isMobile ? "detail/:movieId" : 'movies/detail/:movieId', // INFO Temp fix - see above
      'TV Show Detail': isMobile ? "detail/:showId" : 'tv/detail/:showId', // INFO Temp fix - see above
      'My Profile': isMobile ? 'me' : 'users/me',
      // NotFound: "404"
      Movies: "movies",
      Users: "users",
      'User Profile': ':userId',
      Home: "",
      // TODO May eventually want to allow users to see other users' friends - use "users/:userId/friends" for those
      Friends: "users/:userId/friends",
      Settings: "settings",
      'Search Movies': "movies/search",
      'Search Users': "search",
      'Genre List': isMobile ? 'list' : 'movies/list',
      'TV List': isMobile ? 'tv/list' : 'tv/list', // FIXME Get it to route without /movies/ prefix?
      'Cast Member': 'cast',
      'Privacy Policy': 'privacy',
      'Terms of Service': 'terms',
    }
  },
};

export const ActionBarImage = () => {
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === 'dark';
  if (isMobile) {
    return (
      <View style={{ flexDirection: 'row' }}>
        <Image
          source={require('../../assets/images/logo.png')}
          style={{
            width: 150,
            height: Platform.OS === 'ios' ? 44 : 50,
          }}
          resizeMode="contain"
        />
      </View>
    );
  }
  return (
    <View style={{ flexDirection: 'row' }}>
      <Link to="/">
        <Image
          source={require('../../assets/images/logo.png')}
          style={{
            width: 150,
            height: Platform.OS === 'ios' ? 44 : 50,
          }}
          resizeMode="contain"
        />
      </Link>
      <View style={{ flexDirection: 'row', marginLeft: 14 }}>
        <Link to="/" style={{ color: darkMode ? 'white' : '#121212', marginHorizontal: 8, marginVertical: 'auto' }}>Home</Link>
        <Link
          to="/movies/list?genre=Trending"
          action={StackActions.push('Genre List', { genre: { key: 'trending/movie/day', title: 'Trending Today' }})}
          style={{ color: darkMode ? 'white' : '#121212', marginHorizontal: 8, marginVertical: 'auto' }}
        >
          {'Trending'}
        </Link>
        <Link
          to="/movies/list?genre=Popular"
          action={StackActions.push('Genre List', { genre: { key: 'movie/popular', title: 'Popular' }})}
          style={{ color: darkMode ? 'white' : '#121212', marginHorizontal: 8, marginVertical: 'auto' }}
        >
          {'Popular'}
        </Link>
        <Link to="/users/me" style={{ color: darkMode ? 'white' : '#121212', marginHorizontal: 8, marginVertical: 'auto' }}>My Watchlist</Link>
        <Link to="/users/me/friends" style={{ color: darkMode ? 'white' : '#121212', marginHorizontal: 8, marginVertical: 'auto' }}>My Friends</Link>
      </View>
    </View>
  )
};

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<NavigatorParamList>();
const Tab = createBottomTabNavigator();

const styles = {
  searchBar: {
    width: Dimensions.get('window').width - 80,
    marginLeft: -10,
    borderRadius: 6,
  }
};
if (Platform.OS === 'ios') {
  styles.searchBar.height = 34;
}

const ProfileStackScreen = () => {
  const [movieSearch, setMovieSearch] = useState('');
  const { setMovieSearchQuery } = useQueryContext();
  return (
    <Stack.Screen name="My Profile" component={ProfileScreen} options={(navigation) => (!isMobile && desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))} />
  );
};

const MovieDetailStackScreen = () => {
  const [movieSearch, setMovieSearch] = useState('');
  const { setMovieSearchQuery } = useQueryContext();
  return (
    <Stack.Screen
      name="Movie Detail"
      component={MovieDetailScreen}
      options={(navigation) => (isMobile ? {
        headerTitle: () => <ActionBarImage/>,
      } : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
    />
  );
}

const ProvidersStackScreen = () => (
  <Stack.Screen
    options={{
      headerTitle: () => <ActionBarImage/>,
    }}
    name="Providers"
    component={ProviderListScreen}
  />
);

const MovieStackGroup = () => {
  const [movieSearch, setMovieSearch] = useState('');
  const { setMovieSearchQuery } = useQueryContext();
  return (
    <Stack.Group>
      <Stack.Screen
        name="Home"
        component={HomeScreen}
        options={({navigation}) => (isMobile ? {
          headerTitle: () => <ActionBarImage/>,
          headerRight: () => (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('Search Movies');
              }}
            >
              <Ionicons
                style={{padding: 12}}
                name={'search'}
                size={20}
                color={palette.primary}
              />
            </TouchableOpacity>
          )
        } : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
      />
      {/* FIXME It really should just route to the tab - this dupicates it, which is not ideal */}
      {/*{ProfileStackScreen()}*/}
      {MovieDetailStackScreen()}
      <Stack.Screen
        name="TV Show Detail"
        component={TvShowDetailScreen}
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
      />
      <Stack.Screen
        name="Cast Member"
        component={CastMemberScreen}
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
      />
      {/* FIXME This should work like modals and pop over the tab bar */}
      <Stack.Screen
        options={{
          headerTitle: (props) => (
            <Searchbar
              // darkMode={colorScheme === "dark"}
              // containerStyle={{backgroundColor: 'transparent', width: '100%', border: 'none'}}
              inputStyle={Platform.OS === 'ios' && { height: 34 }}
              placeholder="Search movies..."
              onChangeText={(text: string) => {
                setMovieSearch(text);
                if (text.length > 0) {
                  setMovieSearchQuery?.(text);
                }
              }}
              value={movieSearch}
              style={styles.searchBar}
            />
          )
        }}
        name="Search Movies"
        component={MovieSearchScreen}
      />
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="Genre List"
        component={GenreListScreen}
      />
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="TV List"
        component={TvListScreen}
      />
    </Stack.Group>
  )
}

// Used for web
const MainStackNavigator = () => {
  const [movieSearch, setMovieSearch] = useState('');
  const { setMovieSearchQuery } = useQueryContext();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerTintColor: palette.primary,
      }}
      initialRouteName="Home"
    >
      {MovieStackGroup()}
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name="Trailer"
          component={TrailerModal}
          options={{
            headerTitle: () => null,
            headerTintColor: 'white',
            headerStyle: {
              backgroundColor: 'black',
              elevation: 0, // remove shadow on Android
              shadowOpacity: 0, // remove shadow on iOS
              borderBottomColor: 'transparent',
            },
          }}
        />
      </Stack.Group>
      {ProvidersStackScreen()}
      <Stack.Screen
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
        name="Login"
        component={LoginScreen}
      />
      <Stack.Screen
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
        name="Sign Up"
        component={SignUpScreen}
      />
      <Stack.Screen
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
        name="Reset Password"
        component={PasswordResetScreen}
      />
      {ProfileStackScreen()}
      <Stack.Screen
        name="Friends"
        component={FriendListScreen}
        options={({navigation}) => ({
          headerTitle: () => <ActionBarImage/>,
          headerRight: () => (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('Search Users');
              }}
            >
              <Ionicons
                style={{padding: 12}}
                name={'add'}
                size={28}
                color={palette.primary}
              />
            </TouchableOpacity>
          ),
        })}
        initialParams={{
          userId: 'me'
        }}
      />
      <Stack.Screen
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
        name="Privacy Policy"
        component={PrivacyPolicyScreen}
      />
      <Stack.Screen
        options={(navigation) => (isMobile ? {} : desktopHeader(navigation, movieSearch, setMovieSearch, setMovieSearchQuery))}
        name="Terms of Service"
        component={TermsOfServiceScreen}
      />
    </Stack.Navigator>
  );
}

const MovieStackNavigator = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerTintColor: palette.primary,
      }}
      initialRouteName="Home"
    >
      {MovieStackGroup()}
      <Stack.Group screenOptions={{ presentation: 'modal' }}>
        <Stack.Screen
          name="Trailer"
          component={TrailerModal}
          options={{
            headerTitle: () => null,
            headerTintColor: 'white',
            headerStyle: {
              backgroundColor: 'black',
              elevation: 0, // remove shadow on Android
              shadowOpacity: 0, // remove shadow on iOS
              borderBottomColor: 'transparent',
            },
          }}
        />
      </Stack.Group>
      {ProvidersStackScreen()}
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="Login"
        component={LoginScreen}
      />
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="Sign Up"
        component={SignUpScreen}
      />
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="Reset Password"
        component={PasswordResetScreen}
      />
      {ProfileStackScreen()}
      <Stack.Screen
        name="Friends"
        component={FriendListScreen}
        options={({navigation}) => ({
          headerTitle: () => <ActionBarImage/>,
          headerRight: () => (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('Search Users');
              }}
            >
              <Ionicons
                style={{padding: 12}}
                name={'add'}
                size={28}
                color={palette.primary}
              />
            </TouchableOpacity>
          ),
        })}
        initialParams={{
          userId: 'me'
        }}
      />

    </Stack.Navigator>
  );
}

const ProfileStackNavigator = () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: true,
      headerTintColor: palette.primary,
    }}
    initialRouteName="My Profile"
  >
    {ProfileStackScreen()}
    {/*{MovieDetailStackScreen()}*/}
    {/* FIXME These 2 should work like modals and pop over the tab bar */}
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Login"
      component={LoginScreen}
    />
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Sign Up"
      component={SignUpScreen}
    />
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Reset Password"
      component={PasswordResetScreen}
    />
  </Stack.Navigator>
);

const UserStackNavigator = () => {
  const [userSearch, setUserSearch] = useState('');
  const { setUserSearchQuery } = useQueryContext();
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        headerTintColor: palette.primary,
      }}
      initialRouteName="Friends"
    >
      <Stack.Screen
        name="Friends"
        component={FriendListScreen}
        options={({navigation}) => ({
          headerTitle: () => <ActionBarImage/>,
          headerRight: () => (
            <TouchableOpacity
              onPress={() => {
                navigation.navigate('Search Users');
              }}
            >
              <Ionicons
                style={{padding: 12}}
                name={'add'}
                size={28}
                color={palette.primary}
              />
            </TouchableOpacity>
          ),
        })}
        initialParams={{
          userId: 'me'
        }}
      />
      <Stack.Screen
        name="User Profile"
        component={ProfileScreen}
        options={() => ({
          headerTitle: () => <ActionBarImage/>,
        })}
      />
      {/* FIXME This should work like modals and pop over the tab bar */}
      <Stack.Screen
        options={{
          headerTitle: (props) => (
            <Searchbar
              // darkMode={colorScheme === "dark"}
              // containerStyle={{backgroundColor: 'transparent', width: '100%', border: 'none'}}
              inputStyle={Platform.OS === 'ios' && { height: 34 }}
              placeholder="Search users..."
              onChangeText={(text: string) => {
                setUserSearch(text);
                if (text.length > 0) {
                  setUserSearchQuery?.(text);
                }
              }}
              value={userSearch}
              style={styles.searchBar}
            />
          )
        }}
        name="Search Users"
        component={UserSearchScreen}
      />
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="Privacy Policy"
        component={PrivacyPolicyScreen}
      />
      <Stack.Screen
        options={{
          headerTitle: () => <ActionBarImage/>,
        }}
        name="Terms of Service"
        component={TermsOfServiceScreen}
      />
    </Stack.Navigator>
  );
}

const SettingsStackNavigator = () => (
  <Stack.Navigator
    screenOptions={{
      headerShown: true,
      headerTintColor: palette.primary,
    }}
    initialRouteName="Home"
  >
    <Stack.Screen
      options={({navigation}) => ({
        headerTitle: () => <ActionBarImage/>,
      })}
      name="Settings"
      component={SettingsScreen}
    />
    {ProfileStackScreen()}
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Login"
      component={LoginScreen}
    />
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Sign Up"
      component={SignUpScreen}
    />
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Reset Password"
      component={PasswordResetScreen}
    />
    {/* FIXME Better place for these? */}
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Privacy Policy"
      component={PrivacyPolicyScreen}
    />
    <Stack.Screen
      options={{
        headerTitle: () => <ActionBarImage/>,
      }}
      name="Terms of Service"
      component={TermsOfServiceScreen}
    />
    {ProvidersStackScreen()}
  </Stack.Navigator>
);

const TabStackNavigator = () => {
  return (
    <View style={{flex: 1}} collapsable={false}>
      <Tab.Navigator
        screenOptions={({route}) => ({
          headerShown: false,
          tabBarIcon: ({focused, color, size}) => {
            let iconName;

            if (route.name === 'Movies') {
              iconName = focused
                ? 'film'
                : 'film-outline';
            } else if (route.name === 'Users') {
              iconName = focused ? 'bookmark' : 'bookmark-outline';
            } else if (route.name === 'Friends') {
              iconName = focused ? 'people' : 'people-outline';
            } else if (route.name === 'Settings') {
              iconName = focused ? 'ellipsis-horizontal' : 'ellipsis-horizontal-outline';
            }

            // FIXME These aren't showing on Android
            return <Ionicons name={iconName} size={size} color={color}/>;
          },
          tabBarLabel: () => {
            return null
          },
          tabBarActiveTintColor: palette.primary,
          tabBarInactiveTintColor: 'gray',
        })}
      >
        <Tab.Screen name="Movies" component={MovieStackNavigator}/>
        <Tab.Screen name="Users" component={ProfileStackNavigator}/>
        <Tab.Screen name="Friends" component={UserStackNavigator}/>
        <Tab.Screen name="Settings" component={SettingsStackNavigator}/>
      </Tab.Navigator>
    </View>
  )
}

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

export const AppNavigator = (props: NavigationProps) => {
  const {currentUser} = useAuth();
  const {setAllProviders} = useSettingsContext();
  const colorScheme = useColorScheme();
  const { setShareHandler } = useShareContext();
  useBackButtonHandler(canExit);
  // TODO If !getStarted on web, show the single page intro/overview like JustWatch. Once user shows intent to get started,
  //  always show the app home screen after
  useEffect(() => {
    (async() => {
      if (currentUser && currentUser.uid) {
        await User.getProviders(currentUser.uid).then((p) => {
          setAllProviders?.(p);
        });
      }
    })();
  }, [currentUser]);
  useEffect(() => {
    setShareHandler?.(new ShareHandler(navigationRef));
  }, [navigationRef]);
  return (
    <NavigationContainer
      ref={navigationRef}
      // @ts-ignore
      linking={linking}
      theme={colorScheme === "dark" ? DarkTheme : DefaultTheme}
      {...props}
    >
      {isMobile ? <TabStackNavigator /> : <MainStackNavigator />}
    </NavigationContainer>
  )
}

AppNavigator.displayName = "AppNavigator"

/**
 * A list of routes from which we're allowed to leave the app when
 * the user presses the back button on Android.
 *
 * Anything not on this list will be a standard `back` action in
 * react-navigation.
 *
 * `canExit` is used in ./app/app.tsx in the `useBackButtonHandler` hook.
 */
const exitRoutes = ["Home"]
export const canExit = (routeName: string) => exitRoutes.includes(routeName)
