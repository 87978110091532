import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {StackScreenProps} from "@react-navigation/stack";
import {Image, Switch, Text, TouchableOpacity, useColorScheme, View, ViewStyle} from "react-native";
import {ListItem} from "react-native-elements";
import {Screen} from "../components";
import {NavigatorParamList} from "../navigators";
import {color, spacing} from "../theme";
import {useAuth} from "../contexts/AuthContext";
import {palette} from "../theme/palette";
import packageJson from '../../package.json';
import {useSettingsContext} from "../contexts/AppContext";

// TODO Add a toggle to show adult films - default to off and have them confirm their age to enable?
const flexStyle: ViewStyle = { flex: 1 };
export const SettingsScreen: React.FC<StackScreenProps<NavigatorParamList, "Settings">> = observer(({navigation}) => {
  const { currentUser, logout } = useAuth();
  const {settings} = useSettingsContext();
  const {allProviders : providers} = settings;
  const [allowPushNotifications, setAllowPushNotifications] = useState(true);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  const styles = {
    content: { flex: 0, width: 110 },
    input: { flex: 1, color: darkMode ? 'white' : '#121212' },
    title: { color: darkMode ? 'white' : '#121212' },
    subtitle: { color: darkMode ? '#dfdfdf' : '#565656', fontSize: 12, marginTop: 5 },
    buildText: { color: darkMode ? '#dfdfdf' : '#565656', fontSize: 10, marginTop: 12, textAlign: 'center' }
  };
  useEffect(() => {
    if (currentUser) {
      if (currentUser.firstName) {
        setFirstName(currentUser.firstName);
      } else if (currentUser.fullName) {
        setFirstName(currentUser.fullName.split(' ')[0]);
      } else {
        setFirstName(currentUser.displayName.split(' ')[0]);
      }
      if (currentUser.lastName) {
        setLastName(currentUser.lastName);
      } else if (currentUser.fullName) {
        setFirstName(currentUser.fullName.split(' ')[0]);
      } else {
        setLastName(currentUser.displayName.split(' ')[1]);
      }
      setEmail(currentUser.email);
    }
  })
  // const renderLoggedInForm = () => (
  //
  // );
  // const renderLoggedOutForm = () => (
  //
  // );
  return (
    <View style={flexStyle}>
      <Screen preset="scroll" backgroundColor={color.transparent}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <Text
            style={{
              flex: 1,
              fontSize: 20,
              margin: 16,
              marginTop: 24,
              color: darkMode ? 'white' : '#121212',
            }}
          >
            Settings
          </Text>
          <View style={{ margin: 16, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Text style={{ fontSize: 10, color: darkMode ? 'white' : '#121212', }}>Movie data provided by</Text>
            <Image
              source={require('../../assets/images/tmdb-logo.png')}
              style={{
                marginLeft: 4,
                height: 10,
                width: 76,
              }}
            />
          </View>
        </View>
        {/* TODO Upgrade banner and/or promotional ad here for non-premium users */}
        {currentUser && (
          <View>
            <View>
              {/* TODO Allow them to edit their avatar here */}
              {/* TODO Make these re-usable - render function above */}
              <ListItem
                bottomDivider
                containerStyle={{
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
              >
                <ListItem.Content style={styles.content}>
                  <ListItem.Title style={styles.title}>First Name</ListItem.Title>
                </ListItem.Content>
                <ListItem.Input
                  placeholder="Your first name"
                  value={firstName}
                  style={styles.input}
                  onChangeText={(text) => {
                    setFirstName(text);
                    // TODO Update in Realtime DB
                  }}
                />
                <ListItem.Chevron />
              </ListItem>
              <ListItem
                bottomDivider
                containerStyle={{
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
              >
                <ListItem.Content style={styles.content}>
                  <ListItem.Title style={styles.title}>Last Name</ListItem.Title>
                </ListItem.Content>
                <ListItem.Input
                  placeholder="Your last name"
                  value={lastName}
                  style={styles.input}
                  onChangeText={(text) => {
                    setLastName(text);
                    // TODO Update in Realtime DB
                  }}
                />
                <ListItem.Chevron />
              </ListItem>
              <ListItem
                containerStyle={{
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
              >
                <ListItem.Content style={styles.content}>
                  <ListItem.Title style={styles.title}>Email</ListItem.Title>
                </ListItem.Content>
                <ListItem.Input
                  placeholder="Type your email"
                  value={email}
                  style={styles.input}
                  // FIXME Allow them to change their email this easily? Probably not...
                />
                <ListItem.Chevron />
              </ListItem>
            </View>
            <View style={{ marginTop: 16 }}>
              <ListItem
                containerStyle={{
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
                onPress={() => {
                  navigation.navigate('Providers');
                }}
              >
                <ListItem.Content>
                  <ListItem.Title style={styles.title}>Streaming Providers</ListItem.Title>
                  {!Object.keys(providers).length && <ListItem.Subtitle style={styles.subtitle}>Add your streaming services here</ListItem.Subtitle>}
                  {Object.keys(providers).length > 0 && <ListItem.Subtitle style={styles.subtitle}>{Object.keys(providers).map((key) => (providers[key])).join(', ')}</ListItem.Subtitle>}
                </ListItem.Content>
                <ListItem.Chevron />
              </ListItem>
            </View>
            <View style={{ marginTop: 16 }}>
              <ListItem
                containerStyle={{
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
              >
                <ListItem.Content>
                  <ListItem.Title style={styles.title}>Push Notifications</ListItem.Title>
                </ListItem.Content>
                <Switch
                  value={allowPushNotifications}
                  onValueChange={(value) => setAllowPushNotifications(value)}
                />
              </ListItem>
            </View>
            <View>
              <TouchableOpacity onPress={async () => {
                await logout().then(() => {
                  navigation.navigate('Login');
                })
              }}>
                <Text
                  style={{
                    fontSize: 18,
                    color: color.tertiary,
                    marginTop: 20,
                    textAlign: 'center',
                  }}
                >
                  {`Not ${currentUser.firstName || (currentUser.fullName && currentUser.fullName.split(' ')[0]) || currentUser.displayName.split(' ')[0]}? Sign Out`}
                </Text>
              </TouchableOpacity>
              <Text style={styles.buildText}>Version: v{packageJson.version}</Text>
            </View>
          </View>
        )}
        {!currentUser && (
          <View>
            <View style={{ marginLeft: 16, marginRight: 16 }}>
              <Text style={{...styles.title, textAlign: 'center' }}>Ready to see what movie matches you have in common with your friends?</Text>
              <TouchableOpacity
                style={{
                  paddingVertical: spacing[2],
                  paddingHorizontal: spacing[2],
                  borderRadius: 4,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 50,
                  backgroundColor: palette.secondary,
                  marginTop: 30,
                }}
                onPress={() => navigation.navigate('Login')}
              >
                <Text style={{color: 'white'}}>Log In Now</Text>
              </TouchableOpacity>
            </View>
            {/* FIXME Should it allow this when they're not logged in? Prob not... */}
            {/*<View style={{ marginTop: 16 }}>*/}
            {/*  <ListItem*/}
            {/*    containerStyle={{*/}
            {/*      backgroundColor: darkMode ? palette.offBlack : 'white',*/}
            {/*    }}*/}
            {/*    onPress={() => {*/}
            {/*      alert('Coming soon');*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <ListItem.Content>*/}
            {/*      <ListItem.Title style={styles.title}>Streaming Providers</ListItem.Title>*/}
            {/*      <ListItem.Subtitle style={styles.subtitle}>{Object.keys(providers).map((key) => (providers[key])).join(', ')}</ListItem.Subtitle>*/}
            {/*    </ListItem.Content>*/}
            {/*    <ListItem.Chevron />*/}
            {/*  </ListItem>*/}
            {/*</View>*/}
            {/* TODO What else to show? */}
            <Text style={{ fontSize: 20, marginLeft: 16, marginRight: 16, marginTop: 30, color: darkMode ? 'white' : '#121212' }}>Notifications</Text>
            <View style={{ marginTop: 12 }}>
              {/* FIXME re-use this */}
              <ListItem
                containerStyle={{
                  backgroundColor: darkMode ? palette.offBlack : 'white',
                }}
              >
                <ListItem.Content>
                  <ListItem.Title style={styles.title}>Push Notifications</ListItem.Title>
                </ListItem.Content>
                <Switch
                  value={allowPushNotifications}
                  onValueChange={(value) => setAllowPushNotifications(value)}
                />
              </ListItem>
            </View>
            <Text style={styles.buildText}>Version: v{packageJson.version}</Text>
          </View>
        )}
      </Screen>
    </View>
  );
});
