import React from "react"
import { View } from "react-native";
import GooglePublisherTag from './google-publisher-tag';

// https://stackoverflow.com/questions/46035999/how-to-embed-google-adsense-in-react-js
export const BannerAd = ({ adUnitId = '/22758720814/MOVIES.PLUS.TV.MEDIUM.RECTANGLE', adUnitSize = '[[300, 250]]' }) => {
  const adDimensions = adUnitSize.replace('[[', '').replace(']]', '').replace('[', '').replace('[', '').split(', ');
  return (
    <View style={{ justifyContent: 'center', alignItems: 'center' }} >
      <GooglePublisherTag
        height={parseInt(adDimensions[1])}
        width={parseInt(adDimensions[0])}
        adUnitId={adUnitId}
        adUnitSize={adUnitSize}
      />
    </View>
  );
}
