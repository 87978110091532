import React, {useEffect, useState} from 'react';
import {
  UserType,
  useUserContext,
} from '../contexts/UserDetailContext';
import {useAuth} from '../contexts/AuthContext';
import User from '../models/user';
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {StyleSheet, Text, View, useColorScheme, Image, Dimensions} from "react-native";
import {Screen} from "../components";
import {color} from "../theme";
import { ListItem } from "react-native-elements";
import {Avatar} from "react-native-paper";
import { alert } from '../utils/alert-helper'
import {palette} from "../theme/palette";
import IonIcons from "react-native-vector-icons/Ionicons";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
export const FriendListScreen: React.FC<StackScreenProps<NavigatorParamList, "Friends">> = ({navigation}) => {
  const {currentUser} = useAuth();
  const {setSelectedUser} = useUserContext();
  const [loading, setLoading] = useState(false);
  const [friends, setFriends] = useState<UserType[]>([]);
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      setLoading(true);
      User.getFollowedUsers(currentUser.uid).then(followList => {
        // TODO Cache them locally
        setFriends(followList);
        setLoading(false);
      }).catch((err) => {
        console.error(err);
        alert('Database error', err.message);
      });
    }
  }, [currentUser]);
  const showUserDetail = (user: UserType) => {
    setSelectedUser?.(user);
    navigation.navigate('User Profile', {userId: user.id, user});
  };
  const renderAvatar = (user) => {
    if (user) {
      console.log('Building avatar for user:');
      console.log(user);
      if (user.profileImageUrl) {
        return (
          <Avatar.Image
            size={50}
            source={{uri: user.profileImageUrl || 'https://i.pravatar.cc/80'}}
          />
        )
      }
      return <Avatar.Text size={50} label={(user.firstName && user.firstName.charAt(0)) || (user.fullName && user.fullName.charAt(0))}/>;
    }
    return <Avatar.Text size={50} label="A"/>;
  };
  return (
    <View style={{ flex: 1 }}>
      <Screen preset="scroll" backgroundColor={color.transparent}>
        {/*{loading && friends.length === 0 && (*/}
        {/*  <View style={{textAlign: 'center'}}>*/}
        {/*    <CircularProgress color="inherit" size={30} />*/}
        {/*  </View>*/}
        {/*)}*/}
        {friends.length === 0 && !loading && (
          <>
            <Image
              source={require('../../assets/images/friend-list.png')}
              style={{
                marginTop: 30,
                height: Dimensions.get('window').width * (2 / 3),
                width: Dimensions.get('window').width,
                aspectRatio: 3 / 2,
                resizeMode: 'contain',
              }}
            />
            <Text
              style={{
                marginTop: 10,
                textAlign: 'center',
                backgroundColor: 'transparent',
                color: darkMode ? 'white' : '#121212',
              }}
            >
              Use the <IonIcons name="add" size={20} style={{ color: darkMode ? 'white' : '#121212' }}/> icon above to find friends to follow
            </Text>
          </>
        )}
        <View style={{ marginTop: 16 }}>
          {friends.map((friend, index) => (
            <ListItem
              bottomDivider={index !== friends.length - 1}
              containerStyle={{
                backgroundColor: darkMode ? palette.offBlack : 'white',
              }}
              onPress={() => showUserDetail(friend)}
              // style={{ height: 70 }}
            >
              {renderAvatar(friend)}
              <ListItem.Content>
                <ListItem.Title
                  style={{ fontWeight: 'bold', color: darkMode ? 'white' : '#121212' }}
                >
                  {friend.fullName}
                </ListItem.Title>
              </ListItem.Content>
              <ListItem.Chevron color={darkMode ? 'white' : '#121212'}/>
            </ListItem>
          ))}
        </View>
      </Screen>
    </View>
  );
};
