import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import {isMobile} from '../../components/mobile-device-detect';
import IonIcons from 'react-native-vector-icons/Ionicons';
import {MovieType, useMovieContext} from '../../contexts/MovieDetailContext';
import {AdMobBanner} from 'expo-ads-admob';
import User from '../../models/user';
import {useAuth} from '../../contexts/AuthContext';
import {baseOriginalPath, basePath, smallPath} from "../../models/movie";
import {
  View,
  Text,
  TouchableOpacity,
  Image,
  ScrollView,
  ImageBackground,
  StyleSheet,
  Platform,
  Linking,
  useColorScheme, Dimensions,
} from "react-native";
import {StackScreenProps} from "@react-navigation/stack";
import { NavigatorParamList } from "../../navigators";
import {observer} from "mobx-react-lite";
import Rating from 'react-native-star-rating-widget';
import {color} from "../../theme";
import {Screen} from "../../components";
import {useTheme} from "@react-navigation/native";
import {LinearGradient} from "expo-linear-gradient";
import SkeletonView from "./skeleton-detail";
import SkeletonList from "./skeleton-scroll-list";
// import {TrailerPlayer} from "../../components/trailer-player/index.web";
import {Toast} from "../../components/toast";
import {MovieRatingCircularProgress} from "../../components/movie-rating-circular-progress";
import {WatchedWith} from "../../components/watched-with";
import {WatchedDate} from "../../components/watched-date";
import {palette} from "../../theme/palette";
import Ionicons from "react-native-vector-icons/Ionicons";
import {useShareContext} from "../../contexts/ShareContext";
import ShareHandler from "../../utils/share";
// import {AmazonPrimeBanner} from "../../components/amazon-prime-banner";
import {BannerAd} from "../../components/google-ad-manager";

const movieDbApiKey = process.env.TMDB_API_KEY;
const streamingAvailabilityApiKey = process.env.STREAMING_AVAILABILITY_API_KEY;

export const providerLogos = {
  amazon_prime_video: `${smallPath}/68MNrwlkpF7WnmNPXLah69CR5cb.jpg`,
  prime: `${smallPath}/68MNrwlkpF7WnmNPXLah69CR5cb.jpg`,
  netflix: `${smallPath}/9A1JSVmSxsyaBK4SUFsYVqbAYfW.jpg`,
  hulu: `${smallPath}/giwM8XX4V2AQb9vsoN7yti82tKK.jpg`,
  hbo: `${smallPath}/vAtH6Z6Oq7zCmEGS3Sdu08dxvYZ.jpg`,
  hbo_max: `${smallPath}/rrta9psrx3e7F9wLUfpANdJzudx.jpg`,
  hbo_now: `${smallPath}/3LQzaSBH1kjQB9oKc4n72dKj8oY.jpg`,
  max_go: `${smallPath}/AhaVozbDe3SPHXTKyd6Crdt720S.jpg`,
  disney: `${smallPath}/dgPueyEdOwpQ10fjuhL2WYFQwQs.jpg`,
  disney_plus: `${smallPath}/dgPueyEdOwpQ10fjuhL2WYFQwQs.jpg`,
  starz: `${smallPath}/pDcaPupcS7YxKce2sQePrq8k1gK.jpg`,
  showtime: `${smallPath}/bo7FM7ha4iEnOEAvhhwg0RuVGid.jpg`,
  imdb: `${smallPath}/nd4NLxYeSv2TQ3HFzsecbAuSq1C.jpg`,
  paramount: `${smallPath}/pkAHkRhIq3Iu0ZlEhDzbguSlyZF.jpg`,
  paramount_plus: `${smallPath}"/xbhHHa1YgtpwhC8lb1NQ3ACVcLd.jpg`,
  peacock: `${smallPath}/d9cPwjnMYUEdjsfPuX96akc807z.jpg`,
  amc: `${smallPath}/9edKQczyuMmQM1yS520hgmJbcaC.jpg`,
  vudu: `${smallPath}/21dEscfO8n1tL35k4DANixhffsR.jpg`,
  apple_itunes: `${smallPath}/peURlLlr8jggOwK53fJ5wdQl05y.jpg`,
  fubotv: `${smallPath}/jPXksae158ukMLFhhlNvzsvaEyt.jpg`,
  youtube: `${smallPath}/oIkQkEkwfmcG7IGpRR1NB8frZZM.jpg`,
  pluto_tv: `${smallPath}/t6N57S17sdXRXmZDAkaGP0NHNG0.jpg`,
  cbs: `${smallPath}/2BPU00vSfCZ4XI2CnQCBv8rZk2f.jpg`,
  abc: `${smallPath}/l9BRdAgQ3MkooOalsuu3yFQv2XP.jpg`,
  tbs: `${smallPath}/rcebVnRvZvPXauK4353Jgiu4DWI.jpg`,
  directv: `${smallPath}/xL9SUR63qrEjFZAhtsipskeAMR7.jpg`,
  // TODO Rest of these
};
const adUnitID = Platform.select({
  ios: "ca-app-pub-9688782921518602/8981823168",
  android: "ca-app-pub-9688782921518602/9093503804",
});
export const MovieDetailScreen: React.FC<StackScreenProps<NavigatorParamList, "Movie Detail">> = observer(({route, navigation}) => {
  const {currentUser} = useAuth();
  const {selectedMovie, setSelectedMovie} = useMovieContext();
  const [cast, setCast] = useState([]);
  const [hasSeen, setHasSeen] = useState('');
  const [bookmarked, setBookmarked] = useState(false);
  const [showVideoTrailer, setShowVideoTrailer] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [loadingCast, setLoadingCast] = useState(true);
  const [loadingRecommended, setLoadingRecommended] = useState(false);
  const [loadingSimilar, setLoadingSimilar] = useState(true);
  const [recommendedMovies, setRecommendedMovies] = useState<MovieType[]>([]);
  const [similarMovies, setSimilarMovies] = useState<MovieType[]>([]);
  const [rating, setRating] = useState(0);
  const [streamingProviders, setStreamingProviders] = useState([]);

  const showDetail = (movie: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(movie);
    // FIXME Replace? If not, the back button needs to reset the selected movie
    // navigation.navigate("Movie Detail", movie.id);
    // @ts-ignore
    navigation.setParams({movieId: movie.id});
  };
  const showPersonCredits = (castMemberId) => {
    navigation.navigate("Cast Member", { personId: castMemberId });
  }
  const {colors} = useTheme();
  const colorScheme = useColorScheme();
  const { shareHandler, setShareHandler } = useShareContext();
  const darkMode = colorScheme === "dark";
  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    innerContainer: {
      backgroundColor: darkMode ? '#121212' : '#efefef'
    },
    image: {
      flex: 1,
      justifyContent: "center"
    },
    title: {
      color: colors.text,
      fontSize: 20,
      marginTop: 16,
      marginBottom: 10,
      fontWeight: '500',
    },
    description: {
      fontSize: 14,
      color: colors.text,
    },
    headerBackground: {
      height: isMobile ? 200 : 520,
      width: '100%',
    },
    backgroundVideo: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      zIndex: 1,
      height: 0,
    },
  });
  const scrollRef = useRef();
  useEffect(() => {
    if (!shareHandler) {
      setShareHandler?.(new ShareHandler(navigation));
    }

  }, []);

  useEffect(() => {
    if (isMobile) {
      navigation.setOptions({
        headerRight: () => (
          <TouchableOpacity
            onPress={async () => {
              if (shareHandler) {
                const title = currentUser && currentUser.firstName ? `${currentUser.firstName} shared ${selectedMovie.title} with the Movies + TV app` : `${selectedMovie.title} is on the Movies + TV app`;
                await shareHandler.share({
                  title,
                  message: `Let's watch ${selectedMovie.title}. Check it out on the Movies + TV app.`,
                  imageUrl: selectedMovie.posterImage || selectedMovie.posterBackdrop,
                  screen: 'Movie Detail',
                  params: { id: selectedMovie.id, movieId: selectedMovie.id, route: 'Movie Detail', path: `movies/detail/${selectedMovie.id}` },
                });
              } else {
                // TODO Error? This should really never happen
                alert('Unable to share...');
              }
            }}
          >
            <Ionicons
              style={{padding: 12}}
              name={Platform.OS === 'ios' ? 'share-outline' : 'share-social'}
              size={20}
              color={palette.primary}
            />
          </TouchableOpacity>
        ),
      });
    }
  }, [shareHandler]);

  useEffect(() => {
    // Scroll to the top
    scrollRef && scrollRef.current && scrollRef.current.scrollTo(0);
    // @ts-ignore
    let {movieId} = route.params;
    if (selectedMovie.id || movieId) {
      if (selectedMovie.id) {
        movieId = selectedMovie.id;
      }
      // console.log('Getting details for movie: ' + movieId);
      if (currentUser && currentUser.uid) {
        User.isOnWatchlist(currentUser.uid, `${movieId}`).then(
          isOnList => {
            setBookmarked(isOnList);
          }
        );
        User.getSeenStatus(currentUser.uid, `${movieId}`).then(
          seenStatus => {
            setHasSeen(seenStatus);
          }
        );
        User.getRating(currentUser.uid, `${movieId}`).then(r => {
          if (r) {
            setRating(r);
          }
        });
      }

      axios
        .get(
          `https://api.themoviedb.org/3/movie/${movieId}/credits?api_key=${movieDbApiKey}&append_to_response=videos`
        )
        .then(res => {
          const {data} = res;
          setCast(
            data.cast.map((member: any) => ({
              // TODO https://developers.themoviedb.org/3/discover/movie-discover use "with_cast" with single ID to find all their movies
              id: member.id,
              name: member.name,
              character: member.character,
              image:
                member.profile_path &&
                `${basePath}${member.profile_path}`,
            }))
          );
          setLoadingCast(false);
        }).catch(() => {
          setLoadingCast(false);
        });

      // FIXME These don't look right - they prob need the user to be signed in to TMDB and adding reviews there
      // axios
      //   .get(
      //     `https://api.themoviedb.org/3/movie/${movieId}/recommendations?api_key=${movieDbApiKey}`
      //   )
      //   .then(res => {
      //     const { data } = res;
      //     setRecommendedMovies(
      //       data.results
      //         .filter((movie: any) => {
      //           return !!movie.backdrop_path;
      //         })
      //         .map((movie: any) => ({
      //           id: movie.id,
      //           title: movie.title,
      //           description: movie.overview,
      //           posterImage:
      //             movie.poster_path &&
      //             `${basePath}${movie.poster_path}`,
      //           posterBackdrop:
      //             movie.backdrop_path &&
      //             `${baseOriginalPath}${movie.backdrop_path}`,
      //           year: movie.release_date.substring(0, 4),
      //           rating: movie.vote_average,
      //           ratingCount: movie.vote_count,
      //         }))
      //     );
      //   });

      axios
        .get(
          `https://api.themoviedb.org/3/movie/${movieId}/similar?api_key=${movieDbApiKey}`
        )
        .then(res => {
          const {data} = res;
          setSimilarMovies(
            data.results
              .filter((movie: any) => {
                return !!movie.backdrop_path;
              })
              .map((movie: any) => ({
                id: movie.id,
                title: movie.title,
                description: movie.overview,
                posterImage:
                  movie.poster_path &&
                  `${basePath}${movie.poster_path}`,
                posterBackdrop:
                  movie.backdrop_path &&
                  `${baseOriginalPath}${movie.backdrop_path}`,
                year: movie.release_date.substring(0, 4),
                rating: movie.vote_average,
                ratingCount: movie.vote_count,
              }))
          );
          setLoadingSimilar(false);
        }).catch(() => {
          setLoadingSimilar(false);
        });

      // FIXME Don't run this in dev (try to detect whether hot reloads are enabled)
      axios
        .request({
          method: 'GET',
          url:
            'https://streaming-availability.p.rapidapi.com/get/basic',
          params: {
            country: 'us',
            tmdb_id: `movie/${movieId}`,
            output_language: 'en',
          },
          headers: {
            'x-rapidapi-host':
              'streaming-availability.p.rapidapi.com',
            'x-rapidapi-key': streamingAvailabilityApiKey,
          },
        })
        .then(function (response) {
          console.log('Streaming providers:');
          console.log(response.data);
          console.log(response.data.streamingInfo);
          const services = Object.keys(response.data.streamingInfo);
          const providers = services.map(name => ({
            name,
            // @ts-ignore
            logo: providerLogos[name],
            link: response.data.streamingInfo[name].us.link,
          }));
          // @ts-ignore
          setStreamingProviders(providers);
        })
        .catch(function (error) {
          console.error('Streaming availability API error:');
          console.error(error);
        });
    }
    // @ts-ignore
  }, [selectedMovie.id, route.params.movieId]);

  useEffect(() => {
    // @ts-ignore
    let {movieId} = route.params;
    if (selectedMovie.id || movieId) {
      if (selectedMovie.id) {
        console.log('Using selectedMovie.id')
        movieId = selectedMovie.id;
      } else {
        setLoadingDetail(true);
      }
      axios
        .get(
          `https://api.themoviedb.org/3/movie/${movieId}?api_key=${movieDbApiKey}&append_to_response=videos`
        )
        .then(res => {
          const {data} = res;
          console.log('Got data for movie:');
          console.log(data);
          const {videos} = data;
          const {results} = videos;
          if (results) {
            results.forEach(async (result: any) => {
              if (result.type === 'Trailer') {
                if (result.site === 'YouTube') {
                  setSelectedMovie?.({
                    id: data.id,
                    title: data.title,
                    description: data.overview,
                    posterImage:
                      data.poster_path && `${basePath}${data.poster_path}`,
                    posterBackdrop:
                      data.backdrop_path &&
                      `${baseOriginalPath}${data.backdrop_path}`,
                    year: data.release_date.substring(0, 4),
                    rating: data.vote_average,
                    ratingCount: data.vote_count,
                    videoPreview: `https://youtu.be/${result.key}`,
                    youtubeId: result.key,
                  });
                }
                // TODO Other providers?
              }
            });
            setLoadingDetail(false);
          }
        }).catch((err) => {
          if (selectedMovie.id) {
            setLoadingDetail(false);
          }
          // TODO Show an error screen instead? There was a problem getting the movie details
          alert(err);
        });
    }
  }, []);
  const showToast = (message: string) => {
    Toast.show(message);
  }
  // FIXME Require the user is logged in for this
  const toggleBookmarked = () => {
    if (currentUser && currentUser.uid) {
      let message = `Added "${selectedMovie.title}" to Watch List`;
      if (bookmarked) {
        message = `Removed "${selectedMovie.title}" from Watch List`;
        User.removeFromWatchlist(currentUser.uid, `${selectedMovie.id}`)
          .then(() => {
            console.log('Removed successfully');
          })
          .catch(err => {
            console.error(err);
          });
      } else {
        User.addToWatchlist(currentUser.uid, selectedMovie)
          .then(() => {
            console.log('Added successfully');
          })
          .catch(err => {
            console.error(err);
          });
      }
      showToast(message);
      setBookmarked(!bookmarked);
    } else {
      navigation.navigate('Sign Up');
    }
  };
  const shouldShowAds = () => {
    return true;
    // FIXME Only if user is logged out or they have a premium account
    return (
      currentUser &&
        // FIXME Set a "premium" flag on the user object to indicate they get premium features and no ads
      currentUser.email !== 'paigemelissa0@gmail.com'
      // && currentUser.email !== 'trevordboyer@gmail.com'
      // && currentUser.email !== 'devs@elite-dev.com' // TODO Remove this to test
    );
  };
  // const searchUsers = (searchText: string) => {
  //   User.searchUsers(searchText.toLowerCase()).then(users => {
  //     setLoadingUsers(false);
  //     setUserResults(users);
  //   });
  // };
  const renderActionButtons = () => (
    <View
      style={{
        position: 'absolute',
        right: 16,
        zIndex: 1,
        top: isMobile ? 130 : 380,
        marginBottom: 16,
        paddingTop: 16,
        paddingBottom: 16,
        // borderTop: '1px solid #656565',
        // borderBottom: '1px solid #656565',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {/* FIXME Make these all reusable - the code is duplicated aside from the text and icon */}
      {(!hasSeen || hasSeen === 'no') && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHasSeen('yes');
              showToast('Seen status set to "Yes"');
              if (currentUser && currentUser.uid) {
                User.setSeenStatus(currentUser.uid, `${selectedMovie.id}`, 'yes');
                // FIXME I'm not sure we want to do this unless they manually pick a date
                // User.setWatchTime(currentUser.uid, `${selectedMovie.id}`, new Date());
              } else {
                navigation.navigate('Sign Up');
              }
            }}
          >
            <IonIcons name="checkmark-circle-outline" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: colors.text}}>Seen</Text>
        </View>
      )}
      {hasSeen === 'maybe' && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHasSeen('no');
              showToast('Seen status set to "No"');
              if (currentUser && currentUser.uid) {
                User.setSeenStatus(currentUser.uid, `${selectedMovie.id}`, 'no');
              } else {
                navigation.navigate('Sign Up');
              }
            }}
          >
            <IonIcons name="help-circle-outline" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <span style={{fontSize: 12, color: colors.text}}>Seen</span>
        </View>
      )}
      {hasSeen === 'yes' && (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity
            onPress={() => {
              setHasSeen('maybe');
              showToast('Seen status set to "Maybe"');
              if (currentUser && currentUser.uid) {
                User.setSeenStatus(currentUser.uid, `${selectedMovie.id}`, 'maybe');
              } else {
                navigation.navigate('Sign Up');
              }
            }}
          >
            <IonIcons name="checkmark-circle" size={26} style={{color: palette.tertiary}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: palette.tertiary}}>Seen</Text>
        </View>
      )}
      {bookmarked ? (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity onPress={toggleBookmarked}>
            <IonIcons name="bookmark" size={26} style={{color: palette.tertiary}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: palette.tertiary}}>Remove</Text>
        </View>
      ) : (
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 56,
          }}
        >
          <TouchableOpacity onPress={toggleBookmarked}>
            <IonIcons name="bookmark-outline" size={26} style={{color: colors.text}}/>
          </TouchableOpacity>
          <Text style={{fontSize: 12, color: colors.text}}>Add</Text>
        </View>
      )}
    </View>
  );
  if (loadingDetail) {
    return (
      <View style={styles.container}>
        <Screen preset="scroll" backgroundColor={color.transparent}>
          <SkeletonView />
          <SkeletonList />
          <SkeletonList />
        </Screen>
      </View>
    );
  }
  const renderInnerContent = () => (
    <>
      <View
        style={{
          padding: 16,
          maxWidth: isMobile ? '100%' : 1200,
          margin: 'auto',
        }}
      >
        {/* See https://www.themoviedb.org/talk/5aef71b4925141768800a5bf for the movie rating (eg, Rated R) - it's the "certification" in the /release_dates endpoint */}
        <Text style={styles.title}>
          {selectedMovie.title}{' '}
          <Text style={{fontSize: 14, color: colors.text}}>
            {`(${selectedMovie.year})`}
          </Text>
        </Text>
        {hasSeen === 'yes' && (
          <>
            <WatchedWith movie={selectedMovie} />
            <WatchedDate movie={selectedMovie} />
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{...styles.title, ...{fontSize: 14, marginTop: 20, color: darkMode ? 'white' : '#121212' }}}>Your Rating</Text>
              <View
                style={{
                  flex: 1,
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: 10,
                  marginBottom: 30,
                }}
              >
                <Rating
                  rating={rating}
                  onChange={(newValue) => {
                    // @ts-ignore
                    setRating(newValue);
                    if (currentUser) {
                      // @ts-ignore
                      User.addRating(currentUser.uid, `${selectedMovie.id}`, newValue);
                    }
                  }}
                  starSize={30}
                  style={{ alignSelf: 'flex-end' }}
                />
              </View>
            </View>
          </>
        )}
        <View style={{display: 'flex', flexDirection: 'row', marginTop: 10}}>
          <View style={{display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center'}}>
            <MovieRatingCircularProgress
              title={undefined}
              titleColor={'transparent'}
              titleFontSize={12}
              fontSize={12}
              titleStyle={{}}
              maxValue={100}
              radius={28}
              value={selectedMovie.rating ? selectedMovie.rating * 10 : 0}
              activeStrokeWidth={6}
              textColor={colors.text}
              activeStrokeColor={'#00b1b2'}
              inActiveStrokeColor={'#656565'}
              inActiveStrokeOpacity={0.5}
              inActiveStrokeWidth={6}
              circleBackgroundColor={'transparent'}
              valueSuffix={'%'}
            />
            <Text
              style={{
                fontSize: 12,
                alignSelf: 'center',
                marginLeft: 10,
                color: colors.text,
              }}
            >
              {selectedMovie.ratingCount} ratings
            </Text>
          </View>
          {/* FIXME There's a z-index issue here with the action buttons and the provider logo but doesn't seem like a straightforward fix */}
          {/* TODO Desktop only */}
          {/*{Platform.OS === 'web' && selectedMovie.videoPreview && (*/}
          {/*  <TrailerPlayer*/}
          {/*    showVideoTrailer={showVideoTrailer}*/}
          {/*    videoId={selectedMovie.videoPreview.replace(*/}
          {/*      'https://youtu.be/',*/}
          {/*      ''*/}
          {/*    )}*/}
          {/*    onClose={() => {*/}
          {/*      setShowVideoTrailer(false);*/}
          {/*      console.debug('Closing video modal');*/}
          {/*    }}*/}
          {/*  />*/}
          {/*)}*/}
          {(selectedMovie.videoPreview && selectedMovie.videoPreview.length > 0) && (
            <View
              style={{
                flex: 1,
                borderLeft: '1px solid #aaa',
                alignItems: 'center',
                height: 44,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  // setShowVideoTrailer(true);
                  navigation.navigate('Trailer', { youtubeId: selectedMovie.videoPreview.replace('https://youtu.be/', '') });
                }}
              >
                <View style={{display: 'flex', flexDirection: 'row'}}>
                  <IonIcons name="play-outline" size={20} style={{color: colors.text, marginTop: 10}}/>
                  <Text style={{color: colors.text, marginLeft: 10, lineHeight: 44}}>
                    Play Trailer
                  </Text>
                </View>
              </TouchableOpacity>
            </View>
          )}
        </View>
        {streamingProviders.length === 0 && (
          <Text style={{marginTop: 22, color: colors.text}}>Not currently streaming</Text>
        )}
        {/* TODO https://www.digitalfodder.com/netflix-affiliate-program-alternatives/ - most seem to have affiliate programs */}
        {streamingProviders.length > 0 && (
          <View
            style={{
              borderWidth: 1,
              borderColor: darkMode ? '#656565' : '#cccccc',
              borderRadius: 6,
              padding: 10,
              marginTop: 24,
            }}
          >
            <Text style={{ color: colors.text, marginBottom: 6 }}>Watch Now</Text>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              {streamingProviders.map(provider => (
                <TouchableOpacity onPress={() => Linking.openURL(provider.link)}>
                  <Image
                    key={provider.name}
                    source={{uri: provider.logo}}
                    // @ts-ignore
                    alt={provider.name}
                    style={{height: 80, width: 80, borderRadius: 8, marginRight: 10}}
                  />
                </TouchableOpacity>
              ))}
            </View>
          </View>
        )}
        {/* FIXME It would make sense to show these sort of ads to users who did not add the platform - ie, they don't
             already have it - specify a given ad unit based on the user's selected platforms  */}
        {shouldShowAds() && (
          <View style={{ marginTop: 20, marginHorizontal: -15 }}>
            <BannerAd
              adUnitId={ isMobile ? '/22758720814/MOVIES.PLUS.TV.MEDIUM.RECTANGLE' : '/22758720814/MOVIES.PLUS.TV.LEADERBOARD' }
              adUnitSize={ isMobile ? '[[300, 250]]' : '[[728, 90]]' }
            />
          </View>
        )}
        <Text style={styles.title}>Overview</Text>
        <Text style={styles.description}>{selectedMovie.description}</Text>
      </View>
      {loadingCast && <SkeletonList />}
      {cast.length > 0 && (
        <View
          style={{
            maxWidth: isMobile ? '100%' : 1200,
            margin: 'auto',
          }}
        >
          <View style={{padding: 16}}>
            <Text style={styles.title}>Cast</Text>
          </View>
          <ScrollView horizontal>
            {cast.map((member: any) => (
              <TouchableOpacity onPress={() => showPersonCredits(member.id)}>
                <View
                  key={member.name}
                  style={{
                    width: 160,
                    minWidth: 160,
                    height: 250,
                    marginLeft: 6,
                    marginRight: 6,
                    borderRadius: 5,
                    overflow: 'hidden',
                    // backgroundColor: darkMode ? palette.offBlack : 'white',
                  }}
                >
                  <Image
                    source={{
                      uri: (member.image && member.image.replace('/w500/', '/w200/')) || 'https://via.placeholder.com/140x210?text=No%20Image'
                    }}
                    style={{ width: 160, height: 160, borderRadius: 90 }}
                  />
                  <View style={{margin: 10}}>
                    <Text
                      style={{
                        fontSize: 14,
                        margin: 0,
                        fontWeight: '700',
                        color: colors.text,
                        textAlign: 'center',
                      }}
                    >
                      {member.name}
                    </Text>
                    <Text
                      style={{
                        fontSize: 13,
                        margin: 0,
                        marginTop: 5,
                        color: colors.text,
                        textAlign: 'center',
                      }}
                    >
                      {member.character}
                    </Text>
                  </View>
                </View>
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}
      {shouldShowAds() && (
        <View style={{ width: Dimensions.get('window').width, maxWidth: 1200, marginTop: 20 }}>
          <BannerAd
            adUnitId={ isMobile ? '/22758720814/MOVIES.PLUS.TV.MEDIUM.RECTANGLE' : '/22758720814/MOVIES.PLUS.TV.LEADERBOARD' }
            adUnitSize={ isMobile ? '[[300, 250]]' : '[[728, 90]]' }
          />
        </View>
      )}
      {loadingRecommended && <SkeletonList />}
      {recommendedMovies.length > 0 && (
        <View
          style={{
            maxWidth: isMobile ? '100%' : 1200,
            margin: 'auto',
          }}
        >
          <View style={{display: 'flex', padding: 16}}>
            <Text style={styles.title}>Other Viewers Watched</Text>
            {/* <Text>View all</span> */}
          </View>
          <ScrollView horizontal={true}>
            {recommendedMovies.map((movie: any) => (
              <TouchableOpacity key={movie.id} onPress={() => showDetail(movie)}>
                <Image
                  source={{uri: movie.posterImage.replace('/w500/', '/w200/')}}
                  style={{
                    height: 180,
                    margin: 3,
                    width: 120,
                    borderRadius: 5,
                  }}
                />
              </TouchableOpacity>
            ))}
          </ScrollView>
        </View>
      )}
      {loadingSimilar && <SkeletonList />}
      {similarMovies.length > 0 && (
        <View
          style={{
            maxWidth: isMobile ? '100%' : 1200,
            margin: 'auto',
          }}
        >
          <View style={{display: 'flex', padding: 16}}>
            <Text style={styles.title}>Similar Movies</Text>
            {/* <Text>View all</span> */}
          </View>
          <ScrollView horizontal={true}>
            {similarMovies.map((movie: any) => (
              <View
                style={{margin: 5}}
              >
                <TouchableOpacity key={movie.id} onPress={() => showDetail(movie)}>
                  <Image
                    source={{uri: movie.posterImage.replace('/w500/', '/w200/')}}
                    style={{
                      height: 180,
                      margin: 3,
                      width: 120,
                      borderRadius: 5,
                    }}
                  />
                </TouchableOpacity>
              </View>
            ))}
          </ScrollView>
        </View>
      )}
    </>
  )
  const renderContent = () => {
    if (isMobile) {
      return renderInnerContent();
    }
    return (
      <View style={{ display: 'flex', flexDirection: 'row', maxWidth: isMobile ? '100%' : 1200, margin: 'auto' }}>
        <View style={{ flex: 1 }}>
          {renderInnerContent()}
        </View>
        <View style={{ marginTop: 50 }}>
          {shouldShowAds() && (
            <BannerAd />
          )}
        </View>
      </View>
    )
  }
  return (
    <View style={styles.container}>
      <Screen style={styles.innerContainer} backgroundColor={color.transparent}>
        <ScrollView ref={scrollRef}>
          {renderActionButtons()}
          {Platform.OS === 'web' && (
            <div style={{ height: isMobile ? 200 : 520, backgroundImage: `url(${selectedMovie.posterBackdrop || selectedMovie.posterImage}`, backgroundPosition: 'top', backgroundSize: 'cover' }} />
          )}
          {Platform.OS !== 'web' && (
            <ImageBackground
              // @ts-ignore
              source={{uri: selectedMovie.posterBackdrop || selectedMovie.posterImage}}
              resizeMode="cover"
              // FIXME How to apply background-position: center top; to the background?
              style={styles.headerBackground}
            />
          )}
          <LinearGradient
            colors={['transparent', darkMode ? '#121212' : '#efefef']}
            style={{
              marginTop: 0,
              position: 'absolute',
              top: isMobile ? 50 : 0,
              width: '100%',
              height: isMobile ? 150 : 520,
            }}
          />
          {!isMobile && (
            <View
              style={{
                marginTop: 0,
                marginLeft: -16,
                marginRight: -16,
                // background: isMobile
                //     ? `linear-gradient(0, ${
                //         darkMode ? '#121212' : 'white'
                //     }, transparent)`
                //     : `linear-gradient(45deg, ${
                //         darkMode ? '#121212' : 'white'
                //     }, transparent)`,
                position: 'absolute',
                top: isMobile ? 50 : 0,
                width: '100%',
                height: isMobile ? 150 : 520,
              }}
            />
          )}
          {!isMobile && (
            <Image
              // @ts-ignore
              source={selectedMovie.posterImage && selectedMovie.posterImage.replace('/w500/', '/w200/')}
              style={{
                height: 300,
                width: 200,
                position: 'absolute',
                top: 120,
                left: 30,
                borderRadius: 8,
              }}
            />
          )}
          {renderContent()}
        </ScrollView>
      </Screen>
    </View>
  );
});
