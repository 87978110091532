import * as React from "react"
import {KeyboardAvoidingView, Platform, ScrollView, StatusBar, useColorScheme, View} from "react-native"
// import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ScreenProps } from "./screen.props"
import { isNonScrolling, offsets, presets } from "./screen.presets"

const isIos = Platform.OS === "ios"

function ScreenWithoutScrolling(props: ScreenProps) {
  // const insets = useSafeAreaInsets()
  const colorScheme = useColorScheme();
  const preset = presets.fixed
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}
  // const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }

  const statusBarBgColor = colorScheme === "dark" ? '#121212' : '#ffffff';
  const statusBarColor = colorScheme === "dark" ? 'light-content' : 'dark-content';

  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : undefined}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >
      <StatusBar
        backgroundColor={props.statusBar ? props.statusBar.backgroundColor : statusBarBgColor}
        barStyle={props.statusBar ? props.statusBar.barStyle : statusBarColor}
      />
      {/*<View style={[preset.inner, style, insetStyle]}>{props.children}</View>*/}
      <View style={[preset.inner, style]}>{props.children}</View>
    </KeyboardAvoidingView>
  )
}

function ScreenWithScrolling(props: ScreenProps) {
  // const insets = useSafeAreaInsets();
  const colorScheme = useColorScheme();
  const preset = presets.scroll
  const style = props.style || {}
  const backgroundStyle = props.backgroundColor ? { backgroundColor: props.backgroundColor } : {}
  // const insetStyle = { paddingTop: props.unsafe ? 0 : insets.top }

  const statusBarBgColor = colorScheme === "dark" ? '#121212' : '#ffffff';
  const statusBarColor = colorScheme === "dark" ? 'light-content' : 'dark-content';

  return (
    <KeyboardAvoidingView
      style={[preset.outer, backgroundStyle]}
      behavior={isIos ? "padding" : undefined}
      keyboardVerticalOffset={offsets[props.keyboardOffset || "none"]}
    >
      {/* FIXME This isn't using the header colors - make sure it matches */}
      <StatusBar
        backgroundColor={props.statusBar ? props.statusBar.backgroundColor : statusBarBgColor}
        barStyle={props.statusBar ? props.statusBar.barStyle : statusBarColor}
      />
      {/*<View style={[preset.outer, backgroundStyle, insetStyle]}>*/}
      <View style={[preset.outer, backgroundStyle]}>
        <ScrollView
          style={[preset.outer, backgroundStyle]}
          contentContainerStyle={[preset.inner, style]}
          keyboardShouldPersistTaps={props.keyboardShouldPersistTaps || "handled"}
          onScroll={props.onScroll}
          scrollEventThrottle={props.scrollEventThrottle}
        >
          {props.children}
        </ScrollView>
      </View>
    </KeyboardAvoidingView>
  )
}

/**
 * The starting component on every screen in the app.
 *
 * @param props The screen props
 */
export function Screen(props: ScreenProps) {
  if (isNonScrolling(props.preset)) {
    return <ScreenWithoutScrolling {...props} />
  } else {
    return <ScreenWithScrolling {...props} />
  }
}
