import React, { useState, createContext, useContext } from 'react';
import { UserType } from './UserDetailContext';

export interface SettingsType {
  authenticated?: boolean;
  user?: UserType;
  allProviders?: any; // TODO ProviderType
}

interface ContextType {
  settings: SettingsType;
  setSettings?: (settings: SettingsType | null) => void;
  setAllProviders?: (providers: any) => void, // TODO ProviderType
}

const initialSettings = {
  allProviders: {},
};

const SettingsContext = createContext<ContextType>({
  settings: initialSettings,
  // authenticated?: // if user in localstorage;
  // user?: // pull from localstorage;
});

// @ts-ignore
export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(initialSettings);
  // FIXME Can we merge an object? Just pass in something like setSettings?.({ darkMode: true }); ?
  const updateSettings = (settings: SettingsType | null) => {
    // @ts-ignore
    setSettings(settings);
  };
  const updateProviders = (p: any) => {
    settings.allProviders = p;
    updateSettings(settings);
  }
  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings: updateSettings,
        setAllProviders: updateProviders,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
export default SettingsContext;
