import React from "react";
import {
  Placeholder,
  PlaceholderLine,
  Fade,
} from 'rn-placeholder';
import {ScrollView, View} from "react-native";

const cardStyle = { borderRadius: 6, marginLeft: 8 };

export default () => (
  <View>
    <Placeholder Animation={Fade}>
      <PlaceholderLine width={30} height={20} style={{ marginLeft: 16, marginTop: 10 }} />
      <View style={{ display: 'flex', flexDirection: 'row' }}>
          <PlaceholderLine style={cardStyle} width={30} height={180} noMargin />
          <PlaceholderLine style={cardStyle} width={30} height={180} noMargin />
          <PlaceholderLine style={cardStyle} width={30} height={180} noMargin />
          <PlaceholderLine style={cardStyle} width={30} height={180} noMargin />
      </View>
    </Placeholder>
  </View>
)
