import React, {useEffect, useRef, useState} from 'react';
import {StackScreenProps} from "@react-navigation/stack";
import {ActionBarImage, NavigatorParamList} from "../navigators";
import {observer} from "mobx-react-lite";
import {
  Dimensions,
  Image as StandardImage,
  ImageBackground,
  Platform,
  ScrollView,
  Text,
  TextStyle,
  TouchableOpacity,
  useColorScheme,
  View,
  ViewStyle,
} from "react-native";
import {Image} from "react-native-elements";
import {Screen} from "../components";
import {color, spacing} from "../theme";
import {isMobile} from "../components/mobile-device-detect";
import ReadMore from '../components/read-more';
import {MovieType, useMovieContext} from "../contexts/MovieDetailContext";
import axios from "axios";
import {baseOriginalPath, smallPath, basePath} from "../models/movie";
import {LinearGradient} from "expo-linear-gradient";
import {palette} from "../theme/palette";

const flexStyle: ViewStyle = {flex: 1};

const movieDbApiKey = process.env.TMDB_API_KEY;

export const CastMemberScreen: React.FC<StackScreenProps<NavigatorParamList, "Cast Member">> = observer(({route, navigation}) => {
  const [loadingPerson, setLoadingPerson] = useState(true);
  const [person, setPerson] = useState({});
  const [movies, setMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [images, setImages] = useState([]);
  const {setSelectedMovie} = useMovieContext();
  const colorScheme = useColorScheme();

  const scrollRef = useRef();

  const innerContainerStyle: ViewStyle = {
    backgroundColor: colorScheme === 'dark' ? '#121212' : '#efefef',
  };

  const textStyle: TextStyle = {
    color: colorScheme === 'dark' ? 'white' : '#121212',
    fontSize: 16,
    marginTop: 16,
    marginBottom: 10,
    fontWeight: '500',
  }
  const titleStyle: TextStyle = {
    color: colorScheme === 'dark' ? 'white' : '#121212',
    fontSize: 20,
    marginTop: 16,
    marginBottom: 10,
    fontWeight: '500',
  }

  const showDetail = (movie: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(movie);
    // @ts-ignore
    navigation.navigate("Movie Detail", {movieId: movie.id});
  };

  const showTvDetail = (show: MovieType) => {
    // console.debug(show);
    setSelectedMovie?.(show);
    // @ts-ignore
    navigation.navigate("TV Show Detail", { showId: show.id });
  };

  useEffect(() => {
    if (isMobile) {
      navigation.setOptions({
        headerTitle: () => person ? (
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text
              style={{
                textAlign: 'left',
                color: colorScheme === 'dark'? 'white' : '#121212',
                fontSize: 16,
                marginLeft: 10,
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: Platform.OS === 'android' ? 10 : 0,
              }}
            >
              {person.name}
            </Text>
          </View>
        ) : <ActionBarImage/>,
      });
    }
  }, [person]);

  useEffect(() => {
    scrollRef && scrollRef.current && scrollRef.current.scrollTo(0);
    let { personId } = route.params;
    if (personId) {
      axios
        .get(
          `https://api.themoviedb.org/3/person/${personId}?api_key=${movieDbApiKey}`
        )
        .then(res => {
          const {data} = res;
          setPerson(data);
          setLoadingPerson(false);
        }).catch(() => {
          setLoadingPerson(false);
        });

      // FIXME Can we get these most recent to oldest or most popular to least?
      axios
        .get(
          `https://api.themoviedb.org/3/person/${personId}/movie_credits?api_key=${movieDbApiKey}`
        )
        .then(res => {
          const {data} = res;
          setMovies(data.cast.map((movie: any) => ({
            id: movie.id,
            title: movie.title,
            description: movie.overview,
            posterImage:
              movie.poster_path &&
              `${smallPath}${movie.poster_path}`,
            posterBackdrop:
              movie.backdrop_path &&
              `${baseOriginalPath}${movie.backdrop_path}`,
            year: movie.release_date.substring(0, 4),
            rating: movie.vote_average,
            ratingCount: movie.vote_count,
          })));
          // setLoadingMovies(false);
        }).catch(() => {
          // setLoadingMovies(false);
        });

      axios
        .get(
          `https://api.themoviedb.org/3/person/${personId}/tv_credits?api_key=${movieDbApiKey}`
        )
        .then(res => {
          const {data} = res;
          setTvShows(data.cast.map((movie: any) => ({
            id: movie.id,
            title: movie.name,
            description: movie.overview,
            posterImage:
              movie.poster_path && `${smallPath}${movie.poster_path}`,
            posterBackdrop:
              movie.backdrop_path &&
              `${baseOriginalPath}${movie.backdrop_path}`,
            year: movie.first_air_date.substring(0, 4),
            rating: movie.vote_average,
            ratingCount: movie.vote_count,
            type: 'tv',
          })))
          // setLoadingCast(false);
        }).catch(() => {
          // setLoadingCast(false);
        });

      axios
        .get(
          `https://api.themoviedb.org/3/person/${personId}/images?api_key=${movieDbApiKey}`
        )
        .then(res => {
          const {data} = res;
          setImages(data.profiles.map((profile) => ({
            thumbnail: profile.file_path && `${smallPath}${profile.file_path}`,
            source: { uri: profile.file_path && `${baseOriginalPath}${profile.file_path}` },
          })));
          // setLoadingCast(false);
        }).catch(() => {
          // setLoadingCast(false);
        });
    }
  }, [route.params.personId]);

  const renderMovieCard = (movie) => (
    <TouchableOpacity onPress={() => showDetail(movie)}>
      <Image
        source={{uri: movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')}}
        // PlaceholderContent={<ActivityIndicator />}
        style={{
          height: isMobile ? 180 : 320,
          width: isMobile ? 120 : 200,
          borderRadius: 5,
          margin: 3,
        }}
      />
    </TouchableOpacity>
  );

  const renderTvCard = (movie) => (
    <TouchableOpacity onPress={() => showTvDetail(movie)}>
      <Image
        source={{uri: movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')}}
        // PlaceholderContent={<ActivityIndicator />}
        style={{
          height: isMobile ? 180 : 320,
          width: isMobile ? 120 : 200,
          borderRadius: 5,
          margin: 3,
        }}
      />
    </TouchableOpacity>
  );

  const renderViewLess = onPress => {
    return (
      <View>
        <TouchableOpacity onPress={onPress}>
          {/* TODO Up caret */}
          <Text style={{ color: palette.secondary }}>View less</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const renderViewMore = onPress => {
    return (
      <View>
        <TouchableOpacity onPress={onPress}>
          {/* TODO Down caret */}
          <Text style={{ color: palette.secondary }}>Read more</Text>
        </TouchableOpacity>
      </View>
    );
  };

  const getDefaultImage = () => {
    if (images && images.length) {
      return images[0].source.uri;
    }
    return 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvdhpJ2oJ0BBsqlzQ6oTSow_O_uUjkn05BSkpEcSsSgpdR-01Mv48NsbvAvU_g0M0eWk0&usqp=CAU';
  }

  const formatDate = (string) => {
    let date = new Date(string);
    date = new Date(date.getTime() + date.getTimezoneOffset() * 60000)
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  const renderMainContent = () => (
    <>
      {movies.length > 0 && (
        <View style={{paddingHorizontal: spacing[4], display: 'flex', flexDirection: 'column'}}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text style={{ ...titleStyle, flex: 1 }}>Movies</Text>
            {/*<Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('Movies', { genre: { key: 'trending/movie/day', title: 'Trending Today' }})}>View all</Text>*/}
          </View>
          <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
            {movies.map((movie: any) => (
              <View key={movie.id} style={{margin: 5}}>
                {renderMovieCard(movie)}
              </View>
            ))}
          </ScrollView>
        </View>
      )}
      {tvShows.length > 0 && (
        <View style={{paddingHorizontal: spacing[4], display: 'flex', flexDirection: 'column'}}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <Text style={{ ...titleStyle, flex: 1 }}>TV Shows</Text>
            {/*<Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('Genre List', { genre: { key: 'trending/movie/day', title: 'Trending Today' }})}>View all</Text>*/}
          </View>
          <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
            {tvShows.map((tvShow: any) => (
              <View key={tvShow.id} style={{margin: 5}}>
                {renderTvCard(tvShow)}
              </View>
            ))}
          </ScrollView>
        </View>
      )}
    </>
  );

  const renderBio = () => (
    <>
      <View style={{padding: 16}}>
        <Text style={titleStyle}>Biography</Text>
      </View>
      <View style={{paddingHorizontal: 16}}>
        <ReadMore
          textStyle={textStyle}
          numberOfLines={3}
          text={person.biography}
          renderViewLess={renderViewLess}
          renderViewMore={renderViewMore}
        />
        {person.birthday && (
          <>
            <Text style={{...textStyle, fontWeight: '700'}}>Born</Text>
            {/* TODO Calculate age */}
            <Text style={{...textStyle, marginTop: 0}}>{formatDate(person.birthday)}</Text>
            {person.place_of_birth && <Text style={{...textStyle, marginTop: 0}}>{person.place_of_birth}</Text>}
          </>
        )}
        {person.deathday && (
          <>
            <Text style={{...textStyle, fontWeight: '700'}}>Died</Text>
            {/* TODO Calculate age */}
            <Text style={{...textStyle, marginTop: 0}}>{formatDate(person.deathday)}</Text>
          </>
        )}
      </View>
    </>
  );

  const renderImages = () => (
    <>
      <View style={{padding: 16}}>
        <Text style={titleStyle}>Images</Text>
      </View>
      {/* TODO 'See All' button */}
      {/* TODO https://stackoverflow.com/questions/47140920/react-native-how-to-implement-carousel-of-images-with-zoom-in-flatlist-componen */}
      <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
        {images.map((image) => (
          <Image source={{ uri: image.source.uri }} style={{height: 100, width: 100, margin: 10}}/>
        ))}
      </ScrollView>
    </>
  );

  if (loadingPerson) {
    if (isMobile) {
      return (
        <View>
          {/* TODO Skeleton view */}
        </View>
      );
    }
    return (
      <View>
        {/* TODO Skeleton view */}
      </View>
    );
  }

  if (isMobile) {
    return (
      <View style={flexStyle}>
        <Screen style={{...innerContainerStyle }} backgroundColor={color.transparent}>
          <ScrollView ref={scrollRef}>
            {/*<Image src={person.image} />*/}
            <Image source={{ uri: getDefaultImage() }}
                   style={{height: Dimensions.get('window').width, width: Dimensions.get('window').width}}/>
            <LinearGradient
              colors={['transparent', colorScheme === 'dark' ? '#121212' : '#efefef']}
              style={{
                marginTop: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                height: Dimensions.get('window').width,
              }}
            />
            {renderMainContent()}
            {renderBio()}
            {renderImages()}
          </ScrollView>
        </Screen>
      </View>
    );
  }
  return (
    <View style={flexStyle}>
      <Screen style={{...innerContainerStyle, paddingBottom: 30}} backgroundColor={color.transparent}>
        <ScrollView ref={scrollRef}>
          <ImageBackground
            // @ts-ignore
            source={{ uri: movies && movies.length && movies[0].posterBackdrop }}
            resizeMode="cover"
            style={{ height: 400, width: '100%' }}
          />
          <LinearGradient
            colors={['transparent', colorScheme === 'dark' ? '#121212' : '#efefef']}
            style={{
              marginTop: 0,
              position: 'absolute',
              top: 0,
              width: '100%',
              height: 400,
            }}
          />
          <StandardImage source={getDefaultImage()} style={{height: 200, width: 200, borderRadius: 100, position: 'absolute', left: 30, top: 300 }} />
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: 320, marginTop: 120 }}>
              <Text style={{ ...titleStyle, fontWeight: '700', marginLeft: 16, fontSize: 26 }}>{person.name}</Text>
              {renderBio()}
            </View>
            <View style={{ flex: 1 }}>
              {renderMainContent()}
              {renderImages()}
            </View>
          </View>
        </ScrollView>
      </Screen>
    </View>
  )
});
