import React, { useEffect, useState } from 'react';
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {Image, TouchableOpacity, Text, View, useColorScheme, Platform} from "react-native";
import {ListItem, SearchBar} from "react-native-elements";
import {palette} from "../theme/palette";
import {ActivityIndicator} from "react-native-paper";
import axios from "axios";
import {color} from "../theme";
import {Screen} from "../components";
import {baseOriginalPath} from "../models/movie";
import {providerLogos} from "./MovieDetail";
import {useSettingsContext} from "../contexts/AppContext";
import User from "../models/user";
import {useAuth} from "../contexts/AuthContext";

const movieDbApiKey = process.env.TMDB_API_KEY;
let allProviders = [];
export const ProviderListScreen: React.FC<StackScreenProps<NavigatorParamList, "Providers">> = ({ navigation }) => {
  const {currentUser} = useAuth();
  const {settings, setAllProviders} = useSettingsContext();
  const {allProviders: selectedProviders} = settings;
  const [providers, setProviders] = useState([]);
  const [checkedProviders, setCheckedProviders] = useState({});
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(true);
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  useEffect(() => {
    axios
      .get(
        `https://api.themoviedb.org/3/watch/providers/movie?api_key=${movieDbApiKey}&language=en&watch_region=US`
      )
      .then(res => {
        setLoading(false);
        const {data} = res;
        console.log(data);
        // FIXME Why is Amazon Prime in the list twice?
        const p = [];
        let hasPrime = false;
        data.results.forEach((result) => {
          if (result.provider_name !== 'Amazon Prime Video') {
            p.push({
              id: result.provider_id,
              name: result.provider_name,
              image_url: result.logo_path && `${baseOriginalPath}${result.logo_path}`,
            });
          } else {
            hasPrime = true;
          }
        });
        if (hasPrime) {
          p.unshift({
            id: 9,
            name: 'Amazon Prime Video',
            image_url: `${baseOriginalPath}/emthp39XA2YScoYL1p0sdbAH2WA.jpg`,
          });
        }
        allProviders = p;
        setProviders(p);
      }).catch((err) => {
        setLoading(false);
        console.error(err);
      });

    if (selectedProviders) {
      Object.keys(selectedProviders).forEach((providerId) => {
        checkedProviders[providerId] = !checkedProviders[providerId];
        setCheckedProviders({...checkedProviders});
      })
    }
  }, []);

  useEffect(() => {
    (async() => {
      if (currentUser && currentUser.uid) {
        await User.getProviders(currentUser.uid).then((p) => {
          setAllProviders?.(p);
        });
      }
    })();
  }, [currentUser]);

  useEffect(() => {
    // FIXME Debounce
    console.log('Filtering providers...');
    if (query.length > 0) {
      // @ts-ignore
      setProviders(allProviders.filter((p) => p.name.toLowerCase().indexOf(query.toLowerCase()) !== -1))
    } else {
      setProviders(allProviders);
    }
  }, [query]);
  const toggleProvider = async (provider) => {
    // FIXME Require the user to log in to add these? This should be a private route, if so, otherwise use some UUID or
    //  their IP address to store them temporarily
    if (checkedProviders[provider.id]) {
      User.removeProvider(currentUser.uid, provider);
    } else {
      User.addProvider(currentUser.uid, provider);
    }
    checkedProviders[provider.id] = !checkedProviders[provider.id];
    setCheckedProviders({...checkedProviders});
    // TODO This is either too slow or glitchy - add the providers manually and then call this
    await User.getProviders(currentUser.uid).then((p) => {
      setAllProviders?.(p);
    });
  }
  return (
    <View style={{ flex: 1 }}>
      <Screen preset="scroll" backgroundColor={color.transparent}>
        <SearchBar
          value={query}
          onChangeText={setQuery}
          lightTheme={!darkMode}
          placeholder="Filter..."
          containerStyle={{
            // backgroundColor: searchBarOpacity > 0 ? (darkMode ? `rgba(18,18,18,${searchBarOpacity})` : `rgba(256,256,256,${searchBarOpacity})`) : 'transparent',
            backgroundColor: darkMode ? `rgba(18,18,18,1)` : `rgba(256,256,256,1)`,
            // marginTop: 20,
            position: Platform.OS === 'web' ? 'sticky' : 'relative', // FIXME Find a way to stick on RN - see https://stackoverflow.com/questions/38581562/sticky-component-inside-scrollview
            top: 0,
            zIndex: 1000,
            borderTopColor: 'transparent',
          }}
        />
        {Object.keys(selectedProviders).length > 0 && (
          <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 20, width: '100%', paddingHorizontal: 16 }}>
            <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
              {Object.keys(selectedProviders).map((provider) => (
                <Image source={{ uri: providerLogos[selectedProviders[provider].toLowerCase().replace(new RegExp(' ', 'g'), '_')] }} style={{ height: 30, width: 30, marginHorizontal: 4, borderRadius: 3 }} />
              ))}
            </View>
          </View>
        )}
        {!loading && providers.length === 0 && (
          <Text
            style={{
              marginTop: 30,
              textAlign: 'center',
              color: darkMode ? 'white' : '#121212'
            }}
          >
            No providers found
          </Text>
        )}
        {loading && providers.length === 0 && (
          <View style={{ alignSelf: 'center', marginTop: 30 }}>
            <ActivityIndicator size={30} animating={true} color={palette.primary} />
          </View>
        )}
        <View
          style={{
            width: '100%',
            bgcolor: 'background.paper',
          }}
        >
          {providers.map((provider) => (
            <TouchableOpacity
              onPress={() => {
                // TODO Toggle checked
              }}
            >
              <ListItem
                key={provider.id}
                containerStyle={{
                  marginHorizontal: 16,
                  marginVertical: 4,
                  borderRadius: 8,
                  backgroundColor: darkMode ? '#343434' : 'white',
                }}
              >
                <Image source={{ uri: provider.image_url }} style={{ height: 40, width: 40, borderRadius: 8 }} />
                <ListItem.Content>
                  <ListItem.Title
                    style={{ fontWeight: 'bold', color: darkMode ? 'white' : '#121212' }}
                  >
                    {provider.name}
                  </ListItem.Title>
                </ListItem.Content>
                <ListItem.CheckBox
                  checked={checkedProviders[provider.id]}
                  onPress={() => toggleProvider(provider)}
                />
              </ListItem>
            </TouchableOpacity>
          ))}
        </View>
      </Screen>
    </View>
  );
};
