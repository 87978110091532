import {format} from "date-fns";
import {createTheme, TextField, ThemeProvider} from "@mui/material";
import {LocalizationProvider, MobileDatePicker} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {isIOS} from "../mobile-device-detect";
import React, {useEffect, useState} from "react";
import User from "../../models/user";
import {useAuth} from "../../contexts/AuthContext";
import {useColorScheme} from "react-native";

export const WatchedDate = ({ movie }) => {
  const {currentUser} = useAuth();
  const [watchTime, setWatchTime] = useState<Date | null>(null);
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
  });
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      User.getWatchTime(currentUser.uid, `${movie.id}`).then(
        time => {
          if (time) {
            // FIXME This seems to be off by 1 day
            setWatchTime(new Date(time));
          }
        }
      );
    }
  }, [currentUser]);
  return (
    <div style={{ display: 'flex', borderBottom: '1px solid #323232' }}>
      <ThemeProvider theme={theme}>
        {/* TODO On blur, hide the picker and just show the date with "edit" / pencil button */}
        {isIOS && (
          <TextField
            label="Date Watched"
            type="date"
            fullWidth
            defaultValue={watchTime && format(watchTime, 'yyyy-MM-dd')}
            onChange={(e) => {
              console.log(e.target.value);
              setWatchTime(new Date(e.target.value));
              if (currentUser) {
                User.setWatchTime(currentUser.uid, `${movie.id}`, new Date(e.target.value));
              } else {
                // TODO Redirect to sign up screen?
              }
            }}
            sx={{ marginTop: '20px', marginBottom: '20px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
        {!isIOS && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <MobileDatePicker
              label="Date Watched"
              value={watchTime || new Date()}
              onChange={(newValue) => {
                setWatchTime(newValue);
                if (currentUser) {
                  User.setWatchTime(currentUser.uid, `${movie.id}`, newValue);
                } else {
                  // TODO Redirect to sign up screen?
                }
              }}
              renderInput={(params) => <TextField fullWidth sx={{ marginTop: '20px', marginBottom: '20px' }} {...params} />}
            />
          </LocalizationProvider>
        )}
      </ThemeProvider>
    </div>
  );
};
