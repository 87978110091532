// This is the first file that ReactNative will run when it starts up.
import App from "./app/app.tsx"
import { registerRootComponent } from "expo"
import 'react-native-gesture-handler';
import * as Sentry from "sentry-expo";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

Sentry.init({
  dsn: "https://b0132584fa1b47c098cf13f4777dcee2@o1126651.ingest.sentry.io/6585995",
  tracesSampleRate: 1.0,
  enableInExpoDevelopment: true,
  debug: true,
});

registerRootComponent(App)
export default App

// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
