import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {MovieType, useMovieContext,} from '../contexts/MovieDetailContext';
import {baseOriginalPath, basePath} from "../models/movie";
import {
  View,
  ViewStyle,
  Text,
  TouchableOpacity,
  TextStyle,
  ScrollView,
  useColorScheme,
} from "react-native";
import {color, spacing} from "../theme";
import {Screen} from "../components";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {observer} from "mobx-react-lite";
import {providerLogos} from "./MovieDetail";
import {Image, ListItem} from "react-native-elements";
import User from "../models/user";
import {useAuth} from "../contexts/AuthContext";
import {useSettingsContext} from "../contexts/AppContext";
import {isMobile} from "../components/mobile-device-detect";

const movieDbApiKey = process.env.TMDB_API_KEY;

// TODO Let users toggle them on or off - full list is at https://www.themoviedb.org/bible/movie/59f3b16d9251414f20000006
const genreTypes = {
  53: 'Thriller',
  878: 'Science Fiction',
  35: 'Comedy',
  80: 'Crime',
  27: 'Horror',
  10749: 'Romance',
  99: 'Documentary'
};

const flexStyle: ViewStyle = { flex: 1, maxWidth: isMobile ? '100%' : 1600, margin: 'auto' }
const containerStyle: ViewStyle = {
  // backgroundColor: '#121212', // colors.background, // FIXME Depends on the theme
  paddingHorizontal: spacing[4],
}
// FIXME Should be recommendations and similar content like JustWatch offers
export const HomeScreen: React.FC<StackScreenProps<NavigatorParamList, "Home">> = observer(({navigation}) => {
  const {currentUser} = useAuth();
  const {settings, setAllProviders} = useSettingsContext();
  const {allProviders : providers} = settings;
  const providerString = Object.keys(providers).join('|');
  const [userWatchlist, setUserWatchlist] = useState([]);
  const [trendingMovies, setTrendingMovies] = useState([]);
  const [popularMovies, setPopularMovies] = useState([]);
  const [highlyRatedMovies, setHighlyRatedMovies] = useState([]);
  // @ts-ignore
  const [newlyReleasedMovies, setNewlyReleasedMovies] = useState([]);
  const [tvShows, setTvShows] = useState([]);
  const [genres, setGenres] = useState({});
  const {setSelectedMovie} = useMovieContext();
  const colorScheme = useColorScheme();
  const showDetail = (movie: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(movie);
    // @ts-ignore
    navigation.navigate("Movie Detail", {movieId: movie.id});
  };

  const showTvDetail = (show: MovieType) => {
    // console.debug(movie);
    setSelectedMovie?.(show);
    // @ts-ignore
    navigation.navigate("TV Show Detail", { showId: show.id });
  };

  const textStyle: TextStyle = {
    color: colorScheme === 'dark' ? 'white' : '#121212',
    fontSize: 16,
    marginTop: 16,
    marginBottom: 10,
    fontWeight: '500',
  }
  const titleStyle: TextStyle = {
    color: colorScheme === 'dark' ? 'white' : '#121212',
    fontSize: 20,
    marginTop: 16,
    marginBottom: 10,
    fontWeight: '500',
  }
  useEffect(() => {
    (async() => {
      if (currentUser && currentUser.uid) {
        await User.getProviders(currentUser.uid).then((p) => {
          setAllProviders?.(p);
        });
      }
    })();
  }, [currentUser]);
  useEffect(() => {
    axios
      .get(
        `https://api.themoviedb.org/3/trending/movie/day?api_key=${movieDbApiKey}&language=en&with_watch_providers=${providerString}&watch_region=US`
      )
      .then(res => {
        const {data} = res;
        console.log(data);
        setTrendingMovies(
          data.results
            .filter((movie: any) => {
              return !!movie.backdrop_path;
            })
            .map((movie: any) => ({
              id: movie.id,
              title: movie.title,
              description: movie.overview,
              posterImage:
                movie.poster_path &&
                `${basePath}${movie.poster_path}`,
              posterBackdrop:
                movie.backdrop_path &&
                `${baseOriginalPath}${movie.backdrop_path}`,
              year: movie.release_date.substring(0, 4),
              rating: movie.vote_average,
              ratingCount: movie.vote_count,
            }))
        );
      }).catch((err) => {
      console.error(err);
    });

    axios
      .get(
        `https://api.themoviedb.org/3/movie/popular?api_key=${movieDbApiKey}&language=en&with_watch_providers=${providerString}&watch_region=US`
      )
      .then(res => {
        const {data} = res;
        // console.debug(data);
        setPopularMovies(
          data.results
            .filter((movie: any) => {
              return !!movie.backdrop_path;
            })
            .map((movie: any) => ({
              id: movie.id,
              title: movie.title,
              description: movie.overview,
              posterImage:
                movie.poster_path &&
                `${basePath}${movie.poster_path}`,
              posterBackdrop:
                movie.backdrop_path &&
                `${baseOriginalPath}${movie.backdrop_path}`,
              year: movie.release_date.substring(0, 4),
              rating: movie.vote_average,
              ratingCount: movie.vote_count,
            }))
        );
      });

    axios
      .get(
        `https://api.themoviedb.org/3/movie/top_rated?api_key=${movieDbApiKey}&language=en&with_watch_providers=${providerString}&watch_region=US`
      )
      .then(res => {
        const {data} = res;
        // console.debug(data);
        setHighlyRatedMovies(
          data.results
            .filter((movie: any) => {
              return !!movie.backdrop_path;
            })
            .map((movie: any) => ({
              id: movie.id,
              title: movie.title,
              description: movie.overview,
              posterImage:
                movie.poster_path &&
                `${basePath}${movie.poster_path}`,
              posterBackdrop:
                movie.backdrop_path &&
                `${baseOriginalPath}${movie.backdrop_path}`,
              year: movie.release_date.substring(0, 4),
              rating: movie.vote_average,
              ratingCount: movie.vote_count,
            }))
        );
      });

    // axios
    //   .get(
    //     `https://api.themoviedb.org/3/movie/now_playing?api_key=${movieDbApiKey}&language=en`
    //   )
    //   .then(res => {
    //     const { data } = res;
    //     console.debug(data);
    //     setNewlyReleasedMovies(
    //       data.results
    //         .filter((movie: any) => {
    //           return !!movie.backdrop_path;
    //         })
    //         .map((movie: any) => ({
    //           id: movie.id,
    //           title: movie.title,
    //           description: movie.overview,
    //           posterImage:
    //             movie.poster_path &&
    //             `${basePath}${movie.poster_path}`,
    //           posterBackdrop:
    //             movie.backdrop_path &&
    //             `${baseOriginalPath}${movie.backdrop_path}`,
    //           year: movie.release_date.substring(0, 4),
    //           rating: movie.vote_average,
    //           ratingCount: movie.vote_count,
    //         }))
    //     );
    //   });

    axios
      .get(
        `https://api.themoviedb.org/3/discover/tv?api_key=${movieDbApiKey}&language=en&&with_watch_providers=${providerString}&watch_region=US`
      )
      .then(res => {
        const {data} = res;
        // console.debug(data);
        setTvShows(data.results
          .filter((movie: any) => {
            return !!movie.backdrop_path;
          })
          .map((movie: any) => ({
            id: movie.id,
            title: movie.name,
            description: movie.overview,
            posterImage:
              movie.poster_path && `${basePath}${movie.poster_path}`,
            posterBackdrop:
              movie.backdrop_path &&
              `${baseOriginalPath}${movie.backdrop_path}`,
            year: movie.first_air_date.substring(0, 4),
            rating: movie.vote_average,
            ratingCount: movie.vote_count,
            type: 'tv',
          })));
      });

    Object.keys(genreTypes).map((key) => {
      axios
        .get(
          `https://api.themoviedb.org/3/discover/movie?api_key=${movieDbApiKey}&language=en&with_genres=${key}&with_watch_providers=${providerString}&watch_region=US`
        )
        .then(res => {
          const {data} = res;
          // console.debug(data);
          // @ts-ignore
          genres[key] = data.results
            .filter((movie: any) => {
              return !!movie.backdrop_path;
            })
            .map((movie: any) => ({
              id: movie.id,
              title: movie.title,
              description: movie.overview,
              posterImage:
                movie.poster_path && `${basePath}${movie.poster_path}`,
              posterBackdrop:
                movie.backdrop_path &&
                `${baseOriginalPath}${movie.backdrop_path}`,
              year: movie.release_date.substring(0, 4),
              rating: movie.vote_average,
              ratingCount: movie.vote_count,
            }));
          setGenres({...genres});
        });
    });
  }, []);
  useEffect(() => {
    if (currentUser && currentUser.uid) {
      // TODO Only not already seen
      User.getWatchlist(`${currentUser.uid}`).then((watchlist) => {
        // const m = watchlist.sort((a, b) => (a.title > b.title) ? 1 : -1);
        // setUserWatchlist(m);
        setUserWatchlist(watchlist);
      });
    }
  }, [currentUser]);
  const moviesForGenre = (genreId: string) => {
    // @ts-ignore
    return genres[genreId];
  };
  const getGenreTitle = (genreId: string) => {
    // @ts-ignore
    return genreTypes[genreId];
  };

  const renderMovieCard = (movie) => (
    <TouchableOpacity onPress={() => showDetail(movie)}>
      <Image
        source={{uri: movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')}}
        style={{
          height: isMobile ? 180 : 320,
          width: isMobile ? 120 : 200,
          borderRadius: 5,
          margin: 3,
        }}
      />
    </TouchableOpacity>
  );

  const renderTvCard = (movie) => (
    <TouchableOpacity onPress={() => showTvDetail(movie)}>
      <Image
        source={{uri: movie.posterImage && movie.posterImage.replace('/w500/', '/w200/')}}
        style={{
          height: isMobile ? 180 : 320,
          width: isMobile ? 120 : 200,
          borderRadius: 5,
          margin: 3,
        }}
      />
    </TouchableOpacity>
  );

  return (
    <View style={flexStyle}>
      <Screen style={{...containerStyle, paddingBottom: 30}} preset="scroll" backgroundColor={color.transparent}>
        {/* TODO Ensure this scrolls horizontally*/}
        {Object.keys(providers).length > 0 && (
          <TouchableOpacity onPress={() => navigation.navigate('Providers')}>
            <View style={{ display: 'flex', flexDirection: 'row', marginVertical: 20, width: '100%' }}>
              <View style={{ flex: 1, display: 'flex', flexDirection: 'row' }}>
                {Object.keys(providers).map((provider) => {
                  return (
                    <Image source={{ uri: providerLogos[providers[provider].toLowerCase().replace(new RegExp(' ', 'g'), '_')] }} style={{ height: 30, width: 30, marginHorizontal: 4, borderRadius: 3 }} />
                  )
                })}
              </View>
              <ListItem.Chevron style={{ flex: 0, alignSelf: 'flex-end' }} />
            </View>
          </TouchableOpacity>
        )}
        {/* TODO Include personal "recommendations" and movies from their friends' lists */}
        {/* TODO Include a recommended genre horizontal slider - either chips or cards */}
        {trendingMovies.length > 0 && (
          <View style={{display: 'flex', flexDirection: 'column'}}>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{ ...titleStyle, flex: 1 }}>Trending Today</Text>
              <Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('Genre List', { genre: { key: 'trending/movie/day', title: 'Trending Today' }})}>View all</Text>
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {trendingMovies.map((movie: any) => (
                <View key={movie.id} style={{margin: 5}}>
                  {renderMovieCard(movie)}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        {popularMovies.length > 0 && (
          <View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{ ...titleStyle, flex: 1 }}>Popular</Text>
              <Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('Genre List', { genre: { key: 'movie/popular', title: 'Popular' }})}>View all</Text>
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {popularMovies.map((movie: any) => (
                <View key={movie.id} style={{margin: 5}}>
                  {renderMovieCard(movie)}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        {highlyRatedMovies.length > 0 && (
          <View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{ ...titleStyle, flex: 1 }}>Highly Rated</Text>
              <Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('Genre List', { genre: { key: 'movie/top_rated', title: 'Highly Rated' }})}>View all</Text>
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {highlyRatedMovies.map((movie: any) => (
                <View key={movie.id} style={{margin: 5}}>
                  {renderMovieCard(movie)}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        {newlyReleasedMovies.length > 0 && (
          <View>
            <View style={{display: 'flex'}}>
              <Text>New Releases</Text>
              {/* <span>View all</span> */}
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {newlyReleasedMovies.map((movie: any) => (
                <View key={movie.id} style={{margin: 5}}>
                  {renderMovieCard(movie)}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        {userWatchlist.length > 0 && (
          <View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{ ...titleStyle, flex: 1 }}>Your Watchlist</Text>
              <Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('My Profile')}>View all</Text>
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {userWatchlist.map((movie: any) => (
                <View key={movie.id} style={{margin: 5}}>
                  {renderMovieCard(movie)}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
        {/* FIXME Figure out how to allow dynamic genres */}
        {Object.keys(genres).length > 0 &&
        Object.keys(genres).map(key => (
          <View key={key}>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Text style={{ ...titleStyle, flex: 1 }}>{getGenreTitle(key)}</Text>
              <Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('Genre List', { genre: { key, title: getGenreTitle(key) }})}>View all</Text>
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {moviesForGenre(key).map((movie: any) => (
                <View key={movie.id} style={{margin: 5}}>
                  {renderMovieCard(movie)}
                </View>
              ))}
            </ScrollView>
          </View>
        ))}
        {tvShows.length > 0 && (
          <View>
            <View style={{display: 'flex', flexDirection: 'row'}}>
              <Text style={{ ...titleStyle, flex: 1 }}>TV Shows</Text>
              <Text style={{ ...textStyle, alignSelf: 'flex-end' }} onPress={() => navigation.navigate('TV List')}>View all</Text>
            </View>
            <ScrollView horizontal={true} style={{marginLeft: -16, marginRight: -16}}>
              {tvShows.map((show: any) => (
                <View key={show.id} style={{margin: 5}}>
                  {renderTvCard(show)}
                </View>
              ))}
            </ScrollView>
          </View>
        )}
      </Screen>
    </View>
  );
});
