import React, { useContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { auth } from '../config/firebase';
import UserCredential = firebase.auth.UserCredential;
import User from "../models/user";
import {UserType} from "./UserDetailContext";

// https://github.com/WebDevSimplified/React-Firebase-Auth

interface ContextType {
  loginWithFacebook: () => Promise<UserCredential>;
  loginWithGoogle: () => Promise<UserCredential>;
  login: (email: string, password: string) => Promise<UserCredential>;
  signup: (
    email: string,
    password: string
  ) => Promise<UserCredential>;
  logout: () => Promise<any>;
  resetPassword?: (email: string) => void;
  updateEmail?: (email: string) => void;
  updateProfile?: (profile: any) => Promise<any>;
  currentUser?: any;
}

function loginWithFacebook() {
  const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
  return auth.signInWithPopup(facebookAuthProvider);
}

function loginWithGoogle() {
  console.log('Using Firebase web config...');
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
  // FIXME Why won't it pull the displayName? It's set to " "
  googleAuthProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
  return auth.signInWithPopup(googleAuthProvider);
}

function signup(email: string, password: string) {
  return auth.createUserWithEmailAndPassword(email, password);
}

function login(email: string, password: string) {
  return auth.signInWithEmailAndPassword(email, password);
}

function logout() {
  return new Promise((resolve) => {
    auth.signOut().then(() => {
      // TODO Clear local storage
      resolve();
    });
  });
}

function resetPassword(email: string) {
  return auth.sendPasswordResetEmail(email);
}

const initialValues = {
  login,
  signup,
  logout,
  loginWithFacebook,
  loginWithGoogle,
  resetPassword,
};

const AuthContext = React.createContext<ContextType>(initialValues);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return auth.onAuthStateChanged(user => {
      // FIXME It isn't pulling the displayName for Google - prob doesn't for FB either
      console.log('Got firebase user!');
      console.log(user);
      setCurrentUser(user);
      setLoading(false);
      if (user && user.uid) {
        if (user.displayName) {
          const userDetail: UserType = {
            // @ts-ignore
            searchText: user.displayName!.toLowerCase(),
            firstName: user!.displayName!.split(' ')[0],
            lastName: user!.displayName!.split(' ')[1],
            email: user!.email!,
          };
          if (user!.photoURL) {
            userDetail.imageUrl = user!.photoURL;
          }
          User.updateUser(user.uid, userDetail);
        }
      }
    });
  }, []);

  function updateEmail(email: string) {
    return currentUser?.updateEmail(email);
  }

  function updatePassword(password: string) {
    return currentUser?.updatePassword(password);
  }

  function updateProfile(profile: any) {
    // { displayName: "Jane Q. User", photoURL: "https://example.com/jane-q-user/profile.jpg" }
    return currentUser?.updateProfile(profile);
  }

  const value = {
    ...initialValues,
    currentUser,
    updateEmail,
    updatePassword,
    updateProfile,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
