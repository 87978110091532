export const thumbnailPath = 'https://image.tmdb.org/t/p/w92';
export const smallPath = 'https://image.tmdb.org/t/p/w200';
export const basePath = 'https://image.tmdb.org/t/p/w500';
export const basePathLarge = 'https://image.tmdb.org/t/p/w1280';
export const baseOriginalPath = 'https://image.tmdb.org/t/p/original';
class Movie {
  // constructor(tmdbMovie: any) {
  //   this.id = tmdbMovie.id;
  //   this.title = tmdbMovie.title;
  //   this.description = tmdbMovie.overview;
  //   this.posterImage =
  //     tmdbMovie.poster_path && `${basePath}${tmdbMovie.poster_path}`;
  //   this.posterBackdrop =
  //     tmdbMovie.backdrop_path && `${basePath}${tmdbMovie.backdrop_path}`;
  //   this.year = tmdbMovie.release_date.substring(0, 4);
  //   this.rating = tmdbMovie.vote_average;
  //   this.ratingCount = tmdbMovie.vote_count;
  // }
  //
  // setVideo(video: string) {
  //   this.video = video;
  // }
}
