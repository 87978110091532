import React from "react";
import {
  Placeholder,
  PlaceholderMedia,
  PlaceholderLine,
  Fade,
} from 'rn-placeholder';
import {View} from "react-native";

const squareStyle = { borderRadius: 0 };
const smallRadiusStyle = { borderRadius: 5 };

export default () => (
  <View>
    <PlaceholderLine style={squareStyle} height={200} noMargin />
    <View style={{ margin: 16 }}>
      <PlaceholderLine height={20} />
      <Placeholder
        Left={PlaceholderMedia}
        Animation={Fade}
        style={{ marginBottom: 20 }}
      >
        <PlaceholderLine width={30} />
      </Placeholder>
      {/*<PlaceholderMedia isRound={false} />*/}
      <PlaceholderLine />
      <PlaceholderLine width={60} />
      <PlaceholderLine style={smallRadiusStyle} height={100} noMargin />
    </View>
  </View>
)
