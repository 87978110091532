import React, {useEffect, useState} from 'react';
import {useAuth} from "../contexts/AuthContext";
import {useTheme} from "@react-navigation/native";
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {observer} from "mobx-react-lite";
import {auth} from '../config/firebase';
import {
  Alert,
  View,
  StyleSheet,
  Text,
  ViewStyle,
  TouchableOpacity,
  ImageBackground, Platform,
} from "react-native";
import {TextInput} from "react-native-paper";
import {color, spacing} from "../theme";
import {Button, Screen} from "../components";
import {palette} from "../theme/palette";
import {isMobile} from "../components/mobile-device-detect";

const flexStyle: ViewStyle = {flex: 1};
const containerStyle: ViewStyle = {
  // backgroundColor: '#121212', // colors.background, // FIXME Depends on the theme
  paddingHorizontal: spacing[4],
}

const styles = StyleSheet.create({
  separatorWrap: {
    paddingVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  separator: {
    borderBottomWidth: 1,
    flexGrow: 1,
    borderColor: '#565656', // Color.blackTextDisable,
  },
  separatorText: {
    color: '#565656', // Color.blackTextDisable,
    paddingHorizontal: 10,
  },
});

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const LoginScreen: React.FC<StackScreenProps<NavigatorParamList, "Login">> = observer(({navigation}) => {
    const {currentUser, loginWithApple, loginWithFacebook, loginWithGoogle, login} = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState(null);
    const {dark} = useTheme();
    useEffect(() => {
      if (currentUser) {
        navigation.navigate('Home');
      }
    }, [currentUser]);
    const handleSuccess = async () => {
      // INFO This should be handled with the useEffect above
      // await auth.currentUser?.getIdToken().then(user => {
      //   console.log('Got user token:');
      //   console.log(user);
      //   if (user) {
      //     navigation.navigate('Home');
      //   }
      // });
    };
    const validateForm = () => {
      let noErrors = true;
      if (!email || !email.length || !validateEmail(email.trim())) {
        setEmailError(true);
        noErrors = false;
      } else {
        setEmailError(false);
      }
      if (!password || !password.length) {
        setPasswordError(true);
        noErrors = false;
      } else {
        setPasswordError(false);
      }
      return noErrors;
    };
    const renderInnerForm = () => (
      <>
        {Platform.OS === 'ios' && (
          <Button
            style={{height: 50, backgroundColor: palette.apple, marginTop: 20}}
            onPress={async () => {
              try {
                setIsLoading(true);
                setError(null);
                await loginWithApple().then(async () => handleSuccess());
              } catch (error) {
                setIsLoading(false);
                setError(error);
              }
            }}
            text="Log In with Apple"
            textStyle={{fontSize: 14}}
          />
        )}
        <Button
          style={{height: 50, backgroundColor: palette.facebook, marginTop: 20}}
          onPress={async () => {
            try {
              setIsLoading(true);
              setError(null);
              await loginWithFacebook().then(async () => handleSuccess()).catch((err) => alert(err));
            } catch (error) {
              setIsLoading(false);
              setError(error);
            }
          }}
          text="Log In with Facebook"
          textStyle={{fontSize: 14}}
        />
        <Button
          style={{height: 50, backgroundColor: palette.google, marginTop: 20}}
          onPress={async () => {
            try {
              setIsLoading(true);
              setError(null);
              await loginWithGoogle().then(async () => handleSuccess()).catch((err) => alert(err));
            } catch (error) {
              setIsLoading(false);
              setError(error);
            }
          }}
          text="Log In with Google"
          textStyle={{fontSize: 14}}
        />
        <View style={styles.separatorWrap}>
          <View style={styles.separator}/>
          <Text style={styles.separatorText}>or</Text>
          <View style={styles.separator}/>
        </View>
        <TextInput
          label="Email"
          mode="outlined"
          autoCapitalize={"none"}
          keyboardType="email-address"
          autoCorrect={false}
          autoComplete="email"
          style={{marginBottom: 20}}
          value={email}
          onChangeText={(text) => setEmail(text)}
        />
        <TextInput
          label="Password"
          mode="outlined"
          textContentType="password"
          autoCapitalize={"none"}
          autoCorrect={false}
          secureTextEntry={!showPassword}
          autoComplete="current-password"
          value={password}
          onChangeText={(text) => setPassword(text)}
          right={<TextInput.Icon name={showPassword ? 'eye-off' : 'eye'}
                                 onPress={() => setShowPassword(!showPassword)}/>}
        />
        <Button
          style={{height: 50, backgroundColor: palette.secondary, marginTop: 20}}
          onPress={async () => {
            setSubmitted(true);
            if (!validateForm()) {
              return;
            }
            setSubmitting(true);
            console.log(email, password);
            await login(email, password)
              .then(async () => {
                setSubmitting(false);
                await handleSuccess();
              })
              .catch((err: any) => {
                setSubmitting(false);
                console.debug(err);
                Alert.alert(
                  "Unable to Log In",
                  err.message,
                  [{text: "Dismiss", onPress: () => console.log("Dismissed")}]
                );
              });
          }}
          text="Log In"
          textStyle={{fontSize: 14}}
        />
        <View style={{marginTop: 20}}>
          <Text style={{color: 'white', textAlign: 'center'}}>
            Don't have an account yet?
            <TouchableOpacity onPress={() => navigation.navigate('Sign Up')}>
              <Text style={{marginLeft: 8, color: color.primary}}>Sign Up</Text>
            </TouchableOpacity>
          </Text>
          <View style={{marginTop: 16, marginBottom: 20}}>
            <Text
              style={{color: 'white', textAlign: 'center'}}
              onPress={() => navigation.navigate(`Reset Password`)}
            >
              Forgot Password?
            </Text>
          </View>
        </View>
      </>
    )
    const renderForm = () => {
      if (isMobile) {
        return renderInnerForm();
      }
      return (
        <View style={{ width: '100%', maxWidth: 480, margin: 'auto', padding: 80, backgroundColor: 'rgba(0,0,0,0.5)', borderRadius: 20}}>
          {renderInnerForm()}
        </View>
      )
    }
    // TODO Include a useEffect to listen to the user - if the user is already logged in, redirect them to the home screen or correct url path
    return (
      <View style={flexStyle}>
        <ImageBackground
          source={isMobile ? require('../../assets/images/auth-background.jpg') : require('../../assets/images/auth-background-web.jpg')}
          style={{width: '100%', height: '100%'}}
        >
          <Screen style={containerStyle} preset="scroll" backgroundColor={color.transparent}>
            {renderForm()}
          </Screen>
        </ImageBackground>
      </View>
    );
  },
)

