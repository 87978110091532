import React, { useEffect, useState } from 'react';
import {
  UserType,
  useUserContext,
} from '../contexts/UserDetailContext';
import User from '../models/user';
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {TouchableOpacity, Text, View, useColorScheme, Image, Dimensions} from "react-native";
import { ListItem } from "react-native-elements";
import { Avatar } from "react-native-paper";
import {useQueryContext} from "../contexts/QueryContext";
import {palette} from "../theme/palette";
import {ActivityIndicator} from "react-native-paper";

export const UserSearchScreen: React.FC<StackScreenProps<NavigatorParamList, "Search Users">> = ({ navigation }) => {
  const { setSelectedUser } = useUserContext();
  const { userSearchQuery } = useQueryContext();
  const [userResults, setUserResults] = useState<UserType[]>([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const colorScheme = useColorScheme();
  const darkMode = colorScheme === "dark";
  useEffect(() => {
    // FIXME Debounce
    console.log('Searching users...');
    if (userSearchQuery.length > 0) {
      if (!loadingUsers) {
        setLoadingUsers(true);
        User.searchUsers(userSearchQuery.toLowerCase()).then(users => {
          console.log('Got users!')
          console.log(users)
          setLoadingUsers(false);
          setUserResults(users);
        });
      }
    }
  }, [userSearchQuery]);
  const showUserDetail = (user: UserType) => {
    setSelectedUser?.(user);
    navigation.navigate('User Profile', { userId: user.id, user });
  };
  const renderAvatar = (user) => {
    if (user) {
      if (user.profileImageUrl) {
        return (
          <Avatar.Image
            size={40}
            source={{uri: user.profileImageUrl || 'https://i.pravatar.cc/80'}}
          />
        )
      }
      return <Avatar.Text size={40} label={user.firstName.charAt(0)}/>;
    }
    return <Avatar.Text size={40} label="A"/>;
  }
  return (
    <View>
      {!loadingUsers && userResults.length === 0 && (
        <>
          <Image
            source={require('../../assets/images/user-search.png')}
            style={{
              height: Dimensions.get('window').width * (2 / 3),
              width: Dimensions.get('window').width,
              aspectRatio: 3 / 2,
              resizeMode: 'contain',
            }}
          />
          <Text
            style={{
              marginTop: 10,
              textAlign: 'center',
              color: darkMode ? 'white' : '#121212',
              fontSize: 16,
            }}
          >
            No users found
          </Text>
        </>
      )}
      {loadingUsers && userResults.length === 0 && (
        <View style={{ alignSelf: 'center', marginTop: 30 }}>
          <ActivityIndicator size={30} animating={true} color={palette.primary} />
        </View>
      )}
      <View
        style={{
          width: '100%',
          bgcolor: 'background.paper',
        }}
      >
        {/* TODO Use elements? */}
        {userResults.map((user: UserType) => (
          <TouchableOpacity onPress={() => showUserDetail(user)}>
            <ListItem
                key={user.id}
                containerStyle={{
                  marginHorizontal: 16,
                  marginVertical: 8,
                  borderRadius: 8,
                  backgroundColor: darkMode ? '#343434' : 'white',
                }}
            >
              {renderAvatar(user)}
              <ListItem.Content>
                <ListItem.Title
                    style={{ fontWeight: 'bold', color: darkMode ? 'white' : '#121212' }}
                >
                  {user.fullName}
                </ListItem.Title>
              </ListItem.Content>
              <ListItem.Chevron color={darkMode ? 'white' : '#121212'}/>
            </ListItem>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};
