import React from 'react';
import {useTheme} from "@react-navigation/native";
import {StackScreenProps} from "@react-navigation/stack";
import {observer} from "mobx-react-lite";
import {NavigatorParamList} from "../../navigators";
const PolicyHTML = require('../../templates/privacy.html');

export const PrivacyPolicyScreen: React.FC<StackScreenProps<NavigatorParamList, "Privacy Policy">> = observer(({navigation}) => {
    const {dark} = useTheme();
    return (
      <div style={{ flex: 1, backgroundColor: dark ? '#010101' : 'rgb(242, 242, 242)', marginTop: 40, marginBottom: 30 }}>
        <div style={{ color: dark ? '#efefef' : '#121212', maxWidth: 1200, margin: 'auto' }} dangerouslySetInnerHTML={{ __html: PolicyHTML }} />
      </div>
    );
  },
)

