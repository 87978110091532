import React, {useEffect, useRef, useState} from "react";
import {Linking, Platform} from "react-native";
import debounce from "lodash.debounce";
import WebView from "react-native-webview";

import publisher from "./publisher";
import {InAppBrowser} from "react-native-inappbrowser-reborn";

function warn(...params) {
  // eslint-disable-next-line no-console
  console.warn(...params);
}

const _debounce = debounce((press, url) => {
  if (press) {
    press(url);
  } else {
    Linking.openURL(url).catch((er) => {
      warn(er);
    });
  }
}, 300);

function getAdUnitTargeting(adUnitTargeting) {
  if (!adUnitTargeting) {
    return "";
  }
  const unitTargeting = [];
  Object.keys(adUnitTargeting).forEach((key) => {
    let value = adUnitTargeting[key];
    // Array
    if (Array.isArray(value)) {
      value = value.map((val) => `"${val}"`);
      value = `[${value}]`;
    }
    // String
    else if (typeof value === "string") {
      value = `"${value}"`;
    }
    // @ts-ignore
    unitTargeting.push(`.setTargeting("${key}", ${value})`);
  });
  return unitTargeting.join("");
}

// type Props = {
//   width?: number,
//   adUnitId: string,
//   adUnitSize: string,
//   adUnitTargeting?: Object,
//   baseUrl?: string,
//   backgroundColor?: string,
//   onPress?: Function,
//   impressionViewable?: Function,
//   slotOnload?: Function,
//   slotRenderEnded?: Function,
//   slotRequested?: Function,
//   slotResponseReceived?: Function,
//   slotVisibilityChanged?: Function,
// };
//
// type State = {
//   width: number,
//   height: number,
// };
const GTP = ({
 width,
 height = 0,
 // baseUrl,
 adUnitId,
 adUnitSize,
 adUnitTargeting = '',
 backgroundColor = 'transparent',
 onPress = null,
 onMessage = () => {},
 slotRenderEnded = () => {},
 impressionViewable = () => {},
 slotOnload = () => {},
 slotRequested = () => {},
 slotResponseReceived = () => {},
 slotVisibilityChanged = () => {},
}) => {
  const [html, setHtml] = useState('');
  const [initialUrl, setInitialUrl] = useState();
  const [dimensions, setDimensions] = useState({ height, width });
  const webView = useRef();

  useEffect(() => {
    setHtml(publisher(
      adUnitId,
      adUnitSize,
      getAdUnitTargeting(adUnitTargeting),
      backgroundColor
    ));
  }, []);

  const _onShouldStartLoadWithRequest = (request) => {
    if (!request || !request.url) {
      return true;
    }

    if (request.url.startsWith("blob")) {
      _onPress(request.url);
      return false;
    }

    if (request.url.startsWith("intent:")) {
      _onPress(request.url);
      return false;
    }

    if (request.url.startsWith("https://adssettings.google.com/whythisad")) {
      _onPress(request.url);
      return false;
    }

    if (
      request.url.startsWith("tel:") ||
      request.url.startsWith("mailto:") ||
      request.url.startsWith("maps:") ||
      request.url.startsWith("geo:") ||
      request.url.startsWith("sms:")
    ) {
      _onPress(request.url);
      return false;
    }
    return true;
  };

  const _onNavigationStateChange = ({ url }) => {
    if (!initialUrl) {
      setInitialUrl(url);
    }

    if (url && url !== initialUrl) {
      // @ts-ignore
      webView.current?.stopLoading();
      _onPress(url);
    }
  };

  const _onMessage = (info: Object) => {
    if (onMessage) {
      // @ts-ignore
      onMessage(info);
    }

    try {
      // @ts-ignore
      const { nativeEvent } = info;
      const data = JSON.parse(nativeEvent.data);

      if (data.height) {
        const { height } = data;
        if (height !== dimensions.height) {
          // @ts-ignore
          setDimensions({ height });
        }
      }

      if (data.impressionViewable) {
        // @ts-ignore
        impressionViewable(data.impressionViewable);
      }

      if (data.slotOnload) {
        // @ts-ignore
        slotOnload(data.slotOnload);
      }

      if (data.slotRenderEnded) {
        // @ts-ignore
        slotRenderEnded(data.slotRenderEnded);

        let width = 0;
        let height = 0;
        if (data.slotRenderEnded.size && data.slotRenderEnded.size.length > 1) {
          width = data.slotRenderEnded.size[0];
          height = data.slotRenderEnded.size[1];
        }
        const isFluid = width === 0 && height === 0;
        if (
          !isFluid &&
          (width !== dimensions.width || height !== dimensions.height)
        ) {
          setDimensions({
            width,
            height,
          });
        }
      }

      if (data.slotRequested) {
        // @ts-ignore
        slotRequested(data.slotRequested);
      }

      if (data.slotResponseReceived) {
        // @ts-ignore
        slotResponseReceived(data.slotResponseReceived);
      }

      if (data.slotVisibilityChanged) {
        // @ts-ignore
        slotVisibilityChanged(data.slotVisibilityChanged);
      }
    } catch (er) {
      warn(er);
    }
  };

  const _onPress = async (url) => {
    if (url && url.length && url !== 'about:blank') {
      if (typeof onPress === 'function') {
        _debounce(onPress, url);
      } else {
        if (await InAppBrowser.isAvailable()) {
          await InAppBrowser.open(url);
        } else {
          await Linking.openURL(url);
        }
      }
    }
  }

  const style = {
    flex: 1,
    width: dimensions.width,
    height: dimensions.height,
  };
  if (Platform.OS === "web") {
    // @ts-ignore
    return <iframe srcdoc={html} height={height} width={width} style={{ overflow: 'hidden', border: 'none' }} />
  }
  return (
    <WebView
      // @ts-ignore
      ref={webView}
      style={style}
      bounces={false}
      scrollEnabled={false}
      onMessage={_onMessage}
      source={{
        // baseUrl,
        html,
      }}
      onNavigationStateChange={_onNavigationStateChange}
      onShouldStartLoadWithRequest={_onShouldStartLoadWithRequest}
      androidHardwareAccelerationDisabled={true}
    />
  );
}

export default GTP;
