import React, {useEffect, useState} from 'react';
import {useAuth} from '../contexts/AuthContext';
import {useSettingsContext} from '../contexts/AppContext';
import {StackScreenProps} from "@react-navigation/stack";
import {NavigatorParamList} from "../navigators";
import {observer} from "mobx-react-lite";
import {auth} from '../config/firebase';
import {
  Alert,
  View,
  ViewStyle,
  Text,
  Platform,
  TouchableOpacity,
  StyleSheet,
  ImageBackground,
} from "react-native";
import {Link, useTheme} from "@react-navigation/native";
import {TextInput} from "react-native-paper";
import {palette} from "../theme/palette";
import {Button, Screen} from "../components";
import {color, spacing} from "../theme";
import {validateEmail} from "./Login";
import {isMobile} from "../components/mobile-device-detect";

const flexStyle: ViewStyle = {flex: 1};
const containerStyle: ViewStyle = {
  paddingHorizontal: spacing[4],
}
const styles = StyleSheet.create({
  separatorWrap: {
    paddingVertical: 15,
    flexDirection: "row",
    alignItems: "center",
  },
  separator: {
    borderBottomWidth: 1,
    flexGrow: 1,
    borderColor: '#565656', // Color.blackTextDisable,
  },
  separatorText: {
    color: '#565656', // Color.blackTextDisable,
    paddingHorizontal: 10,
  },
});

export const SignUpScreen: React.FC<StackScreenProps<NavigatorParamList, "Sign Up">> = observer(({navigation}) => {
    const {
      currentUser,
      loginWithApple,
      loginWithFacebook,
      loginWithGoogle,
      signup,
      updateProfile,
    } = useAuth();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const {settings} = useSettingsContext();
    const {dark} = useTheme();
    useEffect(() => {
      if (currentUser) {
        navigation.navigate('Home');
      }
    }, [currentUser]);
    const handleSuccess = async () => {
      // INFO This should be handled with the useEffect above
      // await auth.currentUser.getIdToken().then(token => {
      //   console.log('Got user token:');
      //   console.log(token);
      // });
    };
    const validateForm = () => {
      let hasError = false;
      if (!firstName || !firstName.length) {
        setFirstNameError(true);
        hasError = true;
      } else {
        setFirstNameError(false);
      }
      if (!lastName || !lastName.length) {
        setLastNameError(true);
        hasError = true;
      } else {
        setLastNameError(false);
      }
      if (!email || !email.length || !validateEmail(email.trim())) {
        setEmailError(true);
        hasError = true;
      } else {
        setEmailError(false);
      }
      if (!password || !password.length) {
        setPasswordError(true);
        hasError = true;
      } else {
        setPasswordError(false);
      }
      return !hasError;
    };
    useEffect(() => {
      if (currentUser) {
        (async () => {
          // @ts-ignore
          console.log('Updating user...');
          console.log({
            displayName: `${firstName.trim()} ${lastName.trim()}`,
          });
          await updateProfile?.({
            displayName: `${firstName.trim()} ${lastName.trim()}`,
          })
            .then(() => {
              navigation.navigate("Home");
            })
            .catch(err => {
              console.error(err);
            });
          navigation.navigate('Home');
        })();
      }
    }, [currentUser]);
    const renderInnerForm = () => (
      <>
        {/* TODO If we include a param to do so, show a message that alerts them to "Sign up to complete this action" */}
        {Platform.OS === 'ios' && (
          <Button
            style={{height: 50, backgroundColor: palette.apple, marginTop: 20}}
            onPress={async () => {
              try {
                setIsLoading(true);
                setError(null);
                await loginWithApple().then(async () => handleSuccess());
              } catch (error) {
                setIsLoading(false);
                setError(error);
              }
            }}
            text="Sign Up with Apple"
            textStyle={{fontSize: 14}}
          />
        )}
        <Button
          style={{height: 50, backgroundColor: palette.facebook, marginTop: 20}}
          onPress={async () => {
            try {
              setIsLoading(true);
              setError(null);
              await loginWithFacebook().then(handleSuccess);
            } catch (error) {
              setIsLoading(false);
              setError(error);
            }
          }}
          text="Sign Up with Facebook"
          textStyle={{fontSize: 14}}
        />
        <Button
          style={{height: 50, backgroundColor: palette.google, marginTop: 20}}
          onPress={async () => {
            try {
              setIsLoading(true);
              setError(null);
              await loginWithGoogle().then(handleSuccess);
            } catch (error) {
              setIsLoading(false);
              setError(error);
            }
          }}
          text="Sign Up with Google"
          textStyle={{fontSize: 14}}
        />
        <View style={styles.separatorWrap}>
          <View style={styles.separator}/>
          <Text style={styles.separatorText}>or</Text>
          <View style={styles.separator}/>
        </View>
        <TextInput
          label="First Name"
          mode="outlined"
          autoCapitalize={"none"}
          autoCorrect={false}
          autoComplete="first_name"
          style={{marginBottom: 20}}
          value={firstName}
          onChangeText={(text) => setFirstName(text)}
        />
        <TextInput
          label="Last Name"
          mode="outlined"
          autoCapitalize={"none"}
          autoCorrect={false}
          autoComplete="last_name"
          style={{marginBottom: 20}}
          value={lastName}
          onChangeText={(text) => setLastName(text)}
        />
        <TextInput
          label="Email"
          mode="outlined"
          autoCapitalize={"none"}
          keyboardType="email-address"
          autoCorrect={false}
          autoComplete="email"
          style={{marginBottom: 20}}
          value={email}
          onChangeText={(text) => setEmail(text)}
        />
        <TextInput
          label="Password"
          mode="outlined"
          textContentType="password"
          autoCapitalize={"none"}
          autoCorrect={false}
          secureTextEntry={!showPassword}
          autoComplete="current-password"
          value={password}
          onChangeText={(text) => setPassword(text)}
          right={<TextInput.Icon name={showPassword ? 'eye-off' : 'eye'}
                                 onPress={() => setShowPassword(!showPassword)}/>}
        />
        {/* TODO Accept terms? */}
        <Button
          style={{height: 50, backgroundColor: palette.secondary, marginTop: 20}}
          onPress={async () => {
            setSubmitted(true);
            if (!validateForm()) {
              return;
            }
            console.log(email, password);
            setSubmitting(true);
            await signup(email, password)
              .then(async () => {
                setSubmitting(false);
                await handleSuccess();
              })
              .catch((err: any) => {
                setSubmitting(false);
                console.debug(err);
                Alert.alert(
                  "Unable to Sign Up",
                  err.message,
                  [{text: "Dismiss", onPress: () => console.log("Dismissed")}]
                );
              });
          }}
          text="Sign Up"
          textStyle={{fontSize: 14}}
        />
        <View style={{marginTop: 20}}>
          <Text style={{color: 'white', fontSize: 14, textAlign: 'center', marginTop: 12}}>
            By clicking Sign Up, you agree to M+T's <Link to="/terms">Terms of Service</Link> and <Link to="/privacy">Privacy Policy</Link>
          </Text>
        </View>
        <Text style={{color: 'white', marginTop: 8, marginBottom: 20, textAlign: 'center'}}>
          Already have an account?
          <TouchableOpacity onPress={() => navigation.navigate('Login')}>
            <Text style={{marginLeft: 8, color: color.primary}}>Log In</Text>
          </TouchableOpacity>
        </Text>
      </>
    );
    const renderForm = () => {
      if (isMobile) {
        return renderInnerForm();
      }
      return (
        <View style={{
          width: '100%',
          maxWidth: 480,
          margin: 'auto',
          padding: 80,
          backgroundColor: 'rgba(0,0,0,0.5)',
          borderRadius: 20
        }}>
          {renderInnerForm()}
        </View>
      )
    }
    return (
      <View style={flexStyle}>
        <ImageBackground
          source={isMobile ? require('../../assets/images/auth-background.jpg') : require('../../assets/images/auth-background-web.jpg')}
          style={{width: '100%', height: '100%'}}
        >
          <Screen style={containerStyle} preset="scroll" backgroundColor={color.transparent}>
            {renderForm()}
          </Screen>
        </ImageBackground>
      </View>
    );
  },
)
