import {isMobile} from "../mobile-device-detect";
import {Platform, TouchableOpacity, View} from "react-native";
import Ionicons from "react-native-vector-icons/Ionicons";
import {palette} from "../../theme/palette";
import {Avatar, Searchbar} from "react-native-paper";
import {Button} from "@mui/material";
import React from "react";
import {ActionBarImage} from "../../navigators";
import {useAuth} from "../../contexts/AuthContext";

const renderAvatar = (user) => {
  if (user) {
    if (user.profileImageUrl) {
      return (
        <Avatar.Image
          size={40}
          source={{uri: user.profileImageUrl || 'https://i.pravatar.cc/80'}}
        />
      )
    }
    if (user.firstName) {
      return <Avatar.Text size={40} label={user.firstName!.charAt(0)}/>;
    }
  }
  return <Avatar.Text size={40} label="A"/>;
};

export const desktopHeader = (navigation, movieSearch, setMovieSearch, setMovieSearchQuery) => ({
  headerTitle: () => <ActionBarImage/>,
  headerRight: () => {
    const { currentUser } = useAuth();
    return isMobile ?
      <TouchableOpacity
        onPress={() => {
          navigation.navigate('Search Movies');
        }}
      >
        <Ionicons
          style={{padding: 12}}
          name={'search'}
          size={20}
          color={palette.primary}
        />
      </TouchableOpacity>
      : (
        <div style={{ marginRight: 20, display: 'flex', flexDirection: 'row' }}>
          <Searchbar
            // darkMode={colorScheme === "dark"}
            // containerStyle={{backgroundColor: 'transparent', width: '100%', border: 'none'}}
            inputStyle={Platform.OS === 'ios' && { height: 34 }}
            placeholder="Search movies or TV shows..."
            onChangeText={(text: string) => {
              setMovieSearch(text);
              if (text.length > 0) {
                setMovieSearchQuery?.(text);
              }
            }}
            value={movieSearch}
            // style={styles.searchBar}
            style={{ minWidth: 800, maxWidth: '100%', marginHorizontal: 20, marginVertical: 4 }}
          />
          {/* TODO Need a dropdown menu with search results */}
          {/* FIXME Sometimes get navigation.navigate is not a function error - maybe wrong variable getting passed into desktopHeader function */}
          {!currentUser && <Button variant="outlined" style={{ marginTop: 4, marginBottom: 4 }} onClick={() => navigation.navigate('Sign Up')}>Sign Up</Button>}
          {!currentUser && <Button style={{ marginLeft: 8 }} onClick={() => navigation.navigate('Login')}>Log In</Button>}
          {/* FIXME Add a dropdown menu with settings and other useful profile-related links */}
          {currentUser && (
            <View style={{ marginTop: 8 }}>{renderAvatar(currentUser)}</View>
          )}
        </div>
      )
  },
});
