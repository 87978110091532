import { database } from '../config/firebase';
import { MovieType } from '../contexts/MovieDetailContext';
import { UserType } from '../contexts/UserDetailContext';
import {format} from "date-fns";

// FIXME See https://stackoverflow.com/questions/69235184/react-native-firebase-realtime-database-not-working
//  These should probably unsubscribe when finished?

function getCommonItems(array1: string[], array2: string[]) {
  const common = [];
  for (let i = 0; i < array1.length; i++) {
    for (let j = 0; j < array2.length; j++) {
      if (array1[i] === array2[j]) {
        // @ts-ignore
        common.push(array1[i]);
      }
    }
  }
  return common;
}

export default class User {
  // constructor(user: any) {
  //   this.id = user.id;
  //   this.firstName = user.firstName;
  //   this.lastName = user.lastName;
  //   this.fullName = `${user.firstName} ${user.lastName}`;
  //   this.profileImageUrl user.profileImageUrl;
  //   this.coverImageUrl = user.coverImageUrl;
  // }

  static updateUser = async (
    userId: string,
    {
      searchText,
      firstName,
      lastName,
      email,
      imageUrl,
      coverImageUrl,
    }: {
      searchText: string;
      firstName: string;
      lastName: string;
      email: string;
      imageUrl: string | null;
      coverImageUrl: string | null;
    }
  ) => {
    return database
      .ref()
      .child(`users/${userId}`)
      .update({
        searchText,
        firstName,
        lastName,
        email,
        profile_picture: imageUrl || '',
        cover_picture: coverImageUrl || '',
        // friends: {}, // [friendId]: true
        // watchlist: {}, // [movieId]: {...movie}
        // seen: {}, // [movieId]: 'yes' | 'no' | 'maybe'
      });
  };

  static getUser = (userId: string) => {
    return new Promise<UserType | null>((resolve, reject) => {
      database
        .ref()
        .child(`/users/${userId}`)
        .once('value', (snapshot: any) => {
          console.log('Got user by ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            const userDetail = snapshot.val();
            console.log(userDetail);
            resolve({
              ...userDetail,
              id: userId,
              profileImageUrl: userDetail.profile_picture,
              coverImageUrl: userDetail.cover_picture,
            });
          } else {
            resolve(null);
          }
        }, (error) => {
          reject(error);
        });
    });
  };

  static followUser = (userId: string, friendId: string) => {
    const update = {
      [`/users/${userId}/friends/${friendId}`]: true,
    };
    return database.ref().update(update);
  };

  static unfollowUser = (userId: string, friendId: string) => {
    return database
      .ref()
      .child(`/users/${userId}/friends/${friendId}`)
      .remove();
  };

  static isFollowing = (userId: string, friendId: string) => {
    return new Promise<boolean>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/friends/${friendId}`)
        .once('value', (snapshot: any) => {
          console.log('Got following result for friend ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  };

  static getFollowedUsers = (userId: string) => {
    const users: UserType[] = [];
    return new Promise<UserType[]>((resolve, reject) => {
      database
        .ref(`users/${userId}/friends`)
        .once('value', (snapshot: any) => {
          console.log('Database', 'Got snapshot results...');
          if (snapshot.exists()) {
            console.log('Database', 'Snapshot exists!');
            const keys = Object.keys(snapshot.val());
            keys.forEach((key: string, index: number) => {
              database
                .ref(`users/${key}`)
                .once('value', function(userSnapshot) {
                  if (userSnapshot.exists()) {
                    const userDetail = userSnapshot.val();
                    users.push({
                      ...userDetail,
                      id: key,
                      fullName: `${userDetail.firstName} ${userDetail.lastName}`,
                      profileImageUrl: userDetail.profile_picture,
                      coverImageUrl: userDetail.cover_picture,
                    });
                    if (index === keys.length - 1) {
                      resolve(users);
                    }
                  }
                });
            });
          } else {
            resolve([]);
          }
        }, (error) => {
          reject(error);
        });
    });
  };

  static searchUsers = (query: string) => {
    return new Promise<UserType[]>(resolve => {
      database
        .ref()
        .child('users')
        .orderByChild('searchText')
        .startAt(query)
        .endAt(`${query}\uF7FF`)
        .once('value', function(snapshot) {
          if (snapshot.exists()) {
            console.log('Got results:');
            console.log(snapshot.val());
            resolve(
              Object.keys(snapshot.val()).map(key => {
                const userDetail = snapshot.val()[key];
                return {
                  ...userDetail,
                  id: key,
                  fullName: `${userDetail.firstName} ${userDetail.lastName}`,
                  profileImageUrl: userDetail.profile_picture,
                  coverImageUrl: userDetail.cover_picture,
                };
              })
            );
          } else {
            resolve([]);
          }
        });
    });
  };

  static addToWatchlist = (userId: string, movie: MovieType) => {
    const update = {
      [`/users/${userId}/watchlist/${movie.id}`]: {...movie, timeAdded: new Date().toISOString()},
      [`/users/${userId}/watchlistIds/${movie.id}`]: true,
    };
    return database.ref().update(update);
  };

  static removeFromWatchlist = (userId: string, movieId: string) => {
    const update = {
      [`/users/${userId}/watchlist/${movieId}`]: null,
      [`/users/${userId}/watchlistIds/${movieId}`]: null,
    };
    return database.ref().update(update);
  };

  static getWatchlist = (userId: string) => {
    console.log(`Getting watchlist for user: ${userId}`);
    return new Promise<MovieType[]>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/watchlist`)
        .once('value', (snapshot: any) => {
          console.log('Got watchlist result for user ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            console.log(snapshot.val());
            resolve(
              Object.keys(snapshot.val()).map(
                key => snapshot.val()[key]
              )
            );
          } else {
            console.log('Unable to get watchlist for user')
            resolve([]);
          }
        });
    });
  };

  static getSharedWatchlist = (userId: string, friendId: string) => {
    const promises = [
      new Promise<string[]>(resolve => {
        database
          .ref()
          .child(`/users/${userId}/watchlistIds`)
          .once('value', (snapshot: any) => {
            console.log('Checking snapshot for current user');
            if (snapshot.exists()) {
              resolve(Object.keys(snapshot.val()));
            } else {
              resolve([]);
            }
          });
      }),
      new Promise<string[]>(resolve => {
        database
          .ref()
          .child(`/users/${friendId}/watchlistIds`)
          .once('value', (snapshot: any) => {
            console.log('Checking snapshot for friend');
            if (snapshot.exists()) {
              resolve(Object.keys(snapshot.val()));
            } else {
              resolve([]);
            }
          });
      }),
    ];
    return new Promise<MovieType[]>(resolve => {
      const allSharedMovies: MovieType[] = [];
      Promise.all(promises).then(([userMovies, friendMovies]) => {
        const sharedMovieIds = getCommonItems(
          userMovies,
          friendMovies
        );
        sharedMovieIds.forEach((key: string, index: number) => {
          database
            .ref()
            .child(`users/${userId}/watchlist/${key}`)
            .once('value', function(movieSnapshot) {
              if (movieSnapshot.exists()) {
                allSharedMovies.push(movieSnapshot.val());
              }
              if (index === sharedMovieIds.length - 1) {
                resolve(allSharedMovies);
              }
            });
        });
      });
    });
  };

  static isOnWatchlist = (userId: string, movieId: string) => {
    return new Promise<boolean>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/watchlist/${movieId}`)
        .once('value', (snapshot: any) => {
          console.log('Got watchlist result for movie ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  };

  static addRating(userId: string, movieId: string, rating: number) {
    const update = {
      [`/users/${userId}/ratings/${movieId}`]: rating,
    };
    return database.ref().update(update);
  }

  static getRating = (userId: string, movieId: string) => {
    return new Promise<number | null>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/ratings/${movieId}`)
        .once('value', (snapshot: any) => {
          console.log('Got user rating for movie ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            resolve(null);
          }
        });
    });
  };

  static setSeenStatus(
    userId: string,
    movieId: string,
    status: 'yes' | 'no' | 'maybe'
  ) {
    const update = {
      [`/users/${userId}/seen/${movieId}/status`]: status,
    };
    return database.ref().update(update);
  }

  static getSeenStatus = (userId: string, movieId: string) => {
    return new Promise<string>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/seen/${movieId}/status`)
        .once('value', (snapshot: any) => {
          console.log('Got seen status for movie ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            const val = snapshot.val();
            resolve(val);
          } else {
            resolve('no');
          }
        });
    });
  };

  static setWatchTime(
    userId: string,
    movieId: string,
    watchTime: Date
  ) {
    const update = {
      [`/users/${userId}/seen/${movieId}/date`]: format(watchTime || new Date(), 'yyyy-MM-dd'),
    };
    return database.ref().update(update);
  }

  static getWatchTime = (userId: string, movieId: string) => {
    return new Promise<string | null>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/seen/${movieId}/date`)
        .once('value', (snapshot: any) => {
          console.log('Got watch time for movie ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            resolve(null);
          }
        });
    });
  };

  static setWatchedWithUsers(
    userId: string,
    movieId: string,
    userIds: string[]
  ) {
    const update: any = {};
    userIds.forEach((memberId: string) => {
      update[
        `/users/${userId}/watchedWith/${movieId}/members/${memberId}`
        ] = true;
    });
    return database.ref().update(update);
  }

  static getWatchedWithUsers = (userId: string, movieId: string) => {
    const users: UserType[] = [];
    return new Promise<UserType[]>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/watchedWith/${movieId}/members`)
        .once('value', (snapshot: any) => {
          if (snapshot.exists()) {
            const keys = Object.keys(snapshot.val());
            keys.forEach((key: string, index: number) => {
              database
                .ref()
                .child(`users/${key}`)
                .once('value', function(userSnapshot) {
                  if (userSnapshot.exists()) {
                    const userDetail = userSnapshot.val();
                    users.push({
                      ...userDetail,
                      id: key,
                      fullName: `${userDetail.firstName} ${userDetail.lastName}`,
                      profileImageUrl: userDetail.profile_picture,
                      coverImageUrl: userDetail.cover_picture,
                    });
                    if (index === keys.length - 1) {
                      resolve(users);
                    }
                  }
                });
            });
          } else {
            resolve([]);
          }
        });
    });
  };

  static match = (userId: string, friendId: string, movie: any) => {
    const update = {
      [`/users/${userId}/matches/${friendId}/${movie.id}`]: {...movie, timeAdded: new Date().toISOString()},
      [`/users/${userId}/matchIds/${friendId}/${movie.id}`]: true,
    };
    return database.ref().update(update);
  };

  static unmatch = (userId: string, friendId: string, movieId: string) => {
    const update = {
      [`/users/${userId}/matches/${friendId}/${movieId}`]: null,
      [`/users/${userId}/matchIds/${friendId}/${movieId}`]: false,
    };
    return database.ref().update(update);
  };

  static getMatches = (userId: string, friendId: string) => {
    console.log(`Getting matches for user: ${userId} and friend: ${friendId}`);
    return new Promise<MovieType[]>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/matches/${friendId}`)
        .once('value', (snapshot: any) => {
          console.log('Got match results for users');
          console.log(snapshot);
          if (snapshot.exists()) {
            console.log(snapshot.val());
            resolve(
              Object.keys(snapshot.val()).map(
                key => snapshot.val()[key]
              )
            );
          } else {
            console.log('Unable to get watchlist for user')
            resolve([]);
          }
        });
    });
  };

  static isMatched = (userId: string, friendId: string, movieId: string) => {
    return new Promise<boolean>(resolve => {
      database
        .ref()
        .child(`/users/${userId}/matches/${friendId}/${movieId}`)
        .once('value', (snapshot: any) => {
          console.log('Got match result for movie ID');
          console.log(snapshot);
          if (snapshot.exists()) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
    });
  };

  static addProvider(userId: string, provider: any) {
    const update = {
      [`/users/${userId}/providers/${provider.id}`]: provider.name,
    };
    console.log(update);
    return database.ref().update(update);
  }

  static removeProvider(userId: string, provider: any) {
    const update = {
      [`/users/${userId}/providers/${provider.id}`]: null,
    };
    console.log(update);
    return database.ref().update(update);
  }

  static getProviders = (userId: string) => {
    return new Promise<any | null>(resolve => {
      database
        .ref(`/users/${userId}/providers`)
        .once('value', (snapshot: any) => {
          console.log('Got user streaming providers:');
          console.log(snapshot);
          if (snapshot.exists()) {
            resolve(snapshot.val());
          } else {
            resolve({});
          }
        });
    });
  };
}
