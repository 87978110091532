export const palette = {
  black: "#121212",
  offBlack: "#1F1F1F",
  white: "#ffffff",
  offWhite: "#e6e6e6",
  primary: "#00b1b2",
  secondary: "#7877d2",
  tertiary: "#cb63fe",
  lightGrey: "#939AA4",
  lighterGrey: "#CDD4DA",
  angry: "#dd3333",
  deepPurple: "#5D2555",
  apple: '#000000',
  facebook: '#3b5998',
  google: '#dc4a38',
  vimeo: '#23b6ea',
  twitter: '#1da1f4',
}
