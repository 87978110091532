import * as React from "react"
import {Platform, TouchableOpacity} from "react-native"
import { Text } from "../text/text"
import { viewPresets, textPresets } from "./button.presets"
import { ButtonProps } from "./button.props"

/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Button(props: ButtonProps) {
  // grab the props
  const {
    preset = "primary",
    tx,
    text,
    style: styleOverride,
    textStyle: textStyleOverride,
    children,
    ...rest
  } = props

  const viewStyle = viewPresets[preset] || viewPresets.primary;
  let viewStyles;

  const textStyle = textPresets[preset] || textPresets.primary;
  let textStyles;

  if (Platform.OS === "web") {
    viewStyles = Object.assign(viewStyle, styleOverride);
    textStyles = Object.assign(textStyle, textStyleOverride);
  } else {
    viewStyles = [viewStyle, styleOverride];
    textStyles = [textStyle, textStyleOverride];
  }

  const content = children ? (typeof children === 'string' ? <Text tx={tx} text={children} style={textStyles} /> : children) : <Text tx={tx} text={text} style={textStyles} />

  return (
    <TouchableOpacity style={viewStyles} {...rest}>
      {content}
    </TouchableOpacity>
  )
}
