import React from "react";
import ModalVideo from 'react-modal-video';
// FIXME We can't build native with css files - may need to convert react-modal-video to a RN component with React inline styles
// import './styles/modal-video.css';

export function TrailerPlayer({ showVideoTrailer, videoId, onClose }) {
  return (
    <ModalVideo
      fullscreen
      autoplay
      isOpen={showVideoTrailer}
      channel="youtube"
      videoId={videoId}
      onClose={onClose}
    />
  )
}
